import React, {useState} from 'react'
import {Box, Fade, Grid, Typography, Grow, Button} from "@mui/material";
import {EntityListItem, Maybe} from "src/shared/ui";
import {
    IService,
    ITariffListItem,
    usePurchasedEventDetailsListQuery,
    usePurchasedEventDetailsOrderMutation,
    useTariffsQuery
} from "src/shared/services/ApiService";
import {useNavigate} from "react-router-dom";
import ServiceCardTariffsIncludedInFallback
    from "src/features/Services/ui/Service/ServiceCard/ServiceCardTariffsIncludedInFallback";
import {TransitionGroup} from 'react-transition-group'
import {getLimitedEnterTimeout} from "src/shared/utils";
import {ModalWrapper, useModal} from "src/shared/providers";
import DOMPurify from "dompurify";
import {FontFamily} from "src/shared/utils/font";
import {LoadingButton} from "src/shared/ui";


type Props = {
    id: IService["id"]
}


export default function ServiceCardTariffs({
                                               id
                                           }: Props) {
    const [selected, setSelected] = useState<null | string>(null)
    const {
        openModal,
        closeModal
    } = useModal()
    const {refetch: refetchEventDetails} = usePurchasedEventDetailsListQuery({
        params: undefined,
        queryParams: {
            page: 1,
            perPage: 5
        }
    }, {
        enabled: false
    })

    const navigate = useNavigate()
    const {data: {data} = {}} = useTariffsQuery({
        queryParams: {
            serviceId: id,
            page: 1,
            perPage: 4
        },
        params: undefined
    })
    const {mutateAsync: mutateOrder} = usePurchasedEventDetailsOrderMutation()

    const handleOpen = () => {
        const key = 'order-modal'

        openModal({
            key,
            component: (
                <ModalWrapper
                    dialogTitle="Решение добавлено к заказу"
                    onClose={closeModal.bind(null, key)}
                >
                    <Box>
                        <Typography>
                            Решение успешно добавлено. Продолжить выбор решений или перейти к заказу для оформления?
                        </Typography>

                        <Box
                            mt={2}
                            sx={{
                                display: 'flex',
                                gap: '16px',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                onClick={() => {
                                    navigate('/solutions')
                                    closeModal(key)
                                }}
                            >
                                Продолжить выбор решений
                            </Button>

                            <LoadingButton
                                variant="contained"
                                onClick={async () => {
                                    const data = await refetchEventDetails()
                                    const newOrder = data?.data?.data?.find(o => o.state === 'new')

                                    if (newOrder) {
                                        navigate(`/user/orders/${newOrder.id}`)
                                    } else {
                                        navigate('/user/orders')
                                    }
                                    closeModal(key)
                                }}
                            >
                                Перейти к заказу
                            </LoadingButton>
                        </Box>
                    </Box>
                </ModalWrapper>
            )
        })
    }

    const handleOrder = async () => {
        const tariff = data?.find((t) => t.id === selected)

        if (!tariff) return Promise.resolve()

        await mutateOrder({
            body: {
                tariff: tariff.id,
                service: id
            }
        })

        handleOpen()

        return Promise.resolve()
    }

    return (
        <Maybe
            when={!!data?.length}
            fallback={<ServiceCardTariffsIncludedInFallback/>}
        >
            <TransitionGroup>
                <Box>
                    <Typography
                        variant="h5"
                        fontWeight={700}
                        textAlign="center"
                        sx={{
                            marginBottom: '16px'
                        }}
                    >
                        Выберите Тариф:
                    </Typography>
                    <Grid container spacing={3}>
                        {data?.map(({id, name, code, description}, i) => (
                            <Grow
                                in
                                exit={false}
                                appear
                                timeout={{
                                    enter: getLimitedEnterTimeout(300 + i * 100),
                                }}
                            >
                                <Grid
                                    key={id}
                                    item xs={6}
                                >
                                    <EntityListItem
                                        sx={selected === id ? {
                                            border: '3px solid #1169BA'
                                        } : {
                                            border: '3px solid #F1F1F1'
                                        }}
                                        onClick={() => {
                                            if (selected === id) {
                                                setSelected(null)
                                                return
                                            }

                                            setSelected(id)
                                        }}
                                        title={name}
                                        height={250}
                                        description={(
                                            <Typography
                                                color="textSecondary"
                                                fontWeight="500"
                                                lineHeight="20px"
                                                fontFamily={FontFamily.ibmMono}
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(description),
                                                }}
                                                sx={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    display: '-webkit-box',
                                                    '-webkit-line-clamp': '8',
                                                    '-webkit-box-orient': 'vertical',
                                                }}
                                            />
                                        )}
                                        showFullDescription
                                        caption={""}
                                        bgColor={selected === id ? 'rgba(192,236,255,0.6)' : '#F1F1F1'}
                                    />
                                </Grid>
                            </Grow>
                        ))}
                    </Grid>

                    <Box mt={3} textAlign="center">
                        <Button
                            onClick={handleOrder}
                            variant="contained"
                            disabled={!selected}
                        >
                            Добавить к заказу
                        </Button>
                    </Box>
                </Box>
            </TransitionGroup>
        </Maybe>
    )
}
