import React from 'react'
import {Alert, Box, Button, Typography} from "@mui/material";
import {IService} from "src/shared/services/ApiService";
import {ModalWrapper, useModal} from "src/shared/providers";
import {ManagerModal} from "src/features/Services/ui/Service/ServiceCard/ManagerModal";
import {AlertDangerIcon, SuccessModal} from "src/shared/ui";
import DOMPurify from 'dompurify'


type Props = {
    data: IService
}


export default function ServiceCardBody({
                                            data: {
                                                name,
                                                description
                                            }
                                        }: Props) {
    const {
        openModal,
        closeModal
    } = useModal()

    const handleOpen = () => {
        const key = 'manager-modal'

        openModal({
            key,
            component: (
                <ModalWrapper
                    dialogTitle="Связь с менеджером"
                    onClose={closeModal.bind(null, key)}
                >
                    <ManagerModal
                        onSuccess={() => {
                            closeModal(key)
                            handleOpenSuccessModal()
                        }}/>
                </ModalWrapper>
            )
        })
    }

    const handleOpenSuccessModal = () => {
        const key = 'success-modal'

        openModal({
            key,
            component: (
                <ModalWrapper
                    dialogTitle="Успешно отправлено!"
                    onClose={closeModal.bind(null, key)}
                >
                    <SuccessModal
                        text="Спасибо за заявку, наш менеджер уже начал обработку вашего запроса."
                    />
                </ModalWrapper>
            )
        })
    }

    return (
        <Box>
            <Box mt={4.5}>
                <Typography
                    variant="h5"
                    fontWeight={700}
                    sx={{
                        marginBottom: '12px'
                    }}
                >
                    Где и зачем используется
                </Typography>

                <Typography
                    fontWeight={500}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(description),
                    }}
                />
            </Box>
            {/*<Box mt={4.5}>*/}
            {/*    <Typography*/}
            {/*        variant="h5"*/}
            {/*        fontWeight={700}*/}
            {/*        sx={{*/}
            {/*            marginBottom: '12px'*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        Где и зачем используется OSINT*/}
            {/*    </Typography>*/}

            {/*    <Typography fontWeight={500}>*/}
            {/*        OSINT как обособленная дисциплина зародилась в США в 40-х годах прошлого века вместе с учреждением*/}
            {/*        Службы мониторинга зарубежных трансляций. Ее сотрудники записывали и анализировали коротковолновые*/}
            {/*        радиопередачи иностранных государств, после чего полученные данные передавали в виде отчетов военным*/}
            {/*        и*/}
            {/*        разведывательным органам. По информации от некоторых представителей ЦРУ и Пентагона, руководство США*/}
            {/*        получало 70–90% данных из открытых источников и только 30–10% — из агентурных. Сегодня разведка на*/}
            {/*        основе открытых источников используется не только в государственных органах безопасности и обороны,*/}
            {/*        но и*/}
            {/*        в коммерческих компаниях, аналитических агентствах, политических организациях и пр.*/}
            {/*    </Typography>*/}
            {/*</Box>*/}

            {/*<Box mt={4.5}>*/}
            {/*    <Typography*/}
            {/*        variant="h5"*/}
            {/*        fontWeight={700}*/}
            {/*        sx={{*/}
            {/*            marginBottom: '12px'*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        С помощью OSINT можно:*/}
            {/*    </Typography>*/}

            {/*    <Typography fontWeight={500}>*/}
            {/*        <ul>*/}
            {/*            <li>*/}
            {/*                cобирать информацию о конкурентах и искать конкурентные преимущества;*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                анализировать защищенность объекта, выявлять уязвимые точки системы безопасности;*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                находить информационные утечки;*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                выявлять возможные угрозы, их источники и направленност*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                анализировать киберпреступления (кражи данных, взломы и т.д.).*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
        </Box>
    )
}
