import React, {useEffect, useMemo, useState} from "react";
import {
    Box,
    Button,
    Chip,
    Collapse,
    Divider, Grow,
    IconButton,
    Paper,
    Skeleton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {
    IPurchasedEventDetailsOrder,
    usePurchasedEventDetailsOrderSaveQuestionsMutation,
    usePurchasedEventDetailsOrdersQuery
} from "src/shared/services/ApiService";
import {FontFamily} from "src/shared/utils/font";
import {
    IPurchasedEventDetailsOrderQuestion
} from "src/shared/services/ApiService/interfaces/IPurchasedEventDetailsOrderQuestion";
import {FormikHandlers, useFormik} from "formik";
import * as Yup from "yup";
import {ArrowDownIcon, Maybe} from "src/shared/ui";
import {getLimitedEnterTimeout} from "src/shared/utils";
import {TransitionGroup, CSSTransition} from 'react-transition-group'


type Props = {
    order: IPurchasedEventDetailsOrder,
    onSaveQuestion: (b: boolean) => void,
}

type QuestionProps = {
    index: number,
    question: IPurchasedEventDetailsOrderQuestion
    name: string,
    value?: number | string,
    onChange: FormikHandlers["handleChange"],
    onBlur: FormikHandlers["handleBlur"],
    error?: string
}

const Question = ({
                      name,
                      value,
                      onChange,
                      onBlur,
                      error,
                      index,
                      question
                  }: QuestionProps) => {
    const isNumber = ['container', 'int'].includes(question.type)

    return (
        <Box display="flex" gap="16px">
            <Box>
                <Paper
                    variant="outlined"
                    sx={{
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '100%'
                    }}>
                    {index + 1}
                </Paper>
            </Box>


            <Box sx={{
                width: '100%'
            }}>
                <Typography fontSize="16px">
                    {question.name}
                </Typography>

                <Typography fontSize="14px" color="textSecondary">
                    {question.description}
                </Typography>

                <Box mt={1.5}>
                    <TextField
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        fullWidth
                        type={isNumber ? 'number' : 'text'}
                        required={question.required}
                        placeholder={isNumber ? 'Введите число' : 'Введите строку'}
                        error={!!error}
                        helperText={error}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default function OrderService({
                                         order,
                                         onSaveQuestion,
                                     }: Props) {
    const [lastSavedValues, setLastSavedValues] = useState({})
    const [opened, setOpened] = useState(true)
    const {mutateAsync: saveQuestions, isLoading} = usePurchasedEventDetailsOrderSaveQuestionsMutation()
    const {data, isFetching} = usePurchasedEventDetailsOrdersQuery({
        params: {
            id: order.orderId
        },
        queryParams: {}
    }, {
        enabled: !!order.orderId
    })

    const validationSchema = useMemo(() => {
        return Yup.object().shape(data?.data?.reduce((acc, q) => {
            let validation = null

            if (['container', 'int'].includes(q.type)) {
                validation = Yup.number()
                    .min(q.minValue ? Number(q.minValue) : Number.MAX_SAFE_INTEGER, `Значение не должно быть меньше ${q.minValue}`)
                    .max(q.maxValue ? Number(q.maxValue) : Number.MAX_SAFE_INTEGER, `Значение не должно превышать ${q.maxValue}`)
            } else {
                validation = Yup.string()
            }

            if (q.required) {
                validation = validation.required('Необходимо указать значение!')
            }

            return {
                ...acc,
                [q.questionId]: validation,
            }
        }, {}) || {})
    }, [data?.data])

    const {
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        setValues,
    } = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        onSubmit: (v) => {
        }
    })


    const requiredQuestions = data?.data?.filter((q) => q.required) || []
    const notRequiredQuestions = data?.data?.filter((q) => !q.required) || []

    const handleBlurWrapper = (e: any) => {
        const [name, value] = [e.target.name, e.target.value]

        // @ts-ignore
        if (lastSavedValues[name] === undefined || String(lastSavedValues[name]) !== String(value)) {
            saveQuestions({
                params: {
                    id: order.orderId
                },
                body: {
                    answers: [{
                        "questionId": name,
                        "answer": value || undefined
                    }]
                }
            })

            setLastSavedValues(values)
        }


        return handleBlur(e)
    }

    useEffect(() => {
        if (!data?.data) return

        setValues(data?.data?.reduce((acc, q) => ({
            ...acc,
            [q.questionId]: q.answer || undefined
        }), {}) || {})
    }, [data?.data]);

    useEffect(() => {
        onSaveQuestion(isLoading)
    }, [isLoading]);


    return (
        <Box sx={{
            width: '100%',
            paddingLeft: '32px',
            paddingBottom: '16px'
        }}>
            <Paper
                elevation={0}
                sx={{
                    backgroundColor: '#FFE8F0',
                    padding: '20px',
                    marginBottom: '32px'
                }}
            >
                <Typography fontFamily={FontFamily.ibmMono} sx={{
                    fontSize: '22px',
                    fontWeight: '700',
                    textAlign: 'left',
                    display: 'flex',
                    gap: '16px'
                }}>
                    Тариф {!isFetching ? order.tariff.name : (<Skeleton width="100px"/>)}
                </Typography>
            </Paper>

            <Maybe
                when={!isFetching}
                fallback={(
                    <Stack spacing={2}>
                        <Skeleton width="100%" height={30}/>
                        <Skeleton width="100%" height={30}/>
                        <Skeleton width="100%" height={30}/>
                        <Skeleton width="100%" height={30}/>
                        <Skeleton width="100%" height={30}/>
                    </Stack>
                )}
            >
                <TransitionGroup>
                    <Stack spacing={2}>
                        {requiredQuestions?.map((q, index) => (
                            <CSSTransition
                                key={q.questionId}
                                timeout={1}
                            >
                                <Grow
                                    in
                                    exit={false}
                                    appear
                                    timeout={{
                                        enter: getLimitedEnterTimeout(300 + index * 100),
                                    }}
                                >
                                    <Box>
                                        <Question
                                            key={q.questionId}
                                            question={q}
                                            index={index}
                                            name={q.questionId}
                                            onChange={handleChange}
                                            onBlur={handleBlurWrapper}
                                            // @ts-ignore
                                            error={touched[q.questionId] ? errors[q.questionId] : undefined}
                                            // @ts-ignore
                                            value={values[q.questionId]}
                                        />
                                    </Box>
                                </Grow>
                            </CSSTransition>
                        ))}
                    </Stack>
                </TransitionGroup>

                <Maybe when={!!notRequiredQuestions?.length}>
                    <Box mt={3}>
                        <Button
                            onClick={() => setOpened((opened) => !opened)}
                            fullWidth
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '16px',
                                borderRadius: '8px',
                                padding: '0 8px',
                                cursor: 'pointer',
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                            >
                                Дополнительные вопросы
                            </Typography>

                            <Divider
                                variant="middle"
                                flexItem
                                sx={{
                                    flexGrow: 1,
                                    borderBottomWidth: '2px',
                                    height: '22px'
                                }}
                            />

                            <IconButton>
                                <ArrowDownIcon sx={{
                                    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)'
                                }}/>
                            </IconButton>
                        </Button>

                        <Box>
                            <Collapse in={opened}>
                                <TransitionGroup>
                                    <Stack spacing={2}>
                                        {notRequiredQuestions?.map((q, index) => (
                                            <CSSTransition
                                                key={q.questionId}
                                                timeout={1}
                                            >
                                                <Grow
                                                    in
                                                    exit={false}
                                                    appear
                                                    timeout={{
                                                        enter: getLimitedEnterTimeout(300 + index * 100),
                                                    }}
                                                >
                                                    <Box>
                                                        <Question
                                                            key={q.questionId}
                                                            question={q}
                                                            index={index + requiredQuestions.length}
                                                            name={q.questionId}
                                                            onChange={handleChange}
                                                            onBlur={handleBlurWrapper}
                                                            // @ts-ignore
                                                            error={touched[q.questionId] ? errors[q.questionId] : undefined}
                                                            // @ts-ignore
                                                            value={values[q.questionId]}
                                                        />
                                                    </Box>
                                                </Grow>
                                            </CSSTransition>
                                        ))}
                                    </Stack>
                                </TransitionGroup>
                            </Collapse>
                        </Box>
                    </Box>
                </Maybe>
            </Maybe>
        </Box>
    )
}