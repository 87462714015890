import {Box, Button, Container, Grid, Stack, TextField, Typography, Grow, CircularProgress} from "@mui/material";
import {ModalWrapper, useModal, useNotification} from "src/shared/providers";
import {useOrganizationProjectsQuery, useProjectsCreateMutation} from "src/shared/services/ApiService";
import {useDecodedToken} from "src/shared/utils/hooks/useDecodedToken";
import {ProjectListItem} from "src/features/UserProjects/ui/ProjectListItem";
import Maybe from "src/shared/ui/Maybe/Maybe";
import {TransitionGroup} from 'react-transition-group'
import {getLimitedEnterTimeout} from "src/shared/utils";
import {ManagerModal} from "src/features/Services/ui/Service/ServiceCard/ManagerModal";
import React from "react";
import {ProjectCreateModal} from "src/features/UserProjects/ui/ProjectCreateModal";
import {SuccessModal} from "src/shared/ui";


export function Projects() {
    const {
        openModal,
        closeModal,
    } = useModal()
    const {showNotification} = useNotification()
    const tokenInfo = useDecodedToken()
    const {
        data: {data} = {},
        isLoading
    } = useOrganizationProjectsQuery({
        params: {
            organizationId: tokenInfo?.userInfo.currentOrganization.id as string
        },
        queryParams: {
            page: 1,
            perPage: 50
        },
    }, {
        enabled: !!tokenInfo?.userInfo.currentOrganization.id,
    })

    const handleCreateProject = async () => {
        const key = 'manager-modal'

        await (new Promise((resolve, reject) =>
            openModal({
                key,
                component: (
                    <ModalWrapper
                        dialogTitle="Добавление нового пакета лицензий"
                        onClose={() => {
                            closeModal(key)
                            reject()
                        }}
                    >
                        <ProjectCreateModal
                            onSuccess={() => {
                                closeModal(key)
                                resolve(null)
                            }}/>
                    </ModalWrapper>
                )
            })
        ))


        const keySuccessModal = 'success-modal'

        openModal({
            key: keySuccessModal,
            component: (
                <ModalWrapper
                    dialogTitle="Пакет лицензий успешно создан!"
                    onClose={closeModal.bind(null, keySuccessModal)}
                >
                    <SuccessModal
                        text="Теперь к данному пакету лицензий можно привязать купленные услуги!"
                    />
                </ModalWrapper>
            )
        })
    }

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5" fontWeight={700}>
                    Мои пакеты лицензий
                </Typography>


                <Button
                    onClick={handleCreateProject}
                    variant="contained"
                >
                    Добавить пакет лицензий
                </Button>
            </Box>

            <Box mt={2} mb={3}>
                <Typography>
                    Какой-то текст, поясняющий суть и возможность данного раздела, важно что информация здесь была
                    полезной и имела смысл как напоминание, иначе этот текст лучше убрать.
                </Typography>
            </Box>

            <Box>
                <Maybe when={isLoading}>
                    <Box textAlign="center" mt={8}>
                        <CircularProgress/>
                    </Box>
                </Maybe>

                <Maybe
                    when={!!data?.length}
                >
                    <TransitionGroup>
                        <Grid container columnSpacing={5} rowSpacing={3}>
                            {data?.map((project, i) => (
                                <Grid item xs={6}>
                                    <Grow
                                        in
                                        exit={false}
                                        appear
                                        timeout={{
                                            enter: getLimitedEnterTimeout(300 + i * 100),
                                        }}
                                    >
                                        <Box>
                                            <ProjectListItem data={project}/>
                                        </Box>
                                    </Grow>
                                </Grid>
                            ))}
                        </Grid>
                    </TransitionGroup>
                </Maybe>

                <Maybe when={!isLoading && data?.length === 0}>
                    <Box mt={8} mb={2} textAlign="center">
                        <Typography
                            variant="h6"
                            fontWeight="600"
                        >
                            Список пуст
                        </Typography>
                    </Box>
                </Maybe>
            </Box>

        </Box>
    )
}
