import {IServicePortfolio, SimpleListResponse} from "src/shared/services/ApiService";
import {generateQuery} from "src/shared/services/ApiService/helpers";

export type UseServicePortfoliosQueryVariables = {
    sort?: { name: string, sort: 'ASC' | 'DESC' }[]
}

export type UseServicePortfoliosQueryResponse = SimpleListResponse<IServicePortfolio>

export const [useServicePortfoliosQuery, getUseServicePortfoliosQueryKey] = generateQuery<{ projectId: string },
    UseServicePortfoliosQueryVariables,
    UseServicePortfoliosQueryResponse>(
    params => `projects/${params.projectId}/service-portfolios`,
    'ServicePortfolios'
)
