import React from 'react'
import {Fade, Grid, Paper, Grow} from "@mui/material";
import {slice} from "lodash";
import {EntityListItem, ListLoader, Maybe} from "src/shared/ui";
import {MOCK_TARIFFS, CARD_STYLES_BY_CATEGORY} from "src/features/Tariffs/ui/Tariffs/TariffsList/mock";
import {useTariffsQuery} from "src/shared/services/ApiService";
import {CARD_STYLES_BY_CODE} from "src/features/Tariffs/ui/Tariffs/TariffsList/contants";
import {useNavigate} from "react-router-dom";
import {TransitionGroup} from 'react-transition-group'
import {getLimitedEnterTimeout} from "src/shared/utils";


export default function AnotherTariffs() {
    const navigate = useNavigate()

    const {data: {data} = {}} = useTariffsQuery({
        queryParams: {
            page: 1,
            perPage: 5
        },
        params: undefined
    })
    return (
        <Paper
            variant="outlined"
            sx={{
                padding: '28px 32px'
            }}
        >
            <Maybe
                when={!!data?.length}
                fallback={<ListLoader xs={12} count={3}/>}
            >
                <TransitionGroup>
                    <Grid container spacing={3}>
                        {data?.map(({id, name, shortDescription, code}, i) => (
                            <Grow
                                in
                                exit={false}
                                appear
                                timeout={{
                                    enter: getLimitedEnterTimeout(300 + i * 100),
                                }}
                            >
                                <Grid
                                    key={id}
                                    item xs={12}
                                >
                                    <EntityListItem
                                        onClick={() => navigate(`/tariffs/${id}`)}
                                        title={name}
                                        description={shortDescription}
                                        caption={""}
                                        bgColor={CARD_STYLES_BY_CODE[code as 'T0']?.bgColor || '#fff'}
                                        tags={[{
                                            name: code,
                                            color: CARD_STYLES_BY_CODE[code as 'T0']?.tagColor || '#fff'
                                        }]}
                                    />
                                </Grid>
                            </Grow>
                        ))}
                    </Grid>
                </TransitionGroup>
            </Maybe>
        </Paper>
    )
}
