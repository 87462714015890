import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function ArrowLeftIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="24" height="20" viewBox="0 0 24 20" fill="none"
            {...props}
        >
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M23.0571 11.0971C23.5896 11.0238 24 10.5606 24 10.0001C24 9.38872 23.5116 8.89305 22.9091 8.89305L3.73342 8.89305L10.6604 1.89154L10.7663 1.7676C11.0841 1.33486 11.0501 0.719738 10.6636 0.325882C10.2385 -0.107358 9.54773 -0.10881 9.12083 0.322641L0.336407 9.20054C0.284092 9.2514 0.236757 9.30748 0.195221 9.36798C-0.102184 9.80032 -0.0602875 10.4001 0.32089 10.7853L9.12089 19.6774L9.24347 19.7844C9.67122 20.105 10.2772 20.0679 10.6637 19.6741C11.0888 19.2408 11.0873 18.5398 10.6603 18.1084L3.7317 11.1072L22.9091 11.1072L23.0571 11.0971Z"
                  fill="#130F26"/>
        </SvgIcon>
    );
}
