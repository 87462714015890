import React from "react";
import {Box, CircularProgress} from "@mui/material";


type Props = {
    isFetchingNextPage: boolean,
    hasNextPage?: boolean
}

export const InfinityLoader = React.forwardRef<HTMLElement, Props>(({
                                                                 isFetchingNextPage,
                                                                 hasNextPage,
                                                             }, ref) => (
    <>
        {(isFetchingNextPage || hasNextPage) && (
            <Box ref={ref} textAlign="center" pt={2} >
                <CircularProgress />
            </Box>
        )}
    </>
))
