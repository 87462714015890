import React from "react";
import {Box, Chip, Icon, IconButton, Typography} from "@mui/material";
import {IPurchasedEventDetailsOrder} from "src/shared/services/ApiService";
import {FontFamily} from "src/shared/utils/font";
import CloseIcon from "@mui/icons-material/Close";
import {DeleteIcon} from "src/shared/ui";

type Props = {
    order: IPurchasedEventDetailsOrder,
    selected?: boolean,
    onDelete: (id: string | number) => void
}

export default function OrderServiceTab({
                                            order,
                                            selected,
                                            onDelete
                                        }: Props) {
    const isFinished = order.questionCount.all === order.questionCount.finished

    return (
        <Box sx={{
            width: '100%',
            position: 'relative'
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '-8px',
                    right: '-10px'
                }}
            >
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        onDelete(order.orderId)
                    }}
                >
                    <DeleteIcon fontSize="small"/>
                </IconButton>
            </Box>
            <Typography fontFamily={FontFamily.ibmMono} sx={{
                fontSize: '16px',
                fontWeight: '700',
                marginBottom: '12px',
                textAlign: 'left',
                color: selected ? '#0048D3' : 'text.primary',
                maxWidth: '315px',
                paddingRight: '16px',
            }}>
                {order.service.name}
            </Typography>

            <Box mt={2} textAlign="right">
                <Chip
                    label={isFinished ?
                        'Решение уточнено' :
                        `Необходимо уточнение: ${order.questionCount.finished}/${order.questionCount.all}`
                    }
                    size="small"
                    sx={{
                        backgroundColor: isFinished ? '#34C759' : '#FF9500',
                        color: 'white',
                        fontWeight: 600,
                        fontSize: '12px',
                        borderRadius: '4px',
                        height: '26px'
                    }}
                />
            </Box>
        </Box>
    )
}