import React, {useEffect} from "react";
import {Box, Button, Fade, Skeleton, Stack, Typography, Grow} from "@mui/material";
import {TariffServiceAccordion} from "src/features/Tariffs/ui/Tariff/TariffCalculate/TariffServiceAccordion";
import {IService, useServicesQuery} from "src/shared/services/ApiService";
import {useFormik} from "formik";
import {flow, mapValues, pickBy} from 'lodash/fp'
import {SERVICE_PARAMS_LABELS} from "src/entities/Service";
import {Maybe, SuccessModal} from "src/shared/ui";
import {range} from "lodash";
import LoadingButton from '@mui/lab/LoadingButton';
import {
    useTariffServicePurchaseMutation,
} from "src/shared/services/ApiService/mutations/useTariffServicePurchase";
import {ModalWrapper, useModal, useNotification} from "src/shared/providers";
import {NotificationStatus} from "src/shared/providers/NotificationProvider/useNotification";
import {getLimitedEnterTimeout} from "src/shared/utils";
import {TransitionGroup} from 'react-transition-group'


type Props = {
    id: IService["id"]
}

type FormValues = Record<string, Record<string, number>>

export function TariffCalculate({
                                    id
                                }: Props) {
    const {
        openModal,
        closeModal
    } = useModal()

    const {showNotification} = useNotification()
    const {mutateAsync: buyTariffMutation} = useTariffServicePurchaseMutation({
        onSuccess: () => {
            showNotification(NotificationStatus.success, {
                message: 'Тариф успешно отрпавлен на расчет!'
            })
        },
        onError: () => {
            showNotification(NotificationStatus.error, {
                message: 'Не удалось отправить тариф на расчет!'
            })
        }
    })
    const {data: {data} = {}, isLoading} = useServicesQuery({
        queryParams: {
            tariffId: id,
            page: 1,
            perPage: 100
        },
        params: undefined
    })

    const handleOpenSuccessModal = () => {
        const key = 'success-modal'

        openModal({
            key,
            component: (
                <ModalWrapper
                    dialogTitle="Успешно отправлено!"
                    onClose={closeModal.bind(null, key)}
                >
                    <SuccessModal
                        text="Спасибо за заявку, наш менеджер свяжеться с вами в течение часа после обработки данного запроса и подберет оптимальный тариф."
                    />
                </ModalWrapper>
            )
        })
    }

    const {
        isSubmitting,
        values,
        handleChange,
        handleSubmit,
        setValues,
    } = useFormik<FormValues>({
        initialValues: {},
        onSubmit: async (v) => {
            const tariff = Object.keys(v).map((key) => ({serviceId: key, ...v[key]}))

            await buyTariffMutation({
                params: {
                    tariffId: id,
                },
                body: {
                    // @ts-ignore
                    services: tariff
                }
            })

            handleOpenSuccessModal()
        }
    })

    useEffect(() => {
        if (data) {
            setValues(
                data?.reduce((acc, o) => ({
                    ...acc,
                    [o.id]: flow(
                        pickBy((v, key) => SERVICE_PARAMS_LABELS[key as 'ipQuantity'] && !!v),
                        mapValues((v) => 0)
                    )(o)
                }), {})
            )
        }
    }, [data]);


    return (
        <Box my={4} sx={{
            backgroundColor: '#FAF9F7',
            padding: '20px 24px',
            borderRadius: '16px'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '12px'
            }}>
                <Typography
                    variant="h5"
                    fontWeight={700}
                >
                    Рассчитать стоимость Тарифа
                </Typography>

                <Button
                    sx={{
                        textTransform: 'none',
                        color: '#000',
                    }}
                >
                    Раскрыть все
                </Button>
            </Box>

            <Maybe when={isLoading}>
                <Box>
                    {range(4).map((i) => (
                        <Skeleton height={49}/>
                    ))}
                </Box>
            </Maybe>

            <Maybe when={!!data?.length}>
                <TransitionGroup>
                    <Stack spacing={1}>
                        {
                            data?.map((service, i) => (
                                <Grow
                                    in
                                    exit={false}
                                    appear
                                    timeout={{
                                        enter: getLimitedEnterTimeout(300 + i * 100),
                                    }}
                                >
                                    <Box>
                                        <TariffServiceAccordion
                                            key={service.id}
                                            name={service.id}
                                            // @ts-ignore
                                            values={values[service.id]}
                                            onChange={handleChange}
                                            service={service}
                                        />
                                    </Box>
                                </Grow>
                            ))
                        }
                    </Stack>
                </TransitionGroup>
            </Maybe>


            <LoadingButton
                loading={isSubmitting}
                size="large"
                onClick={() => handleSubmit()}
                variant="contained"
                sx={{
                    marginTop: '20px',
                    textTransform: 'none'
                }}
            >
                Отправить на расчет
            </LoadingButton>
        </Box>
    )
}
