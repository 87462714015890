export const CARD_STYLES_BY_CODE = {
    T0: {
        bgColor: '#EFEFFD',
        tagColor: '#5856D6'
    },
    T1: {
        bgColor: '#FFE8F0',
        tagColor: '#F72F77'
    },
    T2: {
        bgColor: '#E5F7FF',
        tagColor: '#5AC8FA'
    },
    T3: {
        bgColor: '#D6FDE0',
        tagColor: '#34C759'
    },
}
