import {Navigate, RouteObject} from "react-router-dom";
import {Tariff, Tariffs} from "../ui";
import {BaseLayout, HomeLayout, RbacGuardLayout} from "src/shared/ui";
import {PermissionsEnum} from "src/shared/services/RbacServices";

export const routes: RouteObject[] = [
    {
        path: '/tariffs',
        element: (
            <HomeLayout>
                <RbacGuardLayout permission={PermissionsEnum.CAN_ACCESS}/>
            </HomeLayout>
        ),
        children: [
            {
                path: '',
                element: <Tariffs/>
            },
        ]

    },
    {
        path: '/tariffs/:id',
        element: (
            <BaseLayout>
                <RbacGuardLayout permission={PermissionsEnum.CAN_ACCESS}/>
            </BaseLayout>
        ),
        children: [
            {
                path: '',
                element: <Tariff/>
            },
        ]
    },
]
