import {generateMutation} from "src/shared/services/ApiService/helpers";


export type UsePurchasedEventDetailsConfirmMutationVariables = {}

export type UsePurchasedEventDetailsConfirmMutationResponse = {}


export const usePurchasedEventDetailsConfirmMutation = generateMutation<{
    id: string | number
},
    UsePurchasedEventDetailsConfirmMutationVariables,
    UsePurchasedEventDetailsConfirmMutationResponse>(
    'post',
    params => `purchased-event-details/${params?.id}/confirm`
)
