import useIntersectionObserver from "./useIntersectionObserver";
import {useEffect, useRef, useState} from "react";

type Props = {
    loading: boolean,
    hasNextPage: boolean,
    onLoadMore: (a?: any) => Promise<any>,
    disabled: boolean,
    rootMargin: string,
}

export default function useInfiniteScroll({
                                              loading,
                                              hasNextPage,
                                              disabled,
                                              onLoadMore,
                                              rootMargin,
                                          }: Props) {
    const [ref, result] = useIntersectionObserver({rootMargin});
    const [busy, setBusy] = useState(false)

    const isVisible = Boolean(result.entry?.isIntersecting);

    const canLoadMore = !loading && hasNextPage && !disabled && isVisible && !busy

    useEffect(() => {
        if (canLoadMore) {
            setBusy(true)
            onLoadMore?.()?.finally(() => {
                setTimeout(() => {
                    setBusy(false)
                }, 1500)
            })
        }

    }, [canLoadMore]);


    return [ref]
}
