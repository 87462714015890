import {generateMutation} from "src/shared/services/ApiService/helpers";
import {EntityResponse, IUserMe} from "src/shared/services/ApiService";


export type UseUsersMeUpdateMutationVariables = {
    email: string,
    phone: string
    fio: string
}

export type UseUsersMeUpdateMutationResponse = EntityResponse<IUserMe>


export const useUsersMeUpdateMutation = generateMutation<
    undefined,
    UseUsersMeUpdateMutationVariables,
    UseUsersMeUpdateMutationResponse
    >(
    'post',
    params => 'users/me'
)
