import {Link} from "react-router-dom";
import {Box, Card, Chip, Stack, SxProps, Typography} from "@mui/material";
import React from "react";
import {FontFamily} from "src/shared/utils/font";
import {Maybe, ThunderIcon} from "src/shared/ui";

type Props = {
    onClick?: () => void,
    title: string | React.ReactNode
    description: string | React.ReactNode
    caption?: string | React.ReactNode | undefined
    tags?: { name: string | React.ReactNode, color: string }[],
    bgColor?: string,
    height?: number | string,
    descriptionLineClamp?: number,
    updated?: number
    showFullDescription?: boolean,
    sx?: SxProps
}

export function EntityListItem({
                                   onClick,
                                   title,
                                   description,
                                   caption,
                                   tags,
                                   bgColor,
                                   height = 223,
                                   descriptionLineClamp = 2,
                                   updated = 0,
                                   showFullDescription = false,
                                   sx = {}
                               }: Props) {
    return (
        <Card
            onClick={onClick}
            elevation={0}
            sx={{
                cursor: 'pointer',
                height: height,
                boxSizing: 'border-box',
                padding: '20px',
                backgroundColor: bgColor || '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                ...sx,
            }}
        >
            <Box>
                <Typography
                    color="textSecondary"
                    variant="caption"
                >
                    {caption}
                </Typography>

                <Typography
                    lineHeight="24px"
                    fontFamily={FontFamily.ibmMono}
                    sx={{
                        fontSize: '18px',
                        fontWeight: 700,
                        margin: '8px 0 12px 0'
                    }}>
                    {title}
                </Typography>

                <Typography
                    color="textSecondary"
                    fontWeight="500"
                    lineHeight="20px"
                    fontFamily={FontFamily.ibmMono}
                    sx={showFullDescription ? {} : {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        '-webkit-line-clamp': descriptionLineClamp.toString(),
                        '-webkit-box-orient': 'vertical',
                    }}
                >
                    {description}
                </Typography>
            </Box>

            <Box sx={{
                // display: 'flex',
                // justifyContent: 'space-between',
                // gap: '8px',
                // flexWrap: 'wrap'
            }}>
                <Maybe when={updated !== 0}>
                    <Box mb={1}>
                        <Chip
                            size="small"
                            icon={<ThunderIcon/>} label={updated === 1 ? "Новый" : "Обновлено"}
                            sx={{
                                backgroundColor: '#000000',
                                color: '#FFD600',
                                fontSize: '12px',
                                borderRadius: '50px',
                                fontWeight: '600'
                            }}
                        />
                    </Box>
                </Maybe>

                <Box>
                    <Stack direction="row" spacing={1}>
                        {tags?.map(({name, color}) => (
                            <Chip
                                sx={{
                                    backgroundColor: color,
                                    color: 'white',
                                    borderRadius: '4px',
                                    height: '26px'
                                }}
                                label={(
                                    <Box display="flex" gap="8px" alignItems="center">
                                        <Box sx={{
                                            width: '5px',
                                            height: '5px',
                                            backgroundColor: 'white',
                                            borderRadius: '100%'
                                        }}/>
                                        <Typography
                                            variant="caption"
                                            fontWeight="700"
                                            display="block"
                                            lineHeight="12px"
                                        >
                                            {name}
                                        </Typography>
                                    </Box>
                                )}
                            />
                        ))}
                    </Stack>
                </Box>
            </Box>
        </Card>
    )
}
