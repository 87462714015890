import {useMemo, useCallback} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import qs from 'qs'

const keywords: { [index: string]: boolean | null | undefined } = {
    true: true,
    false: false,
    null: null,
    undefined,
}

function decoder(str: string, decoder: unknown, charset: string) {
    const strWithoutPlus = str // str.replace(/\+/g, ' ')

    if (charset === 'iso-8859-1') {
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape)
    }

    if (str in keywords) {
        return keywords[str]
    }

    try {
        return decodeURIComponent(strWithoutPlus)
    } catch (e) {
        return strWithoutPlus
    }
}

export const queryParseConfig = {
    ignoreQueryPrefix: true,
    decoder,
    strictNullHandling: true,
}

export const queryStringifyConfig = {
    encode: false,
    strictNullHandling: true,
}

export type UseQueryParamsType = [
    Record<string, unknown>,
    (e: Record<string, unknown> | string) => void
]

export const qsStringify = (params: Record<string, unknown> | string) => qs.stringify(params, queryStringifyConfig)

export const useQueryParams = (): [
    Record<string, unknown>,
    (e: Record<string, unknown> | string) => void
] => {
    const {pathname, search} = useLocation()
    const replace = useNavigate()

    const setParams = useCallback(
        (params: Record<string, unknown> | string) => {
            replace(
                `${pathname}?${qsStringify(params)}`,
                {
                    replace: true,
                }
            )
        },
        [pathname]
    )

    const parsedParams = useMemo(
        () => qs.parse(search, queryParseConfig),
        [search]
    )

    return [parsedParams, setParams]
}
