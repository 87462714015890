import {Box, Typography} from "@mui/material";
import {KnobExpandableTable} from "src/shared/ui/KnobTable";
import {getColumnsUnallocated} from "src/features/UserPortfolio/ui/Portfolio/PortfolioProject/columns";
import {
    IServicePurchased,
    useServicesPurchasedQuery
} from "src/shared/services/ApiService";
import {LoadingTable} from "src/features/UserPortfolio/ui/Portfolio/PortfolioProject/LoadingTable";
import {Maybe} from "src/shared/ui";


export function PortfolioProjectServicesUnallocated() {
    const {
        data: {data} = {},
        isLoading,
    } = useServicesPurchasedQuery({
        queryParams: {
            page: 1,
            perPage: 100,
            //projectId: null
        },
        params: undefined
    })

    return (
        <Box>
            <Maybe when={isLoading}>
                <LoadingTable hideHead/>
            </Maybe>

            <Maybe
                when={!!data?.length}
            >
                <KnobExpandableTable<IServicePurchased>
                    hideHead
                    data={data || []}
                    columns={getColumnsUnallocated()}
                    getRowCanExpand={() => false}
                />
            </Maybe>

            <Maybe when={!isLoading && data?.length === 0}>
                <Box mt={8} mb={2} textAlign="center">
                    <Typography
                        variant="h6"
                        fontWeight="600"
                    >
                        Список пуст
                    </Typography>
                </Box>
            </Maybe>
        </Box>
    )
}
