import {ListResponse} from "src/shared/services/ApiService";
import {generateQuery} from "src/shared/services/ApiService/helpers";
import {IServicePurchased} from "src/shared/services/ApiService/interfaces";

export type UseServicesPurchasedQueryVariables = {
    page: string | number,
    perPage: string | number,
    projectId?: string | number
}

export type UseServicesPurchasedQueryResponse = ListResponse<IServicePurchased>

export const [useServicesPurchasedQuery, getUseServicesPurchasedQueryKey] = generateQuery<undefined,
    UseServicesPurchasedQueryVariables,
    UseServicesPurchasedQueryResponse>(
    params => `services/purchased`,
    'ServicesPurchased'
)
