import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function ArrowDownIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="20" height="21" viewBox="0 0 20 21" fill="none"
            {...props}
            style={{
                fill: 'none'
            }}
        >
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10 0.305908C4.47712 0.305908 0 4.78336 0 10.3059C0 15.8285 4.47712 20.3059 10 20.3059C15.5222 20.3059 20 15.8281 20 10.3059C20 4.7837 15.5222 0.305908 10 0.305908ZM10 1.80591C14.6938 1.80591 18.5 5.61212 18.5 10.3059C18.5 14.9997 14.6938 18.8059 10 18.8059C5.30557 18.8059 1.5 15.0001 1.5 10.3059C1.5 5.61176 5.30557 1.80591 10 1.80591ZM6.97631 8.26162C6.68317 8.04314 6.26645 8.06644 5.99961 8.33214C5.70609 8.6244 5.70507 9.09927 5.99733 9.39279L9.46833 12.8788L9.55255 12.9518C9.84663 13.1708 10.2648 13.1464 10.5313 12.8788L14.0023 9.39279L14.0747 9.30852C14.2919 9.01444 14.2668 8.59783 14 8.33214L13.9157 8.2597C13.6216 8.04248 13.205 8.06758 12.9393 8.33442L9.9988 11.2859L7.06027 8.33442L6.97631 8.26162Z"
                  fill="currentColor"/>
        </SvgIcon>
    );
}
