import React from 'react'
import {Box, Paper, Skeleton, Typography} from "@mui/material";

type Props = {}

export default function TariffCardFallback({}: Props) {


    return (
        <Paper
            variant="outlined"
            sx={{
                padding: '28px 32px'
            }}
        >
            <Paper
                elevation={0}
                sx={{
                    backgroundColor: '#F1F1F1',
                    padding: '20px'
                }}
            >
                <Typography variant="caption" color="textSecondary">
                    <Skeleton width={150}/>
                </Typography>

                <Typography variant="h5" fontSize={28} fontWeight={700} sx={{
                    margin: '8px 0 12px 0'
                }}>
                    <Skeleton width={250}/>
                </Typography>

                <Typography fontWeight={500}>
                    <Skeleton width={350}/>
                </Typography>
            </Paper>

            <Box my={4.5}>
                <Typography
                    variant="h5"
                    fontWeight={700}
                    sx={{
                        marginBottom: '12px'
                    }}
                >
                    <Skeleton width={250}/>
                </Typography>

                <Typography fontWeight={500}>
                    <Skeleton width={350}/>
                    <Skeleton width={450}/>
                    <Skeleton width={370}/>
                </Typography>
            </Box>
        </Paper>
    )
}
