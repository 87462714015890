import {generateMutation} from "src/shared/services/ApiService/helpers";


export type UseProjectsUpdateMutationVariables = {
    name: string
    description: string,
    shortDescription: string,
    url: string
}

export type UseProjectsUpdateMutationResponse = {}


export const useProjectsUpdateMutation = generateMutation<{
    projectId: string
},
    UseProjectsUpdateMutationVariables,
    UseProjectsUpdateMutationResponse>(
    'put',
    params => `projects/${params?.projectId}`
)
