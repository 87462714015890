import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function DeleteIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="16" height="18" viewBox="0 0 16 18" fill="none"
            {...props}
            style={{
                fill: 'none'
            }}
        >
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.20546 17.3353C7.07629 17.3353 5.97546 17.3228 4.88629 17.3003C3.49296 17.2728 2.52879 16.3694 2.37129 14.9428C2.10879 12.5761 1.65962 6.99777 1.65546 6.94194C1.62712 6.59777 1.88379 6.2961 2.22796 6.2686C2.56712 6.25944 2.87379 6.49777 2.90129 6.8411C2.90546 6.89777 3.35379 12.4569 3.61379 14.8053C3.70296 15.6161 4.14046 16.0344 4.91212 16.0503C6.99546 16.0944 9.12129 16.0969 11.413 16.0553C12.233 16.0394 12.6763 15.6294 12.768 14.7994C13.0263 12.4711 13.4763 6.89777 13.4813 6.8411C13.5088 6.49777 13.813 6.25777 14.1538 6.2686C14.498 6.29694 14.7546 6.59777 14.7271 6.94194C14.7221 6.9986 14.2705 12.5911 14.0105 14.9369C13.8488 16.3928 12.8871 17.2786 11.4355 17.3053C10.3246 17.3244 9.25296 17.3353 8.20546 17.3353Z"
                  fill="#F72F77"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.2567 4.82635H1.125C0.78 4.82635 0.5 4.54635 0.5 4.20135C0.5 3.85635 0.78 3.57635 1.125 3.57635H15.2567C15.6017 3.57635 15.8817 3.85635 15.8817 4.20135C15.8817 4.54635 15.6017 4.82635 15.2567 4.82635Z"
                  fill="#F72F77"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.5335 4.82638C11.5852 4.82638 10.7618 4.15055 10.5752 3.22055L10.3727 2.20722C10.3302 2.05305 10.1543 1.91888 9.95432 1.91888H6.42682C6.22682 1.91888 6.05099 2.05305 6.00016 2.24555L5.80599 3.22055C5.62016 4.15055 4.79599 4.82638 3.84766 4.82638C3.50266 4.82638 3.22266 4.54638 3.22266 4.20138C3.22266 3.85638 3.50266 3.57638 3.84766 3.57638C4.20266 3.57638 4.51099 3.32305 4.58099 2.97472L4.78349 1.96138C4.98932 1.18472 5.66182 0.668884 6.42682 0.668884H9.95432C10.7193 0.668884 11.3918 1.18472 11.5893 1.92388L11.801 2.97472C11.8702 3.32305 12.1785 3.57638 12.5335 3.57638C12.8785 3.57638 13.1585 3.85638 13.1585 4.20138C13.1585 4.54638 12.8785 4.82638 12.5335 4.82638Z"
                  fill="#F72F77"/>
        </SvgIcon>
    );
}
