import React from 'react'

import {SnackbarProvider, SnackbarProviderProps} from 'notistack'
import merge from 'lodash/merge'
import {useNotification} from './useNotification'

export type NotificationProviderProps = SnackbarProviderProps


const DEFAULT_CONFIG = {
    maxSnack: 3,
    hideIconVariant: false,
    autoHideDuration: 5000,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
}

const NotificationProvider: React.FC<NotificationProviderProps> = ({
                                                                       children,
                                                                       ...config
                                                                   }) => (
    <SnackbarProvider {...merge(DEFAULT_CONFIG, config)}>
        {children}
    </SnackbarProvider>
)

export {useNotification, NotificationProvider}
