import React, {useEffect, useState} from 'react'
import RbacService from "src/shared/services/RbacServices/RbacService";
import {useAuthContext} from "src/shared/providers";
import {PERMISSION_ROLES, UserRolesEnum} from "src/shared/services/RbacServices";

type Props = {
    children: React.ReactNode
}

export type RbacProviderContextType = null | RbacService

const Context = React.createContext<RbacProviderContextType>(null)

export const useRbacService = (): RbacProviderContextType => {
    return React.useContext(Context)
}

export function RbacServiceProvider({
                                        children
                                    }: Props) {
    const {
        token,
    } = useAuthContext()
    const [rbacService, setRbacService] =
        useState<RbacProviderContextType>(null)


    useEffect(() => {
        if (!token) return

        try {
            // const {
            //     user_roles: userRoles = [],
            //     organization_roles: organizationRoles = [],
            // } = jwt(token)


            setRbacService(new RbacService(PERMISSION_ROLES, [UserRolesEnum.ROLE_ADMIN]))
        } catch (e) {
        }
    }, [token])


    if (token && !rbacService) return null

    return <Context.Provider value={rbacService}>{children}</Context.Provider>
}
