import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {
    IPurchasedEventDetailsListItem,
    ITariffListItem,
    usePurchasedEventDetailsListInfinityQuery,
} from "src/shared/services/ApiService";
import {reduceInfinityResultData, useInfiniteScroll} from "src/shared/utils";
import {InfinityLoader, ListLoader, LoadingTable, Maybe} from "src/shared/ui";
import OrderList from "src/features/UserOrders/ui/List/OrderList";

export default function Orders(){
    const {
        data,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
        isError,
        isFetching
    } = usePurchasedEventDetailsListInfinityQuery({
        params: undefined,
        queryParams: {}
    }, {
        staleTime: 5 * 1000
    })

    // @ts-ignore
    const items: IPurchasedEventDetailsListItem[] = reduceInfinityResultData<ITariffListItem>(data)

    const [loaderRef] = useInfiniteScroll({
        loading: isFetchingNextPage,
        hasNextPage: !!hasNextPage,
        onLoadMore: fetchNextPage,
        disabled: isError,
        rootMargin: '0px 0px 100px 0px',
    });

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5" fontWeight={700}>
                    Мои заказы
                </Typography>
            </Box>

            <Box mt={2} mb={3}>
                <Typography>
                    Какой-то текст, поясняющий суть и возможность данного раздела, важно что информация здесь была
                    полезной и имела смысл как напоминание, иначе этот текст лучше убрать.
                </Typography>
            </Box>

            <Maybe
                when={!isFetching}
                fallback={<LoadingTable hideHead/>}
            >
                <OrderList
                    items={items}
                />

                <InfinityLoader
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                    ref={loaderRef}
                />
            </Maybe>

            <Maybe when={!isFetching && items.length === 0}>
                   <Box textAlign="center" mt={5}>
                       <Typography variant="h4">
                            Список заказов пуст
                       </Typography>
                   </Box>
            </Maybe>
        </Box>
    )
}