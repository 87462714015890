import {ColumnDef} from "@tanstack/react-table";
import {
    IPurchasedEventDetailsListItem,
    IServicePortfolio,
    IServicePortfolioUnallocated
} from "src/shared/services/ApiService";
import {Box, Button, Chip, IconButton, Skeleton, Typography} from "@mui/material";
import React from "react";
import {CARD_STYLES_BY_CODE} from "src/features/Tariffs/ui/Tariffs/TariffsList/contants";
import {FontFamily} from "src/shared/utils/font";
import {ArrowDownIcon, Maybe, PlusIcon} from "src/shared/ui";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";


export const STATE_MAP = {
    new: {
        label: 'Новый заказ',
        color: '#FF9500'
    },
    accepted: {
        label: 'Принято',
        color: '#34C759'
    },
    agreed: {
        label: 'Согласовано',
        color: '#34C759'
    },
    canceled: {
        label: 'Отменено',
        color: '#F72F77'
    },
    completed: {
        label: 'Завершено',
        color: '#34C759'
    }
}


export const getColumns = (): ColumnDef<IPurchasedEventDetailsListItem>[] => ([
    {
        header: 'Статус',
        accessorKey: 'state',
        size: 130,
        cell: (v) => {
            const {label, color} = STATE_MAP[v.row.original.state as 'new'] || {
                label: v.row.original.state,
                color: '#A6A6A6'
            }
            return (
                <Maybe when={!!v.row.original.state}>
                    <Chip
                        label={label}
                        size="small"
                        sx={{
                            backgroundColor: color,
                            color: 'white',
                            fontWeight: 600,
                            fontSize: '14px',
                            borderRadius: '4px',
                            height: '26px'
                        }}
                    />
                </Maybe>
            )
        }
    },
    {
        header: 'Номер заказа',
        accessorKey: 'number',
        size: 140,
        cell: (v) => {
            if (!v.row.original.number) {
                return (
                    <Typography fontWeight="500">
                        Не определен
                    </Typography>
                )
            }
            return (
                <Typography fontWeight="500">
                    №{v.row.original.number}
                </Typography>
            )
        }
    },
    {
        header: 'Дата заказа',
        accessorKey: 'eventDate',
        size: 200,
        cell: (v) => {
            return (
                <Typography fontWeight="500">
                    {(v.row.original?.eventDate && format(new Date(v.row.original?.eventDate), 'HH:mm dd.MM.yyyy', {
                        locale: ru
                    }) || 'Не указано')}
                </Typography>
            )
        }
    },
    {
        header: '',
        accessorKey: 'purchasedEventDetailsId',
        size: 300,
        // @ts-ignore
        cellSx: {
            padding: '8px'
        },
        // @ts-ignore
        sx: {
            marginLeft: 'auto'
        },
        cell: (v) => {
            return (
                <Box textAlign="right">
                    <Maybe when={v.row.original.state === 'new' && v.row.original.services.length !== 0}>
                        <Link to={v.row.original.id}>
                            <Button
                                disabled={v.row.original.services.length === 0}
                                endIcon={(
                                    <EditIcon/>
                                )}
                            >
                                Продолжить редактирование
                            </Button>
                        </Link>
                    </Maybe>
                    <Maybe when={v.row.original.state !== 'new'}>
                        <Button
                            onClick={v.row.getToggleExpandedHandler()}
                            disabled={v.row.original.services.length === 0}
                            endIcon={(
                                <ArrowDownIcon
                                    sx={{
                                        transform: v.row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(0deg)'
                                    }}
                                />
                            )}
                        >
                            Список позиций
                        </Button>
                    </Maybe>
                </Box>
            )
        }
    }
])