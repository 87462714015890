import React, {useContext} from 'react'
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {themeLight} from "./themes/ThemeLight";
import {GlobalStyles, PaletteMode} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';

type Props = {
    children: React.ReactNode
}

type ThemeProviderContextType = {
    toggleColorMode: () => void
    mode: PaletteMode
}

// @ts-ignore
const Context = React.createContext<ThemeProviderContextType>(null)

export const useThemePaletteMode = (): ThemeProviderContextType => useContext(Context)


const ThemeProvider: React.FC<Props> = ({
                                            children
                                        }: Props) => {
    const [mode, setMode] = React.useState<PaletteMode>('light');

    const colorMode = React.useMemo(
        () => ({
            // The dark mode switch would invoke this method
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) =>
                    prevMode === 'light' ? 'dark' : 'light',
                );
            },
            mode
        }),
        [mode],
    );

    const theme = themeLight

    return (
        <Context.Provider
            value={colorMode}
        >
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
{/*                <GlobalStyles*/}
{/*                    styles={{*/}
{/*                        body: {*/}
{/*                            backgroundColor: theme.palette.background.default,*/}
{/*                            margin: 0*/}
{/*                        },*/}
{/*                        '@font-face': [*/}
{/*                        //     {*/}
{/*                        //         'font-family': "'IBM Plex Mono'",*/}
{/*                        //         'src': `local("'IBM Plex Mono ExtraLight'"), local("'IBMPlexMono-ExtraLight'"),*/}
{/*                        // url('/assets/fonts/ibm-plex-mono/IBMPlexMono-ExtraLight.ttf') format('truetype')`,*/}
{/*                        //         'font-weight': 200,*/}
{/*                        //         'font-style': 'normal',*/}
{/*                        //         'font-display': 'swap'*/}
{/*                        //     }*/}
{/*                            `*/}
{/*                                {*/}
{/*    font-family: "'IBM Plex Mono'";*/}
{/*    src: local("'IBM Plex Mono ExtraLight'"), local("'IBMPlexMono-ExtraLight'"),*/}
{/*        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-ExtraLight.ttf') format('truetype');*/}
{/*    font-weight: 200;*/}
{/*    font-style: normal;*/}
{/*    font-display: swap;*/}
{/*}*/}
{/*                            `*/}
{/*                        ]*/}
{/*                    }}*/}
{/*                    // styles={`*/}
{/*                    //       body: {*/}
{/*                    //         backgroundColor: ${theme.palette.background.default};*/}
{/*                    //         margin: 0;*/}
{/*                    //     }*/}
{/*                    //*/}
{/*                    // `}*/}
{/*                />*/}
                {children}
            </MuiThemeProvider>
        </Context.Provider>
    )
}

export default ThemeProvider
