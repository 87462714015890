import {ITariffListItem, ListResponse} from "src/shared/services/ApiService"
import {generateInfiniteQuery, generateQuery} from "src/shared/services/ApiService/helpers";

export type UseTariffsQueryVariables = {
    serviceId?: string,
    page: number,
    perPage: number
}

export type UseTariffsQueryResponse = ListResponse<ITariffListItem>

export const [useTariffsQuery, getUseTariffsQueryKey] = generateQuery<undefined,
    UseTariffsQueryVariables,
    UseTariffsQueryResponse>(
    params => `tariffs`,
    'tariffs'
)
