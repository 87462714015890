import React from 'react'
import {Paper, Typography} from "@mui/material";
import {IService} from "src/shared/services/ApiService";

type Props = {
    data: IService
}

export default function ServiceCardHeader({
                                              data: {
                                                  group: {
                                                      name: groupName,
                                                      colorBackground: groupBgColor
                                                  },
                                                  name,
                                                  shortDescription,
                                              }
                                          }: Props) {
    return (
        <Paper
            elevation={0}
            sx={{
                backgroundColor: groupBgColor || '#fff',
                padding: '20px'
            }}
        >
            <Typography variant="caption" color="textSecondary">
                {groupName}
            </Typography>

            <Typography variant="h5" fontSize={28} fontWeight={700} sx={{
                margin: '8px 0 12px 0'
            }}>
                {name}
            </Typography>

            <Typography fontWeight={500}>
                {shortDescription}
            </Typography>
        </Paper>
    )
}
