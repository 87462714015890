import {IServicePortfolioUnallocated} from "src/shared/services/ApiService";
import {
    getColumnsLoading,
    getColumnsUnallocated
} from "src/features/UserPortfolio/ui/Portfolio/PortfolioProject/columns";
import {KnobExpandableTable} from "src/shared/ui/KnobTable";
import {range} from "lodash";
import {Box} from "@mui/material";

type Props = {
    hideHead?: boolean
}

export function LoadingTable({
                                 hideHead,
                             }: Props) {
    return (
        <Box>
            <KnobExpandableTable<{ i: number }>
                hideHead={hideHead}
                data={range(4).map((i) => ({i: i}))}
                columns={getColumnsLoading()}
                getRowCanExpand={() => false}
            />
        </Box>
    )
}
