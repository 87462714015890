import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import {useRbacService} from "src/shared/providers";


export type PropsType = {
    permission: string
}

export const RbacGuardLayout = ({ permission }: PropsType) => {
    const rbacService = useRbacService()

    if (!rbacService?.checkPermission(permission)) {
        return <Navigate to="/403" />
    }

    return <Outlet />
}
