import {generateMutation} from "src/shared/services/ApiService/helpers";

export type UseUserLoginMutationVariables = {
    email: string
    password: string
}

export type UseUserLoginMutationResponse = {
    token: string
}


export const useUserLoginMutation = generateMutation<
    undefined,
    UseUserLoginMutationVariables,
    UseUserLoginMutationResponse
    >(
    'post',
    params => 'users/login'
)
