import {
    Box,
    Button,
    Chip,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {
    IOrganizationProjectListItem,
} from "src/shared/services/ApiService";
import {DeleteIcon, MoreIcon, SuccessModal} from "src/shared/ui";
import {FontFamily} from "src/shared/utils/font";
import usePopoverAnchor from "src/shared/utils/hooks/usePopoverAnchor";
import {ModalWrapper, useModal} from "src/shared/providers";
import {ProjectCreateModal} from "src/features/UserProjects/ui/ProjectCreateModal";
import React from "react";
import {Link} from "react-router-dom";

type Props = {
    data: IOrganizationProjectListItem
}

export function ProjectListItem({
                                    data
                                }: Props) {
    const {
        openModal,
        closeModal,
    } = useModal()
    const {
        id,
        open,
        anchorEl,
        handleOpen,
        handleClose,
    } = usePopoverAnchor("ProjectMenu")


    const handleUpdateProject = async () => {
        const key = 'manager-modal'

        await (new Promise((resolve, reject) =>
            openModal({
                key,
                component: (
                    <ModalWrapper
                        dialogTitle="Редактирование пакета лицензий"
                        onClose={() => {
                            closeModal(key)
                            reject()
                        }}
                    >
                        <ProjectCreateModal
                            id={data.id}
                            initialFormValues={{
                                name: data.name || '',
                                description: data.description || '',
                                shortDescription: data.shortDescription || '',
                                url: data.url || '',
                            }}
                            onSuccess={() => {
                                closeModal(key)
                                resolve(null)
                            }}/>
                    </ModalWrapper>
                )
            })
        ))


        const keySuccessModal = 'success-modal'

        openModal({
            key: keySuccessModal,
            component: (
                <ModalWrapper
                    dialogTitle="Пакет лицензий успешно создан!"
                    onClose={closeModal.bind(null, keySuccessModal)}
                >
                    <SuccessModal
                        text="Теперь к данному пакету лицензий можно привязать купленные услуги!"
                    />
                </ModalWrapper>
            )
        })
    }

    return (
        <Paper
            elevation={1}
            sx={{
                border: '1px solid #DEDEDE',
                boxShadow: '0px 12px 24px -10px rgba(0, 0, 0, 0.15)'
            }}
        >
            <Box display="flex" flexDirection="row">
                <Box
                    p={3.5}
                    flexGrow="1"
                >
                    <Chip
                        label={`ID: ${data.id}`}
                        sx={{
                            color: '#0048D3',
                            backgroundColor: '#FAF9F7',
                            fontWeight: '600',
                            borderRadius: '4px'
                        }}
                    />

                    <Typography
                        sx={{
                            fontFamily: FontFamily.ibmMono,
                            fontSize: '24px',
                            fontWeight: 600,
                            margin: '12px 0',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            '-webkit-line-clamp': 1,
                            '-webkit-box-orient': 'vertical',
                        }}
                    >
                        {data.name}
                    </Typography>

                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            '-webkit-line-clamp': '2',
                            '-webkit-box-orient': 'vertical',
                            minHeight: '48px',
                        }}
                    >
                        {data.description}
                    </Typography>
                </Box>

                <Box
                    p={1}
                    sx={{
                        backgroundColor: '#FAF9F7',
                        display: 'flex',
                        alignItems: 'center',
                        borderTopRightRadius: '12px',
                        borderBottomRightRadius: '12px',
                    }}>
                    <Box>
                        <IconButton
                            onClick={handleOpen}
                            aria-controls={open ? 'project-settings-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <MoreIcon/>
                        </IconButton>
                    </Box>
                </Box>
            </Box>

            <Menu
                id="project-settings-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'menu-button',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        minWidth: '300px',
                        border: '1px solid #F1EEE9',
                        boxShadow: '4px 7px 30px -5px rgba(83, 72, 52, 0.1)',
                    },
                    '& .MuiMenuItem-root': {
                        fontWeight: '600',
                        fontSize: '14px',
                        padding: '13px 16px'
                    }
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleUpdateProject()
                        handleClose()
                    }}
                >Редактировать
                </MenuItem>

                <Link
                    to={`/user/portfolio?id=${data?.id}`}
                    style={{
                        color: 'inherit',
                        textDecoration: 'none'
                    }}
                >
                    <MenuItem onClick={handleClose}>Смотреть привязанные услуги</MenuItem>
                </Link>
                {/*<MenuItem onClick={handleClose}>*/}
                {/*    <Box*/}
                {/*        display="flex"*/}
                {/*        justifyContent="space-between"*/}
                {/*        alignItems="center"*/}
                {/*        width="100%"*/}
                {/*        sx={{*/}
                {/*            color: '#F72F77'*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <Box>Удалить</Box>*/}
                {/*        <Box>*/}
                {/*            <DeleteIcon fontSize="small"/>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*</MenuItem>*/}
            </Menu>
        </Paper>
    )
}
