import React, {useCallback} from "react";
import {Box, Grid, Grow} from "@mui/material";
import {MOCK_TARIFFS} from "./mock";
import {ITariffListItem} from "src/shared/services/ApiService";
import {EntityListItem} from "src/shared/ui";
import {CARD_STYLES_BY_CODE} from "src/features/Tariffs/ui/Tariffs/TariffsList/contants";
import {useNavigate} from "react-router-dom";
import {TransitionGroup} from 'react-transition-group'
import {getLimitedEnterTimeout} from "src/shared/utils";


type Props = {
    items: ITariffListItem[]
}


export function List({
                         items
                     }: Props) {
    const navigate = useNavigate()

    const makeHandleClick = useCallback((id: string | number) => {
        return () => {
            navigate(id.toString())
        }
    }, [])

    return (
        <TransitionGroup>
            <Grid container spacing={3}>
                {items.map(({id, name, shortDescription, code}, i) => (
                    <Grid
                        key={id}
                        item
                        xs={3}
                    >
                        <Grow
                            in
                            exit={false}
                            appear
                            timeout={{
                                enter: getLimitedEnterTimeout(300 + i * 100),
                            }}
                        >
                            <Box>
                                <EntityListItem
                                    onClick={makeHandleClick(id)}
                                    height={254}
                                    title={name}
                                    description={shortDescription}
                                    descriptionLineClamp={3}
                                    caption={""}
                                    bgColor={CARD_STYLES_BY_CODE[code as 'T0']?.bgColor || '#fff'}
                                    tags={[
                                        {
                                            name: code,
                                            color: CARD_STYLES_BY_CODE[code as 'T0']?.tagColor || '#fff'
                                        }
                                    ]}
                                />
                            </Box>
                        </Grow>
                        {/*<ListItem*/}
                        {/*    title={name}*/}
                        {/*    description={'В разработке'}*/}
                        {/*    category={'a1'}*/}
                        {/*    categoryTitle={"В разработке"}*/}
                        {/*    tags={[]}*/}
                        {/*/>*/}
                    </Grid>
                ))}
            </Grid>
        </TransitionGroup>
    )
}
