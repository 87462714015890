import React from 'react'
import {List} from "./List";
import {ITariffListItem} from "src/shared/services/ApiService";


type Props = {
    items: ITariffListItem[]
}

export default function TariffsList({
                                        items
                                    }: Props) {
    return (
        <List
            items={items}
        />
    )
}
