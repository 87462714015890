import {generateQuery} from "src/shared/services/ApiService/helpers";
import {EntityResponse, ListResponse} from "src/shared/services/ApiService";
import {IServiceGroup} from "src/shared/services/ApiService/interfaces/IServiceGroup";

export type UseServiceParamsQueryVariables = {}

export type UseServiceParamsQueryResponse = EntityResponse<Record<string, string>>

export const [useServiceParamsQuery, getUseServiceParamsQueryKey] = generateQuery<undefined,
    UseServiceParamsQueryVariables,
    UseServiceParamsQueryResponse>(
    params => `services/bool-filters`,
    'ServiceParams'
)
