import {Box, Paper, Typography} from "@mui/material";
import {FontFamily} from "src/shared/utils/font";
import React from "react";

type Props = {
    active?: boolean,
    id: string,
    onClick?: (id: string) => void
    name: string | React.ReactNode,
    description: string | React.ReactNode
}

export function PortfolioProjectListItem({
                                             id,
                                             onClick,
                                             name,
                                             description,
                                             active,
                                         }: Props) {
    return (
        <Paper
            onClick={() => onClick?.(id)}
            elevation={1}
            sx={{
                cursor: 'pointer',
                border: active ? '1px solid #0048D3' : '1px solid #F1F1F1',
                boxShadow: '0px 12px 24px -10px rgba(0, 0, 0, 0.15)'
            }}
        >
            <Box p={3}>
                <Typography fontFamily={FontFamily.ibmMono} sx={{
                    fontSize: '18px',
                    fontWeight: '700',
                    marginBottom: '12px',
                    color: active ? '#0048D3' : '#2C2C2C'
                }}>
                    {name}
                </Typography>

                <Typography fontFamily={FontFamily.ibmMono} sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: active ? '#0048D3' : '#2C2C2C'
                }}>
                    {description}
                </Typography>
            </Box>
        </Paper>
    )
}
