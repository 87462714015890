import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function ProfileIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="40" height="30" viewBox="0 0 40 30" fill="none"
            {...props}
            style={{
                fill: 'none'
            }}
        >
            <path
                d="M7.39969 0.719971C5.26031 0.719971 3.47859 2.34747 3.22594 4.42122C1.73297 5.06763 0.679688 6.55731 0.679688 8.27997V25.08C0.679688 27.39 2.56969 29.28 4.87969 29.28H14.4347C16.1016 29.28 17.5978 28.2825 18.2672 26.76H21.7322C22.4016 28.2825 23.8978 29.28 25.5647 29.28H35.1197C37.4297 29.28 39.3197 27.39 39.3197 25.08V8.27997C39.3197 6.55731 38.2664 5.06763 36.7734 4.42122C36.5208 2.34747 34.7391 0.719971 32.5997 0.719971H7.39969ZM7.39969 2.39997H32.5997C33.7087 2.39997 34.6472 3.0956 34.9884 4.07997H5.01094C5.35219 3.0956 6.29063 2.39997 7.39969 2.39997ZM4.87969 5.75997H35.1197C36.5208 5.75997 37.6397 6.87888 37.6397 8.27997V25.08C37.6397 26.4811 36.5208 27.6 35.1197 27.6H25.5647C24.5541 27.6 23.6517 27.0061 23.2547 26.0775L21.9422 23.0062C21.6108 22.2318 20.843 21.72 19.9997 21.72C19.1564 21.72 18.3886 22.2318 18.0572 23.0062L16.7447 26.0775C16.3477 27.0061 15.4453 27.6 14.4347 27.6H4.87969C3.47859 27.6 2.35969 26.4811 2.35969 25.08V8.27997C2.35969 6.87888 3.47859 5.75997 4.87969 5.75997ZM11.1797 11.64C8.17406 11.64 5.71969 14.0943 5.71969 17.1C5.71969 20.1056 8.17406 22.56 11.1797 22.56C14.1853 22.56 16.6397 20.1056 16.6397 17.1C16.6397 14.0943 14.1853 11.64 11.1797 11.64ZM28.8197 11.64C25.8141 11.64 23.3597 14.0943 23.3597 17.1C23.3597 20.1056 25.8141 22.56 28.8197 22.56C31.8253 22.56 34.2797 20.1056 34.2797 17.1C34.2797 14.0943 31.8253 11.64 28.8197 11.64ZM11.1797 13.32C13.2764 13.32 14.9597 15.0033 14.9597 17.1C14.9597 19.1967 13.2764 20.88 11.1797 20.88C9.08297 20.88 7.39969 19.1967 7.39969 17.1C7.39969 15.0033 9.08297 13.32 11.1797 13.32ZM28.8197 13.32C30.9164 13.32 32.5997 15.0033 32.5997 17.1C32.5997 19.1967 30.9164 20.88 28.8197 20.88C26.723 20.88 25.0397 19.1967 25.0397 17.1C25.0397 15.0033 26.723 13.32 28.8197 13.32ZM19.9997 23.4C20.1769 23.4 20.3245 23.5017 20.3934 23.6625L20.9972 25.08H19.0022L19.6059 23.6625C19.6748 23.5017 19.8225 23.4 19.9997 23.4Z"
                fill="black"/>
        </SvgIcon>
    );
}
