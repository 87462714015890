export * from './hooks'

export const reduceInfinityResultData = <TData>(
    data: unknown
    // @ts-ignore
):  TData[] => data?.pages?.reduce((acc: TData[], {data}: { data: TData[] }) => [...acc, ...data], []) || []

export const getLimitedEnterTimeout = (enterTimeout: number) =>
    enterTimeout < 1500
        ? enterTimeout
        : 1500
