import {generateMutation} from "src/shared/services/ApiService/helpers";


export type UsePurchasedEventDetailsOrderMutationVariables = {
    tariff: string
    service: string,
}

export type UsePurchasedEventDetailsOrderMutationResponse = {}


export const usePurchasedEventDetailsOrderMutation = generateMutation<undefined,
    UsePurchasedEventDetailsOrderMutationVariables,
    UsePurchasedEventDetailsOrderMutationResponse>(
    'post',
    params => `purchased-event-details/order`
)
