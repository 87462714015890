import {generateInfiniteQuery, generateQuery} from "src/shared/services/ApiService/helpers";
import {
    IPurchasedEventDetailsListItem,
    ListResponse,
    UseServicesInfinityQueryResponse,
    UseServicesInfinityQueryVariables
} from "src/shared/services/ApiService";
import {IServiceGroup} from "src/shared/services/ApiService/interfaces/IServiceGroup";

export type UsePurchasedEventDetailsListQueryVariables = {}

export type UsePurchasedEventDetailsListQueryResponse = ListResponse<IPurchasedEventDetailsListItem>

export const [usePurchasedEventDetailsListQuery, getUsePurchasedEventDetailsListQueryKey] = generateQuery<undefined,
    UsePurchasedEventDetailsListQueryVariables,
    UsePurchasedEventDetailsListQueryResponse>(
    params => `purchased-event-details`,
    'PurchasedEventDetailsList'
)

export const [usePurchasedEventDetailsListInfinityQuery, getUsePurchasedEventDetailsListInfinityQueryKey] = generateInfiniteQuery<undefined,
    UseServicesInfinityQueryVariables,
    UseServicesInfinityQueryResponse>(
    params => `purchased-event-details`,
    'PurchasedEventDetailsList-infinity'
)
