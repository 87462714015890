import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function SearchIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="21" height="21" viewBox="0 0 21 21" fill="none"
            {...props}
            style={{
                fill: 'none'
            }}
        >
            <circle cx="9.76639" cy="9.76657" r="8.98856" stroke="#2C2C2C" strokeWidth="1.5" strokeLinecap="round"
                    strokeLinejoin="round"/>
            <path d="M16.0181 16.4851L19.5421 20" stroke="#2C2C2C" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </SvgIcon>
    );
}
