import React from 'react'
import {Dialog, DialogProps, DialogTitle, IconButton, DialogContent, DialogActions} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {Breakpoint} from "@mui/system";
import {Maybe} from 'src/shared/ui';

export type ModalWrapperProps = Partial<DialogProps> & {
    actions?: React.ReactNode | React.ReactNode[]
    onClose: () => void
    dialogTitle?: string,
    maxWidth?: Breakpoint
}

interface DialogTitleProps {
    children?: React.ReactNode;
    onClose: () => void;
}

const DialogTitleWrapper = (props: DialogTitleProps) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{
            m: 0,
            p: 2,
            '&& .MuiPaper-root': {
                borderRadius: '16px'
            }
        }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const ModalWrapper: React.FC<ModalWrapperProps> = ({
                                                       open = true,
                                                       onClose,
                                                       dialogTitle,
                                                       children,
                                                       actions,
                                                       maxWidth = 'sm'
                                                   }) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={maxWidth}
            fullWidth
        >
            <Maybe when={!!dialogTitle}>
                <DialogTitleWrapper
                    onClose={onClose}
                    // @ts-ignore
                    sx={{
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: 700,
                        '& .MuiButtonBase-root': {
                            color: '#2C2C2C'
                        }
                    }}>
                    {dialogTitle}
                </DialogTitleWrapper>
            </Maybe>
            <Maybe when={!!children}>
                <DialogContent dividers>
                    {children}
                </DialogContent>
            </Maybe>
            <Maybe when={!!actions}>
                <DialogActions sx={{
                    margin: 2
                }}>
                    {actions}
                </DialogActions>
            </Maybe>
        </Dialog>
    )
}

export default ModalWrapper
