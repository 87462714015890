import {IPurchasedEventDetailsListItem, IServicePortfolio} from "src/shared/services/ApiService";
import React from "react";
import {KnobExpandableTable} from "src/shared/ui/KnobTable";
import {getColumns} from "./columns";
import {Box, Button, Divider, Stack, Table, TableHead, TableRow, Typography, TableCell, TableBody} from "@mui/material";
import {STATE_MAP} from "src/features/UserPortfolio/ui/Portfolio/PortfolioProject/constants";
import {EventRow} from "src/features/UserPortfolio/ui/Portfolio/PortfolioProject/PortfolioProjectServices";

type Props = {
    items: IPurchasedEventDetailsListItem[]
}

export default function OrderList({
                                      items
                                  }: Props) {
    return (
        <KnobExpandableTable<IPurchasedEventDetailsListItem>
            data={items || []}
            columns={getColumns()}
            getRowCanExpand={() => true}
            renderSubComponent={(data) => (
                <Box
                    sx={{
                        backgroundColor: 'background.paper',
                        borderBottomLeftRadius: '12px',
                        borderBottomRightRadius: '12px'
                    }}
                    py={2}
                >
                    <Table>
                        <TableHead>
                            <TableRow
                                sx={{
                                    '.MuiTableCell-root': {
                                        padding: '6px 20px',
                                        fontWeight: '600'
                                    }
                                }}
                            >
                                <TableCell>Позиция</TableCell>
                                <TableCell align="right">Количество</TableCell>
                                <TableCell align="right">Единица измерения</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.row.original.services.map((service) => (
                                <TableRow
                                    key={service.abbreviation + service.count}
                                    sx={{
                                        '&:nth-child(even)': {
                                            backgroundColor: '#FAF9F7',
                                        },
                                        '.MuiTableCell-root': {
                                            padding: '6px 20px',
                                            border: 'none'
                                        }
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {service.abbreviation}
                                    </TableCell>
                                    <TableCell align="right">{service.count}</TableCell>
                                    <TableCell align="right">шт.</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            )}
        />
    )
}