import React, {ReactNode} from 'react'

type Props = {
    when: boolean
    children: React.ReactNode,
    fallback?: ReactNode
}

const Maybe: React.FC<Props> = ({
                                    when,
                                    children,
                                    fallback = null,
                                }) => {
    const jsx = when ? children : fallback

    if(!jsx) return null

    return <>{jsx}</>
}


export default Maybe
