import {Button, ButtonGroup, IconButton, OutlinedInputProps, TextField} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {useState} from "react";
import {isInteger} from "lodash";
import {FormikHandlers} from "formik";

const COUNTER_LEFT_BTN = 'left'
const COUNTER_RIGHT_BTN = 'right'

const directionsToValueMap = {
    [COUNTER_LEFT_BTN]: -1,
    [COUNTER_RIGHT_BTN]: 1,
}

type Props = {
    name: string,
    value: number,
    onChange: FormikHandlers["handleChange"]
}

export function CounterField({
                                 name,
                                 value: formValue,
                                 onChange,
                             }: Props) {
    const [value, setValue] = useState(formValue)

    const handleChange = (e: any) => {
        setValue(e.target.value)
        onChange(e)
    }


    const handleCounterButton = (type: 'left' | 'right') => {
        if (type === COUNTER_LEFT_BTN && value <= 0) return
        const stepDirection = directionsToValueMap[type]

        handleChange({
            target: {
                name,
                value: stepDirection + value,
            },
        })
    }

    const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\s/g, '')

        if (!isInteger(+value)) return
        handleChange({
            target: {
                name,
                value: value === '' ? 0 : +value,
            },
        })
    }

    const handleBlurQuantity = () => {
        if (value === undefined) {
            handleChange({
                target: {
                    name,
                    value: 1,
                },
            })
        }
    }

    return (
        <ButtonGroup>
            <IconButton
                onClick={() => handleCounterButton(COUNTER_LEFT_BTN)}
                size="small"
            >
                <RemoveIcon htmlColor="#130F26"/>
            </IconButton>

            <TextField
                size="small"
                sx={{
                    '& .MuiInputBase-root': {
                        borderRadius: '4px',
                        width: '60px'
                    },
                    '& .MuiInputBase-input': {
                        padding: '4px 12px',
                        textAlign: 'center',
                        fontWeight: 600
                    }
                }}
                InputProps={{disableUnderline: true} as Partial<OutlinedInputProps>}
                variant="filled"
                value={value}
                onChange={handleChangeQuantity}
                onBlur={handleBlurQuantity}
            />
            <IconButton
                onClick={() => handleCounterButton(COUNTER_RIGHT_BTN)}
                size="small"
            >
                <AddIcon htmlColor="#130F26"/>
            </IconButton>
        </ButtonGroup>
    )
}
