import {Box, Button, Divider, OutlinedInputProps, Stack, TextField, Typography} from "@mui/material";
import {useAppealsCreateMutation} from "src/shared/services/ApiService";
import {useFormik} from "formik";
import {IMaskInput} from 'react-imask';
import React, {useState} from "react";
import {alpha, styled} from "@mui/material/styles";
import {TextFieldProps} from "@mui/material/TextField";
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import {useNotification} from "src/shared/providers";


type FormValues = {
    text: string,
    email: string,
    phone: string,
}

interface InputProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const PhoneMask = React.forwardRef<HTMLElement, InputProps>(
    function TextMaskCustom(props, ref) {
        const {onChange, ...other} = props;

        return (
            <IMaskInput
                {...other}
                mask="+{7}(000)000-00-00"
                lazy={false}
                // @ts-ignore
                inputRef={ref}
                onAccept={(value: any) => onChange({target: {name: props.name, value}})}
            />
        );
    },
);


export const KnobTextField = styled((props: TextFieldProps) => (
    <TextField
        InputProps={{disableUnderline: true} as Partial<OutlinedInputProps>}
        {...props}
    />
))(({theme}) => ({
    '& .MuiFilledInput-root': {
        fontWeight: '500',
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const phoneRegexp = new RegExp('\\+7\\(\\d{3}\\)\\d{3}-\\d{2}-\\d{2}')
const validationSchema = Yup.object().shape({
    text: Yup.string().required('Необходимо указать коментарий!'),
    email: Yup.string().required('Необходимо указать почту!'),
    phone: Yup.string().nullable()
})

type Props = {
    onSuccess: () => void,
}

export function ManagerModal({
                                 onSuccess,
                             }: Props) {
    const {showErrorNotification,} = useNotification()
    const {mutateAsync: createAppeals} = useAppealsCreateMutation({
        onSuccess: () => onSuccess(),
    })
    const {
        values,
        handleBlur,
        handleSubmit,
        handleChange,
        errors,
        isValid,
        isSubmitting,
    } = useFormik<FormValues>({
        initialValues: {
            text: '',
            email: '',
            phone: ''
        },
        validationSchema,
        onSubmit: async (v) => {
            try {
                await createAppeals({
                    body: {
                        ...v,
                        phone: phoneRegexp.test(v.phone) ? v.phone : undefined
                    }
                })
            } catch {
                showErrorNotification({message: 'Не удалось создать обращение!'})
            }
        }
    })


    return (
        <Box>
            <Typography sx={{
                marginBottom: '16px',
                fontSize: '14px'
            }}>
                Оставьте в заявке почту и/или телефон, и наш менеджер свяжеться с вами в течение часа, чтобы помочь в
                вашем вопросе.
            </Typography>

            <Box
                component="form"
                onSubmit={(e: any) => {
                    e.preventDefault()
                    handleSubmit()
                }}
            >
                <Stack spacing={2}>
                    <KnobTextField
                        onBlur={handleBlur}
                        name="email"
                        value={values?.email}
                        onChange={handleChange}
                        fullWidth
                        variant="filled"
                        label="Почта"
                        sx={{
                            '& .MuiInputBase-root': {
                                borderRadius: '8px'
                            }
                        }}
                    />

                    <KnobTextField
                        onBlur={handleBlur}
                        name="phone"
                        value={values?.phone}
                        onChange={handleChange}
                        fullWidth
                        label="Телефон"
                        variant="filled"
                        sx={{
                            '& .MuiInputBase-root': {
                                borderRadius: '8px'
                            }
                        }}
                        InputProps={{
                            inputComponent: PhoneMask as any,
                            disableUnderline: true,
                        }}
                    />

                    <KnobTextField
                        onBlur={handleBlur}
                        name="text"
                        value={values?.text}
                        onChange={handleChange}
                        fullWidth
                        label="Комментарий"
                        variant="filled"
                        sx={{
                            '& .MuiInputBase-root': {
                                borderRadius: '8px'
                            }
                        }}
                    />
                </Stack>
            </Box>

            <Typography sx={{
                marginTop: '16px'
            }}>
                Нажимая “Отправить”, вы соглашаетесь с
                <span style={{
                    color: '#0048D3',
                    fontWeight: '500',
                    cursor: 'pointer'
                }}>{' '}Политикой концедицальности</span> и
                <span style={{
                    color: '#0048D3',
                    fontWeight: '500',
                    cursor: 'pointer'
                }}>{' '}Условиями использования.</span>
            </Typography>

            {Object.values(errors).map((text) => (
                <Typography
                    component="p"
                    variant="caption"
                    color="#F72F77"
                    sx={{
                        margin: '8px 0'
                    }}
                >
                    {text}
                </Typography>
            ))}

            <LoadingButton
                loading={isSubmitting}
                onClick={() => handleSubmit()}
                disabled={!isValid}
                size="large"
                fullWidth
                variant="contained"
                sx={{
                    margin: '16px 0'
                }}
            >
                Отправить
            </LoadingButton>
        </Box>
    )
}
