import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function ShareIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="18" height="18" viewBox="0 0 18 18" fill="none"
            {...props}
        >
            <mask id="mask0_792_1163" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="6" width="18"
                  height="12">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.666992 6.32843H17.3333V17.7818H0.666992V6.32843Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask0_792_1163)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.6378 17.7818H4.36283C2.32533 17.7818 0.666992 16.1243 0.666992 14.0859V10.0234C0.666992 7.98593 2.32533 6.32843 4.36283 6.32843H5.14033C5.48533 6.32843 5.76533 6.60843 5.76533 6.95343C5.76533 7.29843 5.48533 7.57843 5.14033 7.57843H4.36283C3.01366 7.57843 1.91699 8.6751 1.91699 10.0234V14.0859C1.91699 15.4351 3.01366 16.5318 4.36283 16.5318H13.6378C14.9862 16.5318 16.0837 15.4351 16.0837 14.0859V10.0159C16.0837 8.67176 14.9903 7.57843 13.647 7.57843H12.8612C12.5162 7.57843 12.2362 7.29843 12.2362 6.95343C12.2362 6.60843 12.5162 6.32843 12.8612 6.32843H13.647C15.6795 6.32843 17.3337 7.9826 17.3337 10.0159V14.0859C17.3337 16.1243 15.6753 17.7818 13.6378 17.7818Z"
                      fill="#2C2C2C"/>
            </g>
            <mask id="mask1_792_1163" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="8" y="0" width="2"
                  height="12">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.375 0.666748H9.625V11.9508H8.375V0.666748Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask1_792_1163)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M9 11.9509C8.655 11.9509 8.375 11.6709 8.375 11.3259V1.29169C8.375 0.946687 8.655 0.666687 9 0.666687C9.345 0.666687 9.625 0.946687 9.625 1.29169V11.3259C9.625 11.6709 9.345 11.9509 9 11.9509Z"
                      fill="#2C2C2C"/>
            </g>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.57108 4.35727C6.41192 4.35727 6.25192 4.29644 6.13025 4.17477C5.88608 3.93144 5.88442 3.53644 6.12858 3.29144L8.55775 0.851436C8.79192 0.615602 9.20858 0.615602 9.44275 0.851436L11.8727 3.29144C12.1161 3.53644 12.1152 3.93144 11.8711 4.17477C11.6261 4.4181 11.2311 4.4181 10.9877 4.1731L9.00025 2.1781L7.01358 4.1731C6.89192 4.29644 6.73108 4.35727 6.57108 4.35727Z"
                  fill="#2C2C2C"/>
        </SvgIcon>
    );
}
