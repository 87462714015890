import {generateMutation} from "src/shared/services/ApiService/helpers";


export type UseUserRegisterMutationVariables = {
    email: string
    password: string
}

export type UseUserRegisterMutationResponse = {
    token: string
}


export const useUserRegisterMutation = generateMutation<
    undefined,
    UseUserRegisterMutationVariables,
    UseUserRegisterMutationResponse
    >(
    'post',
    params => 'users/register'
)
