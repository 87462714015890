import {KnobExpandableTable} from "src/shared/ui/KnobTable";
import {range} from "lodash";
import {Box, Skeleton} from "@mui/material";
import {ColumnDef} from "@tanstack/react-table";
import React from "react";

type Props = {
    hideHead?: boolean
}

export const getColumnsLoading = (): ColumnDef<{ i: number }>[] => ([
    {
        header: 'Тариф',
        maxSize: 60,
        minSize: 60,
        size: 60,
        cell: (v) => (
            <Box>
                <Skeleton/>
            </Box>
        )
    },
    {
        header: 'Название',
        size: 400,
        cell: (v) => (
            <Box>
                <Skeleton/>
            </Box>
        )
    },
    {
        header: 'Сканирований',
        size: 40,
        cell: (v) => (
            <Box>
                <Skeleton/>
            </Box>
        )
    },
    {
        header: 'Статус',
        size: 200,
        cell: (v) => {
            return (
                <Box>
                    <Skeleton/>
                </Box>
            )
        }
    },
    {
        header: '',
        accessorKey: 'purchasedEventDetailsId',
        size: 40,
        cell: (v) => {
            return (
                <Box>
                    <Skeleton/>
                </Box>
            )
        }
    }
])

export function LoadingTable({
                                 hideHead,
                             }: Props) {
    return (
        <Box>
            <KnobExpandableTable<{ i: number }>
                hideHead={hideHead}
                data={range(4).map((i) => ({i: i}))}
                columns={getColumnsLoading()}
                getRowCanExpand={() => false}
            />
        </Box>
    )
}
