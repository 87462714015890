import {EntityResponse, ITariff} from "src/shared/services/ApiService"
import {generateQuery} from "src/shared/services/ApiService/helpers";

export type UseTariffQueryVariables = {}

export type UseTariffQueryResponse = EntityResponse<ITariff>

export const [useTariffQuery, getUseTariffQueryKey] = generateQuery<{
    tariffId: string
},
    UseTariffQueryVariables,
    UseTariffQueryResponse>(
    params => `tariffs/${params.tariffId}`,
    'tariff'
)
