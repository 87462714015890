import {useState} from "react";
import {default as LoadingButtonMui, LoadingButtonProps} from '@mui/lab/LoadingButton';

type Props = LoadingButtonProps

export function LoadingButton(props: Props) {
    const [isLoading, setIsLoading] = useState(false)
    const isButtonLoading = isLoading || props.loading
    const isDisabled = isButtonLoading || props.disabled

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isButtonLoading) return
        try {
            setIsLoading(true)
            await Promise.resolve(props.onClick?.(event))
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <LoadingButtonMui
            {...props}
            onClick={handleClick}
            disabled={isDisabled}
            loading={isButtonLoading}
        />
    )
}
