import {Box, Divider} from "@mui/material";
import {PortfolioProjectServicesUnallocated} from "./PortfolioProjectServicesUnallocated";
import {
    PortfolioProjectServices
} from "src/features/UserPortfolio/ui/Portfolio/PortfolioProject/PortfolioProjectServices";
import {
    PortfolioProjectFilters
} from "src/features/UserPortfolio/ui/Portfolio/PortfolioProject/PortfolioProjectFilters";
import {useFormikWithQueryString} from "src/shared/utils/hooks/useFormikWithQueryString";
import {useEffect, useMemo} from "react";
import {useFormik} from "formik";
import {useQueryParams} from "src/shared/utils/hooks/useQueryParams";

export type PortfolioProjectFiltersFormValues = {
    sort?: string | null
}

export function PortfolioProject() {
    const [params] = useQueryParams()

    const {
        values,
        handleChange,
    } = useFormik<PortfolioProjectFiltersFormValues>({
        initialValues: {
            sort: "none",
        },
        onSubmit: (v) => {
            console.log(v)
        }
    })

    // useEffect(() => {
    //     handleSubmit()
    // }, [values.sort]);

    const sort = useMemo(() => {
        if(values.sort === 'none') return undefined

        const splitted = values?.sort?.split(':')

        if(splitted?.length !== 2) return undefined

        return  {
            name: splitted[0],
            type: splitted[1]
        }

    }, [values.sort]);


    return (
        <Box>
            {/*<Box sx={{*/}
            {/*    marginBottom: '20px'*/}
            {/*}}>*/}
            {/*    <PortfolioProjectFilters values={values} onChange={handleChange}/>*/}
            {/*</Box>*/}
            <Box
                p={2}
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px'
                }}
            >
                <PortfolioProjectServices
                    // @ts-ignore
                    key={params.id}
                    // @ts-ignore
                    sort={sort}
                />
                <Divider
                    sx={{
                        marginTop: '20px',
                        marginBottom: '-36px',
                        '& .MuiDivider-wrapper': {
                            fontWeight: '600',
                            fontSize: '14px',
                            color: '#747474'
                        }
                    }}
                >
                    Нераспределенные услуги
                </Divider>
                <PortfolioProjectServicesUnallocated
                    // @ts-ignore
                    key={`${params.id}_Unallocated`}
                />
            </Box>
        </Box>

    )
}
