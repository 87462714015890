import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function LogoutIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="18" height="18" viewBox="0 0 18 18" fill="none"
            {...props}
        >
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.43283 17.3392H4.36116C2.32449 17.3392 0.666992 15.6817 0.666992 13.6433V4.36915C0.666992 2.33082 2.32449 0.672485 4.36116 0.672485H8.42366C10.462 0.672485 12.1203 2.33082 12.1203 4.36915V5.14582C12.1203 5.49082 11.8403 5.77082 11.4953 5.77082C11.1503 5.77082 10.8703 5.49082 10.8703 5.14582V4.36915C10.8703 3.01915 9.77283 1.92249 8.42366 1.92249H4.36116C3.01366 1.92249 1.91699 3.01915 1.91699 4.36915V13.6433C1.91699 14.9925 3.01366 16.0892 4.36116 16.0892H8.43283C9.77616 16.0892 10.8703 14.9958 10.8703 13.6525V12.8667C10.8703 12.5217 11.1503 12.2417 11.4953 12.2417C11.8403 12.2417 12.1203 12.5217 12.1203 12.8667V13.6525C12.1203 15.6858 10.4653 17.3392 8.43283 17.3392Z"
                  fill="#2C2C2C"/>
            <mask id="mask0_792_1956" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="6" y="8" width="12"
                  height="2">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.49683 8.38086H17.7809V9.63086H6.49683V8.38086Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask0_792_1956)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M17.156 9.63086H7.12183C6.77683 9.63086 6.49683 9.35086 6.49683 9.00586C6.49683 8.66086 6.77683 8.38086 7.12183 8.38086H17.156C17.501 8.38086 17.781 8.66086 17.781 9.00586C17.781 9.35086 17.501 9.63086 17.156 9.63086Z"
                      fill="#2C2C2C"/>
            </g>
            <mask id="mask1_792_1956" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="14" y="5" width="4"
                  height="8">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.0916 5.9519H17.781V12.0609H14.0916V5.9519Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask1_792_1956)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M14.7163 12.0609C14.5563 12.0609 14.3955 12 14.2738 11.8767C14.0305 11.6317 14.0313 11.2367 14.2755 10.9934L16.2705 9.00586L14.2755 7.0192C14.0313 6.77586 14.0297 6.38086 14.2738 6.13586C14.5172 5.89086 14.9122 5.89086 15.1572 6.1342L17.5972 8.56336C17.7155 8.68003 17.7813 8.84003 17.7813 9.00586C17.7813 9.1717 17.7155 9.3317 17.5972 9.44836L15.1572 11.8784C15.0355 12 14.8755 12.0609 14.7163 12.0609Z"
                      fill="#2C2C2C"/>
            </g>
        </SvgIcon>
    );
}
