import {generateQuery} from "src/shared/services/ApiService/helpers";
import {IDirectionsFilter, ListResponse} from "src/shared/services/ApiService";

export type UseDirectionsFilterListQueryVariables = {}

export type UseDirectionsFilterListQueryResponse = ListResponse<IDirectionsFilter>

export const [useDirectionsFilterListQuery, getUseDirectionsFilterListQueryKey] = generateQuery<undefined,
    UseDirectionsFilterListQueryVariables,
    UseDirectionsFilterListQueryResponse>(
    params => `directions/short`,
    'DirectionsFilterList'
)
