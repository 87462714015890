import {Box, Skeleton, Stack} from "@mui/material";
import {PortfolioProjectListItem} from "./PortfolioProjectListItem";
import {useOrganizationProjectsQuery} from "src/shared/services/ApiService";
import {useDecodedToken} from "src/shared/utils/hooks/useDecodedToken";
import {Maybe} from "src/shared/ui";
import {useQueryParams} from "src/shared/utils/hooks/useQueryParams";
import {range} from "lodash";
import {useEffect} from "react";

export function PortfolioProjectList() {
    const [params, setParams] = useQueryParams()

    const tokenInfo = useDecodedToken()
    const {
        data: {data} = {}
    } = useOrganizationProjectsQuery({
        params: {
            organizationId: tokenInfo?.userInfo.currentOrganization.id as string
        },
        queryParams: {
            page: 1,
            perPage: 50
        },
    }, {
        enabled: !!tokenInfo?.userInfo.currentOrganization.id,
        onSuccess: ({data}) => {
            if(!params?.id) {
                setParams({
                    ...params,
                    id: data[0]?.id
                })
            }
        }
    })

    useEffect(() => {
        if(!params?.id) {
            setParams({
                ...params,
                id: data?.[0]?.id
            })
        }
    }, [data, params?.id]);


    return (
        <Maybe
            when={!!data?.length}
            fallback={(
                <Stack spacing={2}>
                    {range(3).map((i)=>(
                        <PortfolioProjectListItem
                            key={i}
                            id={i.toString()}
                            name={<Skeleton/>}
                            description={(
                                <>
                                    <Skeleton width={100}/>
                                    <Skeleton width={70}/>
                                </>
                            )}
                        />
                    ))}
                </Stack>
            )}
        >
            <Stack spacing={2}>
                {data?.map(({id, name, description}) => (
                    <PortfolioProjectListItem
                        active={params?.id === id}
                        key={id}
                        id={id}
                        onClick={(id) => setParams({
                            ...params,
                            id,
                        })}
                        name={name}
                        description={description}
                    />
                ))}
            </Stack>
            {/*<PortfolioProjectListItem/>*/}
        </Maybe>
    )
}
