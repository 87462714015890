import { useEffect, useState } from 'react'

import { FormikConfig, FormikHelpers, useFormik } from 'formik'
import { forEach, isEqual } from 'lodash'

import { useQueryParams } from './useQueryParams'

type ParseConfig = {
    numberedValues?: string[]
}

const converToNumbers = (
    values: Record<string, unknown>,
    numberedValues = ['page', 'limit', 'offset', 'count']
) => {
    forEach(numberedValues, key => {
        if (values[key]) values[key] = Number(values[key])
    })
    return values
}

/*
    Тот же самый useFormik + синхронизация с query params
 */

export const useFormikWithQueryString = <T extends Record<string, unknown>>(
    formikConfig: FormikConfig<T>,
    parseConfig?: ParseConfig
) => {
    const [searchParams, setParams] = useQueryParams()

    const { onSubmit, initialValues = {} } = formikConfig

    const [submittedValues, setSubmittedValues] = useState<T | undefined>()

    const onSubmitOverride = (
        formValues: T,
        formikHelpers: FormikHelpers<T>
    ) => {
        setSubmittedValues(
            converToNumbers(formValues, parseConfig?.numberedValues) as T
        )

        return (
            onSubmit &&
            onSubmit(
                converToNumbers(formValues, parseConfig?.numberedValues) as T,
                formikHelpers
            )
        )
    }

    const formikResults = useFormik({
        ...formikConfig,
        initialValues: initialValues as T,
        onSubmit: onSubmitOverride,
    })

    useEffect(() => {
        if (submittedValues) {
            setParams(submittedValues as Record<string, string | string[]>)
        }
    }, [submittedValues, setParams])

    useEffect(() => {
        const initialFormValues = {
            ...initialValues,
            ...searchParams,
        }

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        formikResults.setValues(initialFormValues as T)
        void onSubmitOverride(initialFormValues as T, formikResults)
        // eslint-disable-next-line
    }, [searchParams])

    return {
        submittedValues, // Отличие от values в том что меняет свое значение только при событии onSubmit
        ...formikResults,
    }
}
