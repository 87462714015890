import {useEffect, useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Typography} from "@mui/material";
import {CounterField} from "src/shared/ui";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {IServiceListItem} from "src/shared/services/ApiService";
import {SERVICE_PARAMS_LABELS} from "src/entities/Service";
import {FontFamily} from "src/shared/utils/font";
import {FormikHandlers} from "formik";

type Props = {
    service: IServiceListItem
    name: string,
    values: Record<string, number>,
    onChange: FormikHandlers['handleChange']
}

export function TariffServiceAccordion({
                                           name,
                                           values,
                                           service,
                                           onChange
                                       }: Props) {
    const [opened, setOpened] = useState(false);


    return (
        <Box>
            <Accordion
                expanded={opened}
                onChange={() => {
                    setOpened((o) => !o)
                }}
                sx={{
                    boxShadow: 'none',
                    border: '1px solid #DEDEDE',
                    borderBottom: 'none',
                    borderRadius: '8px',
                    '&.Mui-expanded': {
                        borderBottom: '1px solid #DEDEDE'
                    }
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    sx={{
                        borderBottom: '1px solid #DEDEDE',
                        borderRadius: '8px',
                        '&.MuiButtonBase-root': {
                            minHeight: '48px',
                            '& .MuiAccordionSummary-content': {
                                margin: '12px 0'
                            },
                        }

                    }}>
                    <Typography
                        fontFamily={FontFamily.ibmMono}
                        fontWeight="700"
                    >
                        {service.name}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        Object.keys(service).map((key) => (
                            (SERVICE_PARAMS_LABELS[key as 'ipQuantity'] && service[key as 'ipQuantity']) ? (
                                <Box my={1.75} sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <Typography>
                                        {SERVICE_PARAMS_LABELS[key as 'ipQuantity']}
                                    </Typography>
                                    <CounterField
                                        name={`${name}.${key}`}
                                        value={values?.[key] || 0}
                                        onChange={onChange}
                                    />
                                </Box>
                            ) : null
                        ))
                    }
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
