import {Grid} from "@mui/material";
import {PortfolioProjects} from "./PortfolioProjects";
import {PortfolioProject} from "./PortfolioProject";

export function Portfolio() {
    return (
        <Grid container spacing={7.5}>
            <Grid item xs={4}>
                <PortfolioProjects/>
            </Grid>

            <Grid item xs={8}>
                <PortfolioProject/>
            </Grid>
        </Grid>
    )
}
