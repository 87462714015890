import * as React from 'react';
import {alpha, styled} from '@mui/material/styles';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import {OutlinedInputProps} from "@mui/material";

export const AuthTextField = styled((props: TextFieldProps) => (
    <TextField
        InputProps={{disableUnderline: true} as Partial<OutlinedInputProps>}
        {...props}
    />
))(({theme}) => ({
    '& .MuiFilledInput-root': {
        fontWeight: '500',
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}));
