import {ColumnDef} from "@tanstack/react-table";
import {IServicePortfolioUnallocated, IServicePurchased} from "src/shared/services/ApiService";
import {Box, Chip, Skeleton, Typography} from "@mui/material";
import React from "react";
import {FontFamily} from "src/shared/utils/font";
import {ServiceJoin} from "src/features/UserPortfolio/ui/Portfolio/PortfolioProject/ServiceJoin";

const commonColumns: ColumnDef<IServicePurchased>[] = [
    {
        header: 'Группа',
        accessorKey: 'group',
        maxSize: 60,
        minSize: 60,
        size: 60,
        cell: (v) => (
            <Box>
                <Chip
                    sx={{
                        backgroundColor: v.row.original.group.colorBackground,
                        color: 'white',
                        borderRadius: '4px',
                        height: '26px',
                    }}
                    label={(
                        <Box display="flex" gap="8px" alignItems="center">
                            <Box sx={{
                                width: '5px',
                                height: '5px',
                                backgroundColor: v.row.original.group.colorTag,
                                borderRadius: '100%'
                            }}/>
                            <Typography
                                variant="caption"
                                fontWeight="700"
                                display="block"
                                lineHeight="12px"
                                sx={{
                                    color: v.row.original.group.colorTag
                                }}
                            >
                                {v.row.original.abbreviation}
                            </Typography>
                        </Box>
                    )}
                />
            </Box>
        )
    },
    {
        header: 'Направление',
        accessorKey: 'group.name',
        size: 300,
        cell: (v) => (
            <Typography sx={{
                fontFamily: FontFamily.ibmMono,
                fontSize: '16px',
                fontWeight: 700,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                '-webkit-line-clamp': '1',
                '-webkit-box-orient': 'vertical',
            }}>
                {v.row.original.group.name}
            </Typography>
        )
    },
    {
        header: 'Название',
        accessorKey: 'name',
        size: 300,
        cell: (v) => (
            <Typography sx={{
                fontFamily: FontFamily.ibmMono,
                fontSize: '16px',
                fontWeight: 700,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                '-webkit-line-clamp': '1',
                '-webkit-box-orient': 'vertical',
            }}>
                {v.row.original.name}
            </Typography>
        )
    },
]


export const getColumns = (): ColumnDef<IServicePurchased>[] => ([
    ...(commonColumns as unknown as ColumnDef<IServicePurchased>[]),
])

export const getColumnsUnallocated = (): ColumnDef<IServicePurchased>[] => ([
    ...(commonColumns as unknown as ColumnDef<IServicePurchased>[]),
    {
        header: '',
        accessorKey: 'purchasedEventDetailsId',
        size: 40,
        cell: (v) => {
            return (
                <ServiceJoin service={v.row.original}/>
            )
        }
    }
])

export const getColumnsLoading = (): ColumnDef<{ i: number }>[] => ([
    {
        header: 'Тариф',
        maxSize: 60,
        minSize: 60,
        size: 60,
        cell: (v) => (
            <Box>
                <Skeleton/>
            </Box>
        )
    },
    {
        header: 'Название',
        size: 400,
        cell: (v) => (
            <Box>
                <Skeleton/>
            </Box>
        )
    },
    {
        header: 'Статус',
        size: 200,
        cell: (v) => {
            return (
                <Box>
                    <Skeleton/>
                </Box>
            )
        }
    },
    {
        header: '',
        accessorKey: 'purchasedEventDetailsId',
        size: 40,
        cell: (v) => {
            return (
                <Box>
                    <Skeleton/>
                </Box>
            )
        }
    }
])
