import {generateMutation} from "src/shared/services/ApiService/helpers";


export type UseAppealsCreateMutationVariables = {
    text: string
    email: string,
    phone?: string
}

export type UseAppealsCreateMutationResponse = {}


export const useAppealsCreateMutation = generateMutation<
    undefined,
    UseAppealsCreateMutationVariables,
    UseAppealsCreateMutationResponse
    >(
    'post',
    params => 'appeals'
)
