import React from 'react'
import {List} from "src/features/Services/ui/Services/ServiceList/List";
import {IDirection} from "src/shared/services/ApiService/interfaces/IDirection";

type Props = {
    items: IDirection[]
}

export default function ServiceList({
                                        items
                                    }: Props) {
    if (!items?.length) return null

    return (
        <List
            items={items}
        />
    )
}
