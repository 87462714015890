export enum OrganizationRolesEnum {
    CUSTOMER_ROLE = 'CUSTOMER_ROLE',
    SUPPLIER_ROLE = 'SUPPLIER_ROLE',
}

export enum UserRolesEnum {
    ROLE_ADMIN = 'ROLE_ADMIN',
}


export enum PermissionsEnum {
    CAN_ACCESS = 'CAN_ACCESS',
    CAN_EDIT = 'CAN_EDIT',
}


export const PERMISSION_ROLES = {
    [PermissionsEnum.CAN_ACCESS]: [
        UserRolesEnum.ROLE_ADMIN,
    ],
}
