import React from 'react'
import TariffCardBody from "./TariffCardBody";
import TariffCardHeader from "./TariffCardHeader";
import TariffCardServicesIncludedIn from "./TariffCardServicesIncludedIn";
import {Fade, Paper} from "@mui/material";
import {TariffCalculate} from "src/features/Tariffs/ui/Tariff/TariffCalculate/TariffCalculate";
import {ITariff, useTariffQuery} from "src/shared/services/ApiService";
import {Maybe} from "src/shared/ui";
import TariffCardFallback from "src/features/Tariffs/ui/Tariff/TariffCard/TariffCardFallback";
import DOMPurify from 'dompurify'


type Props = {
    id: ITariff["id"]
}

export default function TariffCard({
                                       id
                                   }: Props) {
    const {data: {data} = {}, isLoading} = useTariffQuery({
        params: {
            tariffId: id
        },
        queryParams: {}
    }, {
        enabled: !!id
    })

    return (
        <Maybe
            when={!!data}
            fallback={<TariffCardFallback/>}
        >
            <Fade in={true}>
                <Paper
                    variant="outlined"
                    sx={{
                        padding: '28px 32px'
                    }}
                >
                    <TariffCardHeader
                        data={data!}
                    />
                    <TariffCardBody
                        data={data!}
                    />
                    <TariffCalculate
                        id={id}
                    />
                    <TariffCardServicesIncludedIn
                        id={id}
                    />
                </Paper>
            </Fade>
        </Maybe>
    )
}
