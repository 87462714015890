import React from 'react'
import {
    Box,
    Container,
} from "@mui/material";
import {AppBar} from '../common/AppBar'
import ToggleNav from "src/shared/ui/Layouts/HomeLayout/ToggleNav";

type Props = {
    slotTop?: React.ReactNode,
    children: React.ReactNode,
    backgroundColor?: string,
}

export function BaseLayout({
                               slotTop,
                               children,
                               backgroundColor = '#FAF9F7'
                           }: Props) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh'
        }}>

            <AppBar>
                <ToggleNav/>
            </AppBar>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 3,
                    pt: 2,
                    backgroundColor
                }}
            >
                {slotTop}
                <Container maxWidth="xl">
                    {children}
                </Container>
            </Box>
        </Box>
    )
}
