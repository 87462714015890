import React from 'react'
import ServiceCardBody from "./ServiceCardBody";
import ServiceCardHeader from "./ServiceCardHeader";
import ServiceCardTariffsIncludedIn from "./ServiceCardTariffsIncludedIn";
import {Box, Fade, Paper} from "@mui/material";
import {IService, useServiceQuery} from "src/shared/services/ApiService";
import {Maybe} from "src/shared/ui";
import ServiceCardFallback from "src/features/Services/ui/Service/ServiceCard/ServiceCardFallback";
import ServiceCardTariffs from "src/features/Services/ui/Service/ServiceCard/ServiceCardTariffs";

type Props = {
    id: IService["id"]
}

export default function ServiceCard({
                                        id,
                                    }: Props) {
    const {data: {data} = {}, isLoading} = useServiceQuery({
        params: {
            serviceId: id
        },
        queryParams: {}
    }, {
        enabled: !!id
    })

    return (
        <Maybe
            when={!!data}
            fallback={<ServiceCardFallback/>}
        >
            <Fade in={true}>
                <Paper
                    variant="outlined"
                    sx={{
                        padding: '28px 32px'
                    }}
                >
                    <ServiceCardHeader
                        data={data!}
                    />
                    <ServiceCardBody
                        data={data!}
                    />

                    <Box mt={6}>
                        <ServiceCardTariffs
                            id={id}
                        />
                    </Box>
                </Paper>
            </Fade>
        </Maybe>
    )
}
