import React from 'react'
import {Box, Grid, Typography} from "@mui/material";
import AnotherTariffs from "./AnotherTariffs";
import TariffCard from "./TariffCard/TariffCard";
import {BackAction, FavoriteAction, ShareAction} from "src/shared/ui";
import {Navigate, useParams} from "react-router-dom";

export default function Tariff() {
    const {id} = useParams()

    if (!id) {
        return <Navigate to="/404" replace/>
    }

    return (
        <Grid container spacing={7.5}>
            <Grid item xs={8.5}>
                <Box
                    mb={0.5}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <BackAction/>

                    <Box display="flex" gap="24px">
                        <ShareAction/>
                        <FavoriteAction/>
                    </Box>
                </Box>

                <TariffCard
                    id={id}
                />
            </Grid>

            <Grid item xs={3.5}>
                <Typography
                    variant="h5"
                    fontWeight={700}
                    sx={{
                        marginBottom: '16px'
                    }}
                >
                    Также смотрят
                </Typography>

                <AnotherTariffs/>
            </Grid>
        </Grid>
    )
}
