import React, {useEffect, useState} from "react";
import {Box, Button, Chip, CircularProgress, Paper, Skeleton, Stack, Typography} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import {usePurchasedEventDetailsQuery} from "src/shared/services/ApiService/queries/usePurchasedEventDetailsQuery";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {styled} from "@mui/material/styles";
import OrderServiceTab from "src/features/UserOrders/ui/View/OrderServiceTab";
import OrderService from "src/features/UserOrders/ui/View/OrderService";
import {
    usePurchasedEventDetailsConfirmMutation,
    usePurchasedEventDetailsDeleteMutation
} from "src/shared/services/ApiService";
import {ModalWrapper, useModal, useNotification} from "src/shared/providers";
import {LoadingButton} from "src/shared/ui";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <Paper
            variant="outlined"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            sx={{
                bgcolor: 'background.paper',
                flexGrow: 1,
                borderRadius: '12px',
                ':last-child': {
                    borderBottomLeftRadius: 0
                },
                borderTopLeftRadius: value === index ? 0 : 12,
                marginLeft: '-16px',
                ...(value === index && {
                    borderColor: '#0048D3',
                })
            }}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </Paper>
    );
}

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
    orientation: "horizontal" | "vertical"
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        display: 'none'
    },
});

interface StyledTabProps {
    label: React.ReactNode | string;
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({theme}) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    borderRadius: '12px',
    backgroundColor: '#F1F1F1',
    ':not(:last-child)': {
        marginBottom: '4px',
    },
    paddingRight: '27px !important',
    filter: 'grayscale(0.3)',

    '&.Mui-selected': {
        backgroundColor: theme.palette.background.paper,
        borderRight: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        marginRight: '14px !important',
        paddingRight: '14px !important',
        borderColor: '#0048D3',
        filter: 'grayscale(0)',
    },
    '&:hover:not(.Mui-selected)': {
        filter: 'grayscale(0)',
        backgroundColor: '#faf8f8',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    border: '1px solid rgba(0, 0, 0, 0.12)'
}));

export default function Order() {
    const {
        openModal,
        closeModal
    } = useModal()
    const [isSavingQuestion, setIsSavingQuestion] = useState(false)
    const {mutateAsync: saveOrder, isLoading} = usePurchasedEventDetailsConfirmMutation()
    const {mutateAsync: deleteOrder} = usePurchasedEventDetailsDeleteMutation()
    const {showSuccessNotification} = useNotification()
    const navigate = useNavigate()
    const {id} = useParams()
    const [value, setValue] = React.useState(-1);
    const {data, refetch, isLoading: isOrdersLoading} = usePurchasedEventDetailsQuery({
        queryParams: {},
        params: {
            id: id as string,
        }
    }, {
        enabled: !!id,
        onSuccess: (data) => {
            if (data?.orders?.length === 0) navigate('/user/orders', {
                replace: true
            })
        }
    })

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleSave = async () => {
        if (!id) return

        await saveOrder({
            params: {
                id: id
            }
        })

        showSuccessNotification({
            message: 'Заказ успешно сохранен!'
        })

        navigate('/user/orders')
    }

    useEffect(() => {
        if (value !== -1) return

        if (data?.orders) {
            const i = data?.orders?.findIndex(q => q.questionCount.all !== q.questionCount.finished)

            setValue(i === -1 ? 0 : i)
        }
    }, [data?.orders]);

    const handleDelete = (id: string | number) => {
        const key = 'delete-modal'

        openModal({
            key,
            component: (
                <ModalWrapper
                    dialogTitle="Вы действительно хотите удалить решение?"
                    onClose={closeModal.bind(null, key)}
                >
                    <Box>
                        <Typography>
                            Все ответы на вопросы так же будут удалены.
                        </Typography>

                        <Box
                            mt={2}
                            sx={{
                                display: 'flex',
                                gap: '16px',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                onClick={() => {
                                    closeModal(key)
                                }}
                            >
                                Отмена
                            </Button>

                            <LoadingButton
                                variant="contained"
                                onClick={async () => {
                                    await deleteOrder({
                                        params: {
                                            id: id,
                                        }
                                    })
                                    await refetch()

                                    closeModal(key)
                                }}
                            >
                                Удалить
                            </LoadingButton>
                        </Box>
                    </Box>
                </ModalWrapper>
            )
        })
    }

    if (isOrdersLoading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '100px'
                }}
            >
                <CircularProgress size="70px"/>
            </Box>
        )
    }

    return (
        <>
            <Paper
                variant="outlined"
                sx={{
                    padding: '28px 32px',
                    marginBottom: '32px'
                }}
            >
                <Box
                    sx={{
                        backgroundColor: '#EFEFFD',
                        padding: '20px 24px',
                        borderRadius: '12px'
                    }}
                >
                    <Typography variant="h5"
                                fontWeight={700}
                                sx={{
                                    color: '#5856D6'
                                }}
                    >
                        КОНСТРУКТОР ЗАКАЗА
                    </Typography>
                </Box>

                <Box mt={2}>
                    <Typography variant="h6" fontWeight="bold">
                        Как выбрать решение и получить оценку?
                    </Typography>
                    <Typography>
                        Для того, чтобы сформировать решение и получить оценку, вы можете непосредственно выбрать нужные
                        вам
                        позиции и добавить их к предварительному заказу, либо воспользоваться помощью нашего менеджера,
                        заполнив анкету.
                    </Typography>
                </Box>
            </Paper>

            <Box
                sx={{flexGrow: 1, display: 'flex'}}
            >
                <StyledTabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    sx={{
                        minWidth: '360px'
                    }}
                >
                    {data?.orders?.map((order, index) => (
                        <StyledTab
                            label={(
                                <OrderServiceTab
                                    order={order}
                                    selected={value === index}
                                    onDelete={handleDelete}
                                />
                            )}
                        />
                    ))}
                </StyledTabs>
                {data?.orders?.map((order, index) => (
                    <TabPanel value={value} index={index}>
                        <OrderService
                            order={order}
                            onSaveQuestion={(loading) => {
                                setIsSavingQuestion(loading)

                                if (!loading) {
                                    refetch()
                                }
                            }}
                        />
                    </TabPanel>
                ))}
            </Box>
            <Box mt={3} textAlign="right">
                <LoadingButton
                    variant="contained"
                    disabled={isSavingQuestion}
                    onClick={handleSave}
                    loading={isLoading}
                >
                    Завершить формирование заказа
                </LoadingButton>
            </Box>
        </>
    )
}