import React from 'react'
import {styled} from '@mui/material/styles';
import {Box, Fade, Skeleton, Stack, ToggleButton, Grow} from "@mui/material";
import {HintTitle} from "src/features/Services/ui/Services/ServiceFilters/HintTitle";
import {useServiceGroupsQuery} from "src/shared/services/ApiService";
import {Maybe} from "src/shared/ui";
import {FormikHandlers} from "formik";
import {FontFamily} from "src/shared/utils/font";
import {TransitionGroup} from 'react-transition-group'
import {getLimitedEnterTimeout} from "src/shared/utils";
import {useDirectionsFilterListQuery} from "src/shared/services/ApiService/queries/useDirectionsFilterListQuery";


const StyledToggleButton = styled(ToggleButton)(() => ({
    textTransform: 'none',
    borderRadius: '50px',
    padding: '16px 20px',
    lineHeight: '20px',
    color: '#2C2C2C',
    width: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
    '&:hover': {
        backgroundColor: 'rgba(0,72,211,0.04)',
    },
    '&.Mui-selected': {
        backgroundColor: 'white',
        color: '#0048D3',
        border: '1px solid #0048D3',
        '&:hover': {
            backgroundColor: 'rgba(0,72,211,0.04)',
        },
    },
}))

type Props = {
    value: string[],
    onChange: FormikHandlers['handleChange']
}

export default function ServiceDirections({
                                          value,
                                          onChange
                                      }: Props) {
    const {
        data: {data} = {}
    } = useDirectionsFilterListQuery({
        params: undefined,
        queryParams: {}
    })


    const makeHandleChange = (tag: string) => () => {
        onChange({
            target: {
                name: 'directions',
                value: value?.includes(tag) ? value.filter((v) => v !== tag) : [...value, tag],
            }
        })
    }

    return (
        <Box>
            <HintTitle
                title="Направления"
            />
            <Maybe
                when={!!data?.length}
                fallback={(
                    <Stack direction="column" spacing="12px">
                        <StyledToggleButton
                            value="1"
                            disableRipple
                            sx={{
                                fontFamily: FontFamily.ibmSans
                            }}
                        >
                            <Skeleton width={250}/>
                        </StyledToggleButton>
                        <StyledToggleButton
                            value="2"
                            disableRipple
                            sx={{
                                fontFamily: FontFamily.ibmSans
                            }}
                        >
                            <Skeleton width={170}/>
                        </StyledToggleButton>
                    </Stack>
                )}
            >
                <TransitionGroup>
                    <Stack direction="column" spacing="12px">
                        {data?.map(({id, name}, i) => (
                            <Grow
                                in
                                exit={false}
                                appear
                                timeout={{
                                    enter: getLimitedEnterTimeout(300 + i * 100),
                                }}
                            >
                                <StyledToggleButton
                                    disableRipple
                                    value={id}
                                    selected={value?.includes(id)}
                                    onClick={makeHandleChange(id)}
                                    sx={{
                                        fontFamily: FontFamily.ibmSans
                                    }}
                                >
                                    {name}
                                </StyledToggleButton>
                            </Grow>
                        ))}
                    </Stack>
                </TransitionGroup>
            </Maybe>
        </Box>
    )
}
