import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function ProfileIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="14" height="18" viewBox="0 0 14 18" fill="none"
            {...props}
        >
            <mask id="mask0_792_1857" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="11" width="14"
                  height="7">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.333252 11.0859H13.5332V17.2309H0.333252V11.0859Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask0_792_1857)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M6.93409 12.3359C3.38325 12.3359 1.58325 12.9459 1.58325 14.1501C1.58325 15.3651 3.38325 15.9809 6.93409 15.9809C10.4841 15.9809 12.2833 15.3709 12.2833 14.1668C12.2833 12.9518 10.4841 12.3359 6.93409 12.3359ZM6.93409 17.2309C5.30159 17.2309 0.333252 17.2309 0.333252 14.1501C0.333252 11.4034 4.10075 11.0859 6.93409 11.0859C8.56659 11.0859 13.5333 11.0859 13.5333 14.1668C13.5333 16.9134 9.76659 17.2309 6.93409 17.2309Z"
                      fill="#2C2C2C"/>
            </g>
            <mask id="mask1_792_1857" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="2" y="0" width="10"
                  height="10">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.50806 0.672607H11.3581V9.52144H2.50806V0.672607Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask1_792_1857)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M6.93392 1.86224C5.14975 1.86224 3.69808 3.31307 3.69808 5.09724C3.69225 6.87557 5.13308 8.32557 6.90975 8.33224L6.93392 8.92724V8.33224C8.71725 8.33224 10.1681 6.88057 10.1681 5.09724C10.1681 3.31307 8.71725 1.86224 6.93392 1.86224ZM6.93392 9.52141H6.90725C4.47225 9.51391 2.49975 7.52807 2.50808 5.09474C2.50808 2.65724 4.49308 0.672241 6.93392 0.672241C9.37392 0.672241 11.3581 2.65724 11.3581 5.09724C11.3581 7.53724 9.37392 9.52141 6.93392 9.52141Z"
                      fill="#2C2C2C"/>
            </g>
        </SvgIcon>
    );
}
