import {ListResponse} from "src/shared/services/ApiService"
import {generateQuery} from "src/shared/services/ApiService/helpers";
import {IDirection} from "src/shared/services/ApiService/interfaces/IDirection";

export type UseDirectionsQueryVariables = {
    groups?: string[],
    directions?: string[],
}

export type UseDirectionsQueryResponse = ListResponse<IDirection>

export const [useDirectionsQuery, getUseDirectionsQueryKey] = generateQuery<undefined,
    UseDirectionsQueryVariables,
    UseDirectionsQueryResponse>(
    params => `directions`,
    'directions'
)
