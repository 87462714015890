import jwtDecode from 'jwt-decode'
import {useAuthContext} from "src/shared/providers";
import {useMemo} from "react";

export const useDecodedToken = (): { username: string, userInfo: { currentOrganization: { id: string } } } | null => {
    const {token} = useAuthContext()

    return useMemo(() => {
        try {
            if (token) {
                return jwtDecode(token)

            }

            return null
        } catch {
            return null
        }
    }, [token]);
}
