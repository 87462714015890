import React, {useState} from 'react'
import {compact, range, take} from 'lodash'
import {Badge, Box, Button, Collapse, IconButton, Popover} from "@mui/material";
import {Maybe} from "src/shared/ui";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {FontFamily} from "src/shared/utils/font";


type Props = {
    items: React.ReactNode[],
    max: number
}

export const ShowMore: React.FC<Props> = ({
                                              items,
                                              max,
                                          }) => {
    const [opened, setOpened] = useState(false)
    const clearedItems = compact(items)

    const slicedChildren = take(clearedItems, max)
    const restCount = clearedItems.length - slicedChildren.length


    return (
        <>
            {slicedChildren}

            <Maybe when={!!restCount}>
                <Box>
                    <Collapse in={opened}>
                        {range(max, max + restCount).map((n) => clearedItems[n])}
                    </Collapse>
                    <Button
                        endIcon={<KeyboardArrowDownIcon sx={{
                            transform: `rotate(${opened ? 180 : 0}deg)`,
                            transition: (theme) => theme.transitions.create('transform')
                        }}/>}
                        sx={{
                            textTransform: 'none',
                            '&.MuiButton-root': {
                                fontSize: '14px',
                                fontFamily: FontFamily.ibmSans,
                                color: '#2C2C2C',
                                fontWeight: 600,
                                marginLeft: '-4px',
                                marginTop: '8px'
                            }
                        }}
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpened((v) => !v)
                        }}
                    >
                        {opened ? 'Скрыть' : 'Показать все'}
                    </Button>
                </Box>
            </Maybe>
        </>
    )
}

