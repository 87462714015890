import {generateQuery} from "src/shared/services/ApiService/helpers";
import {
    IPurchasedEventDetailsOrder,
    SimpleListResponse,
} from "src/shared/services/ApiService";
import {
    IPurchasedEventDetailsOrderQuestion
} from "src/shared/services/ApiService/interfaces/IPurchasedEventDetailsOrderQuestion";

export type UsePurchasedEventDetailsOrdersQueryVariables = {}

export type UsePurchasedEventDetailsOrdersQueryResponse = SimpleListResponse<IPurchasedEventDetailsOrderQuestion>

export const [usePurchasedEventDetailsOrdersQuery, getUsePurchasedEventDetailsOrdersQueryKey] = generateQuery<{
    id: string | number
},
    UsePurchasedEventDetailsOrdersQueryVariables,
    UsePurchasedEventDetailsOrdersQueryResponse>(
    params => `purchased-event-details/orders/${params.id}`,
    'PurchasedEventDetailsOrders'
)

