import {IOrganizationProjectListItem, ListResponse} from "src/shared/services/ApiService";
import {generateQuery} from "src/shared/services/ApiService/helpers";

export type UseOrganizationProjectsQueryVariables ={
    page: number,
    perPage: number
}

export type UseOrganizationProjectsQueryResponse = ListResponse<IOrganizationProjectListItem>

export const [useOrganizationProjectsQuery, getUseOrganizationProjectsQueryKey] = generateQuery<{
    organizationId: string
},
    UseOrganizationProjectsQueryVariables,
    UseOrganizationProjectsQueryResponse>(
    params => `organizations/${params.organizationId}/projects`,
    'OrganizationProjects'
)
