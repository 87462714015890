import {Box, IconButton, Typography} from "@mui/material";
import {PortfolioProjectList} from "./PortfolioProjectList";
import {PlusIcon} from "src/shared/ui";

export function PortfolioProjects() {
    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={3}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontWeight: 700,
                    }}
                >
                    Мой портфель
                </Typography>

                <Box>
                    <IconButton>
                        <PlusIcon/>
                    </IconButton>
                </Box>
            </Box>


            <PortfolioProjectList/>
        </Box>
    )
}
