import {generateMutation} from "src/shared/services/ApiService/helpers";


export type UseProjectsServicePortfoliosJoinMutationVariables = {
    joins: { project: string, servicePortfolio: string }[]
}

export type UseProjectsServicePortfoliosJoinMutationResponse = {}


export const useProjectsServicePortfoliosJoinMutation = generateMutation<undefined,
    UseProjectsServicePortfoliosJoinMutationVariables,
    UseProjectsServicePortfoliosJoinMutationResponse>(
    'post',
    params => 'projects/service-portfolios'
)
