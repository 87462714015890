import {Box, Typography} from "@mui/material";
import {SuccessCircleIcon} from "src/shared/ui/Icons/SuccessCircleIcon";

type Props = {
    text: string
}

export function SuccessModal({
                                 text
                             }: Props) {
    return (
        <Box textAlign="center">
            <SuccessCircleIcon
                sx={{
                    fontSize: '74px'
                }}
            />

            <Typography
                sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    marginTop: '24px'
                }}
            >
                {text}
            </Typography>
        </Box>
    )
}
