import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function ArrowRightIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="8" height="12" viewBox="0 0 8 12" fill="none"
            {...props}
        >
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1.20783 0.857981C0.764056 1.33529 0.764056 2.10916 1.20783 2.58646L4.38158 6L1.20783 9.41354C0.764057 9.89084 0.764057 10.6647 1.20783 11.142C1.65161 11.6193 2.37112 11.6193 2.8149 11.142L6.79217 6.86424C7.23594 6.38693 7.23594 5.61307 6.79217 5.13576L2.81489 0.857981C2.37112 0.380674 1.65161 0.380674 1.20783 0.857981Z"
                  fill="#2C2C2C"/>
        </SvgIcon>
    );
}
