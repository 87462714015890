import {ITariffListItem, ListResponse} from "src/shared/services/ApiService"
import {generateInfiniteQuery} from "src/shared/services/ApiService/helpers";

export type UseTariffsInfinityQueryVariables = {
    serviceId?: string,
    page?: number,
    perPage?: number
}

export type UseTariffsInfinityQueryResponse = ListResponse<ITariffListItem>

export const [useTariffsInfinityQuery, getUseTariffsInfinityQueryKey] = generateInfiniteQuery<undefined,
    UseTariffsInfinityQueryVariables,
    UseTariffsInfinityQueryResponse>(
    params => `tariffs`,
    'tariffs-infinity'
)
