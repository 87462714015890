import {createTheme} from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";


const themeLight = createTheme({
    components: {
        MuiMenuItem: {
          styleOverrides: {
              root: {
                  fontFamily: "IBM Plex Sans"
              }
          }
        },
        MuiAlert: {
          styleOverrides: {
              root: {
                  fontFamily: "IBM Plex Sans"
              }
          }
        },
        MuiButton: {
          styleOverrides: {
              root: {
                  borderRadius: '50px',
                  fontWeight: '700',
                  fontFamily: "IBM Plex Sans",
                  textTransform: 'none'
              },
              contained: {
                  boxShadow: '0px 8px 16px -5px rgba(3, 63, 180, 0.4)',
              },
              sizeLarge: {
                  fontSize: '16px',
                  height: '52px'
              },
              sizeMedium: {
                  height: '42px'
              }
          }
        },
        MuiInputBase: {
          styleOverrides: {
              root: {
                  fontFamily: "IBM Plex Sans"
              }
          }
        },
        MuiTypography: {
           styleOverrides: {
               root: {
                   fontFamily: "IBM Plex Sans"
               }
           }
        },
        MuiCssBaseline: {
            styleOverrides: (themeParam) => `
body {
    min-width: 1500px;
    font-family: "IBM Plex Sans";
}
            
@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Bold'), local('IBMPlexMono-Bold'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono ExtraLight'), local('IBMPlexMono-ExtraLight'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Bold Italic'), local('IBMPlexMono-BoldItalic'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Regular'), local('IBMPlexMono-Regular'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono SemiBold'), local('IBMPlexMono-SemiBold'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Medium Italic'), local('IBMPlexMono-MediumItalic'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono SemiBold Italic'), local('IBMPlexMono-SemiBoldItalic'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Medium'), local('IBMPlexMono-Medium'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Italic'), local('IBMPlexMono-Italic'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Thin Italic'), local('IBMPlexMono-ThinItalic'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono ExtraLight Italic'), local('IBMPlexMono-ExtraLightItalic'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Thin'), local('IBMPlexMono-Thin'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Light Italic'), local('IBMPlexMono-LightItalic'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: local('IBM Plex Mono Light'), local('IBMPlexMono-Light'),
        url('/assets/fonts/ibm-plex-mono/IBMPlexMono-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans ExtraLight Italic'), local('IBMPlexSans-ExtraLightItalic'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans Bold Italic'), local('IBMPlexSans-BoldItalic'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans Regular'), local('IBMPlexSans-Regular'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans SemiBold Italic'), local('IBMPlexSans-SemiBoldItalic'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans Thin'), local('IBMPlexSans-Thin'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans Thin Italic'), local('IBMPlexSans-ThinItalic'),
        url('/assets/fonts/ibm-plex-sans/IBMPlexSans-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
            `
        }
        // ...muiOverrides.default.reduce(
        //     (acc, cur) => ({ ...acc, ...cur }),
        //     {}
        // ),
    },
    //palette,
    shape: {borderRadius: 12},
    shadows,
});


themeLight.palette.text.primary = '#2C2C2C'
themeLight.palette.primary.main = '#0048D3'

export {
    themeLight
}
