import {BackAction} from "src/shared/ui";
import {Box, Container} from "@mui/material";

export function BackBlock() {
    return (
        <Box
            sx={{
                backgroundColor: '#FAF9F7',
                padding: '18px 0',
                marginTop: '-32px'
            }}
        >
            <Container maxWidth="xl">
                <Box>
                    <BackAction/>
                </Box>
            </Container>
        </Box>
    )
}
