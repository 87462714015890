import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function ThunderIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="9" height="16" viewBox="0 0 9 16" fill="none"
            {...props}
            style={{
                fill: 'none'
            }}
        >
            <path d="M8.66667 6.66669H4.66667V0.666687L0 9.33335H4V15.3334L8.66667 6.66669Z" fill="#FFD600"/>
        </SvgIcon>
    );
}
