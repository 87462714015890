import {
    Box, Container,
    IconButton, InputAdornment, OutlinedInputProps, TextField,
    Toolbar,
    AppBar as AppBarMui
} from "@mui/material";
import {Link} from "react-router-dom";
import ToggleNav from "src/shared/ui/Layouts/HomeLayout/ToggleNav";
import ProfileMenu from "src/shared/ui/Layouts/common/ProfileMenu";
import React from "react";
import {SearchIcon} from "src/shared/ui/Icons/SearchIcon";


type Props = {
    children?: React.ReactNode
}

export function AppBar({
                           children,
                       }: Props) {
    return (
        <Toolbar sx={{
            backgroundColor: 'white',
            position: 'sticky',
            top: '0',
            zIndex: 1000,
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
        }}>
            <Container
                maxWidth="xl"
                sx={{
                    backgroundColor: 'white'
                }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: '21px',
                    paddingBottom: '21px',
                    position: 'relative',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>
                    <Link to="/">
                        <IconButton
                            disableRipple
                            sx={{
                                padding: 0
                            }}
                        >
                            <img
                                width="200px"
                                src="/logo.svg"
                            />
                        </IconButton>
                    </Link>

                    {children}

                    <Box sx={{
                        display: 'flex',
                        gap: '12px',
                        //position: 'absolute',
                        //right: 24
                    }}>
                        <TextField
                            // id="search"
                            placeholder="Поиск"
                            variant="filled"
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                        >
                                            <SearchIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            } as Partial<OutlinedInputProps>}
                            sx={{
                                '& .MuiFilledInput-root': {
                                    borderRadius: '50px',
                                    border: '1px solid #DEDEDE'
                                },
                                '& .MuiInputBase-root': {
                                    backgroundColor: '#FAF9F7',
                                    height: '52px'
                                },
                                '& .MuiInputBase-input': {
                                    padding: '16px 17px',
                                    color: '#8F8F8F',
                                    fontWeight: 600,
                                    fontSize: '14px'
                                }
                            }}

                        />
                        <ProfileMenu/>
                    </Box>
                </Box>
            </Container>
        </Toolbar>
    )
}
