import {generateMutation} from "src/shared/services/ApiService/helpers";


export type UsePurchasedEventDetailsOrderSaveQuestionsMutationVariables = {
    answers: {
        questionId: string,
        answer: string
    }[]
}

export type UsePurchasedEventDetailsOrderSaveQuestionsMutationResponse = {}


export const usePurchasedEventDetailsOrderSaveQuestionsMutation = generateMutation<{
    id: string
},
    UsePurchasedEventDetailsOrderSaveQuestionsMutationVariables,
    UsePurchasedEventDetailsOrderSaveQuestionsMutationResponse>(
    'post',
    params => `purchased-event-details/orders/${params?.id}`
)
