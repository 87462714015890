import {Box, Button, Grid, Grow, Paper, Typography} from "@mui/material";
import {ArrowRightIcon, PlusIcon} from "src/shared/ui";
import {FontFamily} from "src/shared/utils/font";
import {ProfileIcon} from "src/features/UserAccount/ui/Icons/ProfileIcon";
import {OrganizationIcon} from "src/features/UserAccount/ui/Icons/OrganizationIcon";
import {TransactionIcon} from "src/features/UserAccount/ui/Icons/TransactionIcon";
import {Link} from "react-router-dom";
import {useDecodedToken} from "src/shared/utils/hooks/useDecodedToken";
import {useAuthContext} from "src/shared/providers";
import {TransitionGroup} from 'react-transition-group'
import {getLimitedEnterTimeout} from "src/shared/utils";


const VARIANTS = [
    {
        label: 'Профиль',
        icon: <ProfileIcon
            sx={{
                fontSize: '42px'
            }}
        />,
        disabled: false,
        description: 'Персональные данные',
        to: '/user/account/profile'
    },
    {
        label: 'Моя Организация',
        icon: <OrganizationIcon
            sx={{
                fontSize: '42px'
            }}
        />,
        disabled: true,
        description: 'Данный раздел находится в разработке....',
    },
    {
        label: 'История транзакций',
        icon: <TransactionIcon
            sx={{
                fontSize: '42px'
            }}
        />,
        disabled: true,
        description: 'Данный раздел находится в разработке....',
    },
]


export function Home() {
    const tokenInfo = useDecodedToken()
    const {logout} = useAuthContext()


    return (
        <Box>
            <Typography sx={{
                fontSize: '28px',
                fontWeight: 700,
            }}>
                Аккаунт
            </Typography>

            <Box mb={3} display="flex" flexDirection="row" alignItems="center" gap={1}>
                <Typography
                    sx={{
                        color: '#0048D3',
                        fontWeight: 600
                    }}
                >
                    {tokenInfo?.username}
                </Typography>

                <Button
                    onClick={() => logout()}
                    variant="text"
                    sx={{
                        fontSize: '14px',
                        color: '#000000',
                        textDecoration: 'underline'
                    }}
                >
                    Выйти из аккаунта
                </Button>
            </Box>

            <TransitionGroup>
                <Grid container spacing={2}>
                    {VARIANTS.map(({label, icon, description, disabled, to}, i) => (
                        <Grow
                            in
                            exit={false}
                            appear
                            timeout={{
                                enter: getLimitedEnterTimeout(500 + i * 100),
                            }}
                        >
                            <Grid item xs={4}>

                                <Link
                                    to={to || window.location.pathname}
                                    onClick={(e) => {
                                        if (disabled) e.stopPropagation()
                                    }}
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    <Paper elevation={0} sx={{
                                        cursor: 'pointer',
                                        padding: '24px',
                                        boxShadow: disabled ? 'none' : '0px 12px 24px -10px rgba(0, 0, 0, 0.15)',
                                        border: '1px solid #DEDEDE',
                                        backgroundColor: disabled ? '#F1F1F1' : 'white'
                                    }}>
                                        <Box>
                                            {icon}
                                        </Box>

                                        <Box
                                            my={1.5}
                                            sx={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: FontFamily.ibmMono,
                                                    fontSize: '18px',
                                                    fontWeight: 600,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {label}
                                            </Typography>

                                            <ArrowRightIcon sx={{
                                                fontSize: '12px'
                                            }}/>
                                        </Box>

                                        <Typography
                                            sx={{
                                                color: '#747474',
                                                fontSize: '14px',
                                                fontWeight: '400',
                                            }}
                                        >
                                            {description}
                                        </Typography>
                                    </Paper>
                                </Link>
                            </Grid>
                        </Grow>

                    ))}
                </Grid>
            </TransitionGroup>
        </Box>
    )
}
