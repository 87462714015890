import React from 'react';
import {BrowserRouter} from "react-router-dom";
import {
    ThemeProvider,
    NotificationProvider,
    ApiProvider,
    AuthProvider,
    RouterProvider,
    RbacServiceProvider,
    ModalProvider,
} from 'src/shared/providers'
import {routes} from 'src/pages'

export default function App() {
    return (
        <BrowserRouter>
            <ThemeProvider>
                <NotificationProvider>
                    <AuthProvider>
                        <ApiProvider>
                            <ModalProvider>
                                <RbacServiceProvider>
                                    <RouterProvider routes={routes}/>
                                </RbacServiceProvider>
                            </ModalProvider>
                        </ApiProvider>
                    </AuthProvider>
                </NotificationProvider>
            </ThemeProvider>
        </BrowserRouter>
    )
}
