import {generateQuery} from "src/shared/services/ApiService/helpers";
import {
    IPurchasedEventDetailsOrder,
    SimpleListResponse,
} from "src/shared/services/ApiService";

export type UsePurchasedEventDetailsQueryVariables = {}

export type UsePurchasedEventDetailsQueryResponse = {
    orders: IPurchasedEventDetailsOrder[]
}

export const [usePurchasedEventDetailsQuery, getUsePurchasedEventDetailsQueryKey] = generateQuery<{
    id: string | number
},
    UsePurchasedEventDetailsQueryVariables,
    UsePurchasedEventDetailsQueryResponse>(
    params => `purchased-event-details/${params.id}`,
    'PurchasedEventDetails'
)

