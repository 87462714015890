import {RouteObject, Navigate, Link} from "react-router-dom";
import {routes as authenticationFeatureRoutes} from 'src/features/Authentication'
import {routes as servicesRoutes} from 'src/features/Services'
import {routes as tariffsRoutes} from 'src/features/Tariffs'
import {routes as userPortfolioRoutes} from 'src/features/UserPortfolio'
import {routes as userProjectsRoutes} from 'src/features/UserProjects'
import {routes as userAccountRoutes} from 'src/features/UserAccount'
import {routes as userOrdersRoutes} from 'src/features/UserOrders'
import React from "react";
import {Box, Typography} from "@mui/material";
import {HomeLayout} from "src/shared/ui";

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <Navigate to="/solutions"/>
    },
    {
        path: '/403',
        element: <div>403</div>
    },

    // Авторизация/Регистрация
    ...authenticationFeatureRoutes,

    // Услуги
    ...servicesRoutes,

    // Тарифы
    ...tariffsRoutes,

    // Портфель пользователя
    ...userPortfolioRoutes,

    // Проекты пользователя
    ...userProjectsRoutes,

    // Аккаунт пользователя
    ...userAccountRoutes,

    // Заказы пользователч
    ...userOrdersRoutes,

    {
        path: '*',
        element: (
            <HomeLayout>
                <Box m={2} mx={5}>
                    <Typography variant="h5">
                        Страница не найдена!
                    </Typography>
                    <br/>
                    <Link to="/" replace>
                        <Typography>
                            На главную
                        </Typography>
                    </Link>
                </Box>
            </HomeLayout>
        ),
    }
]
