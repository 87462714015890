import {Box, Button, Divider, Stack, TableRow, Typography} from "@mui/material";
import {KnobExpandableTable} from "src/shared/ui/KnobTable";
import {getColumns} from "src/features/UserPortfolio/ui/Portfolio/PortfolioProject/columns";
import {
    IServicePurchased,
    useServicesPurchasedQuery
} from "src/shared/services/ApiService";
import {useQueryParams} from "src/shared/utils/hooks/useQueryParams";
import {styled} from "@mui/material/styles";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {LoadingTable} from "src/features/UserPortfolio/ui/Portfolio/PortfolioProject/LoadingTable";
import {Maybe} from "src/shared/ui";


const getFormattedDate = (date: string) => format(new Date(date), 'EEEEEE, HH:mm', {
    locale: ru
})

export const EventRow = styled(Box)(({theme}) => ({
    padding: '7px 16px',
    ':nth-child(even)': {
        backgroundColor: '#FAF9F7',
    }
}));

type Props = {
    sort?: { name: string, sort: string }
}

export function PortfolioProjectServices({
                                             sort,
                                         }: Props) {
    const [params, setParams] = useQueryParams()

    const {
        data: {data} = {}, isLoading,
    } = useServicesPurchasedQuery({
        queryParams: {
            page: 1,
            perPage: 100,
            projectId: params.id as string,
        },
        params: undefined
    }, {
        enabled: !!params.id
    })

    return (
        <Box>
            <Maybe when={isLoading}>
                <LoadingTable hideHead/>
            </Maybe>

            <Maybe
                when={!!data?.length}
            >
                <KnobExpandableTable<IServicePurchased>
                    data={data || []}
                    columns={getColumns()}
                    getRowCanExpand={() => false}
                />
            </Maybe>

            <Maybe when={!isLoading && data?.length === 0}>
                <Box my={5} textAlign="center">
                    <Typography
                        variant="h6"
                        fontWeight="600"
                    >
                        Нет привязанных услуг к пакету лицензий
                    </Typography>
                </Box>
            </Maybe>
        </Box>
    )
}
