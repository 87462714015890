export default class RbacService {
    constructor(
        private permissions: Record<string, string[]>,
        private roles: string[]
    ) {}

    checkPermission(permission: string) {
        if (!this.roles || !permission) {
            return false
        }

        const permissionRoles = this.permissions[permission] || []

        return this.roles.some(role => permissionRoles.includes(role))
    }
}
