import React, {useCallback} from "react";
import {Box, Grid, Grow, Paper, Typography} from "@mui/material";
import {EntityListItem} from "src/shared/ui";
import {useNavigate} from "react-router-dom";
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import {getLimitedEnterTimeout} from "src/shared/utils";
import {IDirection} from "src/shared/services/ApiService/interfaces/IDirection";


type Props = {
    items: IDirection[]
}

export function List({
                         items
                     }: Props) {
    const navigate = useNavigate()

    const makeHandleClick = useCallback((id: string | number) => {
        return () => {
            navigate(id.toString())
        }
    }, [])

    return (
        <TransitionGroup>
            {items.map(({direction, services}) => (
                <CSSTransition
                    key={direction.id}
                    timeout={1}
                >
                    <Grid container spacing={3} sx={{
                        marginBottom: '40px'
                    }}>
                        <Grid item xs={12}>
                            <Grow
                                in
                                exit={false}
                                appear
                            >
                                <Box>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            backgroundColor: '#EFEFFD',
                                            padding: '20px'
                                        }}
                                    >
                                        <Box sx={{
                                            display: 'flex',
                                            gap: '8px'
                                        }}>
                                            {/*<Typography*/}
                                            {/*    variant="h2"*/}
                                            {/*    sx={{*/}
                                            {/*        fontSize: '28px',*/}
                                            {/*        color: '#5856D6'*/}
                                            {/*    }}*/}
                                            {/*    fontWeight="bold"*/}
                                            {/*>*/}
                                            {/*    {direction.abbreviation}*/}
                                            {/*</Typography>*/}
                                            <Typography
                                                variant="h2"
                                                sx={{
                                                    fontSize: '28px'
                                                }}
                                                fontWeight="bold"
                                            >
                                                {direction.name}
                                            </Typography>
                                        </Box>

                                        <Typography
                                            variant="h3"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 'medium',
                                                marginTop: '8px'
                                            }}
                                        >
                                            {direction.title}
                                        </Typography>
                                    </Paper>

                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 'medium',
                                            marginTop: '20px',
                                        }}
                                    >
                                        {direction.description}
                                    </Typography>
                                </Box>
                            </Grow>
                        </Grid>

                        {services?.map(({id, name, shortDescription, updated, group}, i) => (
                            <Grid
                                key={id}
                                item xs={4}
                            >
                                <Grow
                                    in
                                    exit={false}
                                    appear
                                    timeout={{
                                        enter: getLimitedEnterTimeout(300 + i * 100),
                                    }}
                                >
                                    <Box>
                                        <EntityListItem
                                            onClick={makeHandleClick(id)}
                                            title={name}
                                            description={shortDescription}
                                            descriptionLineClamp={4}
                                            tags={[{name: group.name, color: group.colorTag}]}
                                            bgColor={group.colorBackground}
                                            updated={updated}
                                            height={270}
                                        />
                                    </Box>
                                </Grow>
                            </Grid>
                        ))}
                    </Grid>
                </CSSTransition>
            ))}

        </TransitionGroup>
    )
}
