import {IServicePortfolio, SimpleListResponse} from "src/shared/services/ApiService";
import {generateQuery} from "src/shared/services/ApiService/helpers";

export type UseProjectServicePortfoliosQueryVariables = {}

export type UseProjectServicePortfoliosQueryResponse = SimpleListResponse<IServicePortfolio>

export const [useProjectServicePortfoliosQuery, getUseProjectServicePortfoliosQueryKey] = generateQuery<{
    projectId: string
},
    UseProjectServicePortfoliosQueryVariables,
    UseProjectServicePortfoliosQueryResponse>(
    params => `projects/${params.projectId}/service-portfolios`,
    'ProjectServicePortfolios'
)
