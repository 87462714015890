import React from 'react'
import {TransitionHandlerProps, useSnackbar} from "notistack";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export enum NotificationStatus {
    success = 'success',
    error = 'error',
    warning = 'warning',
    info = 'info',
}

const NotificationLabels = {
    [NotificationStatus.success]: 'Успешно',
    [NotificationStatus.error]: 'Ошибка',
    [NotificationStatus.warning]: 'Ошибка',
    [NotificationStatus.info]: 'info',
}


export type NotificationOptions = {
    message: string | React.ReactNode
    time?: null | number
    action?: JSX.Element,
    onClose?: () => void,
}

export type ShowStatusNotification = (options: NotificationOptions) => void

export type UseNotificationType = {
    showNotification: (
        status: NotificationStatus,
        options: NotificationOptions,
    ) => void
    showErrorNotification: ShowStatusNotification
    showSuccessNotification: ShowStatusNotification
    showWarningNotification: ShowStatusNotification
    showInfoNotification: ShowStatusNotification
}

const getAutoHideDuration = (status: NotificationStatus) => {
    switch (status){
        case NotificationStatus.error:
            return 5000;
        default:
            return  2000;
    }

}

export const useNotification = (): UseNotificationType => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()


    const showNotification = (
        status: NotificationStatus,
        options: NotificationOptions
    ) => {
        const {message, time, onClose} = options

        const handleClose: TransitionHandlerProps['onClose'] = (event, reason) => {
            if (reason === 'clickaway') {
                return
            }

            onClose?.()
        }
        return enqueueSnackbar(message, {
            autoHideDuration: time || getAutoHideDuration(status),
            content: (key) => (
                <Alert
                    onClose={() => closeSnackbar(key)}
                    severity={status}
                >
                    <AlertTitle>{NotificationLabels[status]}</AlertTitle>
                    {message}
                </Alert>
            ),
            onClose: handleClose
        })
    }

    return {
        showNotification,
        showErrorNotification: showNotification.bind(
            this,
            NotificationStatus.error
        ),
        showSuccessNotification: showNotification.bind(
            this,
            NotificationStatus.success
        ),
        showWarningNotification: showNotification.bind(
            this,
            NotificationStatus.warning
        ),
        showInfoNotification: showNotification.bind(
            this,
            NotificationStatus.info
        ),
    }
}
