import {generateQuery} from "src/shared/services/ApiService/helpers";
import {ListResponse} from "src/shared/services/ApiService";
import {IServiceGroup} from "src/shared/services/ApiService/interfaces/IServiceGroup";

export type UseServiceGroupsQueryVariables = {}

export type UseServiceGroupsQueryResponse = ListResponse<IServiceGroup>

export const [useServiceGroupsQuery, getUseServiceGroupsQueryKey] = generateQuery<undefined,
    UseServiceGroupsQueryVariables,
    UseServiceGroupsQueryResponse>(
    params => `groups`,
    'ServiceGroups'
)
