import {generateMutation} from "src/shared/services/ApiService/helpers";

export type UseTariffServicePurchaseMutationParams = {
    tariffId: number | string
}

export type UseTariffServicePurchaseMutationVariables = {
    services: {
        serviceId: string,
        ipQuantity: number,
        domainQuantity: number,
        activeIpQuantity: number,
        hostsQuantity: number,
        webQuantity: number,
        serviceQuantity: number,
        accountQuantity: number,
        serviceHashQuantity: number,
        skanQuantity: number,
        projectQuantity: number,
        scanFlowQuantity: number,
        userInterfaceQuantity: number,
        projectDevelopmentQuantity: number,
        onlineCourseUser: number,
        onlineCtfUser: number,
        requestQuantity: number,
        professionalHours: number
    }[]

}

export type UseTariffServicePurchaseMutationResponse = {
}


export const useTariffServicePurchaseMutation = generateMutation<
    UseTariffServicePurchaseMutationParams,
    UseTariffServicePurchaseMutationVariables,
    UseTariffServicePurchaseMutationResponse>(
    'post',
    params => `tariffs/${params!.tariffId}/services/purchase`
)
