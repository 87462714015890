import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function SuccessCircleIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="74" height="74" viewBox="0 0 74 74" fill="none"
            {...props}
            style={{
                fill: 'none'
            }}
        >
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M37.0002 73.9231C57.3923 73.9231 73.9233 57.392 73.9233 37C73.9233 16.6079 57.3923 0.0769043 37.0002 0.0769043C16.6082 0.0769043 0.0771484 16.6079 0.0771484 37C0.0771484 57.392 16.6082 73.9231 37.0002 73.9231ZM49.3292 21.6454C48.8836 21.7316 48.4525 21.9903 48.1794 22.3927L33.808 43.605L27.1396 36.9941C26.4498 36.3043 25.3648 36.3043 24.6677 36.9941L22.1384 39.5235C21.4414 40.2133 21.4414 41.363 22.1384 42.0529L32.3134 52.2278C32.8882 52.7955 33.808 53.2051 34.6128 53.2051C35.4176 53.2051 36.2152 52.7021 36.7398 51.9404L54.043 26.4167C54.5963 25.6119 54.3951 24.4909 53.5831 23.9448L50.6513 21.9328C50.2489 21.6598 49.7747 21.5592 49.3292 21.6454Z"
                  fill="#34C759"/>
        </SvgIcon>
    );
}
