import {generateMutation} from "src/shared/services/ApiService/helpers";


export type UsePurchasedEventDetailsDeleteMutationVariables = {}

export type UsePurchasedEventDetailsDeleteMutationResponse = {}


export const usePurchasedEventDetailsDeleteMutation = generateMutation<{
    id: string | number
},
    UsePurchasedEventDetailsDeleteMutationVariables,
    UsePurchasedEventDetailsDeleteMutationResponse>(
    'delete',
    params => `purchased-event-details/orders/${params?.id}`
)
