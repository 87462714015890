import {IServiceListItem, ListResponse} from "src/shared/services/ApiService"
import {generateInfiniteQuery} from "src/shared/services/ApiService/helpers";

export type UseServicesInfinityQueryVariables = {
    groups?: string[],
    boolFilters?: string[],
    tariffId?: string,
    page?: number,
    perPage?: number
}

export type UseServicesInfinityQueryResponse = ListResponse<IServiceListItem>

export const [useServicesInfinityQuery, getUseServicesInfinityQueryKey] = generateInfiniteQuery<undefined,
    UseServicesInfinityQueryVariables,
    UseServicesInfinityQueryResponse>(
    params => `services`,
    'services-infinity'
)
