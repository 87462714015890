import {Box, Button, Stack, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import {useNotification} from "src/shared/providers";
import {useProjectsCreateMutation, useProjectsUpdateMutation} from "src/shared/services/ApiService";
import {NotificationStatus} from "src/shared/providers/NotificationProvider/useNotification";
import {useQueryClient} from "@tanstack/react-query";
import LoadingButton from '@mui/lab/LoadingButton';


type Props = {
    id?: string,
    onSuccess: () => void,
    initialFormValues?: FormValues
}


type FormValues = {
    name: string,
    description: string,
    shortDescription: string,
    url: string,
}

export function ProjectCreateModal({
                                       id,
                                       onSuccess,
                                       initialFormValues = {
                                           name: '',
                                           description: '',
                                           shortDescription: '',
                                           url: ''
                                       }
                                   }: Props) {
    const queryClient = useQueryClient()
    const {showNotification} = useNotification()
    const {mutateAsync: createProject} = useProjectsCreateMutation({
        onSuccess: () => {
            onSuccess()
            showNotification(NotificationStatus.success, {
                message: 'Пакет лицензий создан!'
            })
        },
        onError: () => {
            showNotification(NotificationStatus.error, {
                message: 'Не удалось создать пакет лицензий!'
            })
        }
    })

    const {mutateAsync: updateProject} = useProjectsUpdateMutation({
        onSuccess: () => {
            onSuccess()
            showNotification(NotificationStatus.success, {
                message: 'Пакет лицензий обновлен!'
            })
        },
        onError: () => {
            showNotification(NotificationStatus.error, {
                message: 'Не удалось обновить пакет лицензий!'
            })
        }
    })
    const {
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
    } = useFormik<FormValues>({
        initialValues: initialFormValues,
        onSubmit: async (v) => {
            if (id) {
                await updateProject({
                    params: {
                        projectId: id,
                    },
                    body: v
                })
            } else {
                await createProject({
                    body: v
                })
            }

            return queryClient.invalidateQueries(['OrganizationProjects'])
        }
    })

    return (
        <Box>
            <Stack spacing={3}>
                <Box>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginBottom: '12px'
                        }}
                    >
                        Краткое название
                    </Typography>
                    <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Наименование"
                        fullWidth
                    />
                </Box>


                <Box>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginBottom: '12px'
                        }}
                    >
                        Краткое описание
                    </Typography>

                    <TextField
                        name="shortDescription"
                        value={values.shortDescription}
                        multiline
                        minRows={2}
                        maxRows={6}
                        onChange={handleChange}
                        fullWidth
                        placeholder="Введите краткое описание пакета лицензий"
                    />
                </Box>

                <Box>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginBottom: '12px'
                        }}
                    >
                        Описание
                    </Typography>

                    <TextField
                        name="description"
                        value={values.description}
                        multiline
                        minRows={4}
                        maxRows={6}
                        onChange={handleChange}
                        fullWidth
                        placeholder="Введите описание пакета лицензий"
                    />
                </Box>

                <Box>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginBottom: '12px'
                        }}
                    >
                        Ссылка
                    </Typography>

                    <TextField
                        name="url"
                        value={values.url}
                        onChange={handleChange}
                        placeholder="Ссылка на пакет лицензий"
                        fullWidth
                    />
                </Box>


                <Box pb={2}>
                    <LoadingButton
                        loading={isSubmitting}
                        disabled={!values.name || !values.description || !values.shortDescription}
                        size="large"
                        variant="contained"
                        onClick={() => handleSubmit()}
                        fullWidth
                        sx={{
                            marginTop: '20px'
                        }}
                    >
                        {id ? 'Обновить пакет лицензий' : 'Создать пакет лицензий'}
                    </LoadingButton>
                </Box>
            </Stack>
        </Box>
    )
}
