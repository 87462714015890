import React from 'react'
import {
    Box,
    Container,
    IconButton, InputAdornment,
    OutlinedInputProps,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar
} from "@mui/material";
import ToggleNav from "src/shared/ui/Layouts/HomeLayout/ToggleNav";
import SearchIcon from '@mui/icons-material/Search';
import ProfileMenu from "src/shared/ui/Layouts/common/ProfileMenu";
import {Link} from "react-router-dom";
import {AppBar} from '../common/AppBar'

type Props = {
    children: React.ReactNode
}

export function HomeLayout({
                               children,
                           }: Props) {
    return (
        <Box sx={{
            //display: 'flex',
        }}>
            <AppBar>
                <ToggleNav/>
            </AppBar>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 3,
                    pt: 2
                }}
            >
                <Container maxWidth="xl">
                    {children}
                </Container>
            </Box>
        </Box>
    )
}
