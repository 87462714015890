import React, {useMemo} from 'react'
import {Fade, Grid, Paper, Grow} from "@mui/material";
import {slice} from "lodash";
import {EntityListItem, ListLoader, Maybe} from "src/shared/ui";
import {MOCK_SERVICES, CARD_STYLES_BY_CATEGORY} from "src/features/Services/ui/Services/ServiceList/mock";
import {useDirectionsQuery, useServicesQuery} from "src/shared/services/ApiService";
import {useNavigate, useParams} from "react-router-dom";
import {TransitionGroup} from 'react-transition-group'
import {getLimitedEnterTimeout} from "src/shared/utils";


export default function AnotherServices() {
    const navigate = useNavigate()
    const params = useParams()

    const {
        data: {data} = {}
    } = useDirectionsQuery({
        queryParams: {},
        params: undefined,
    })

    const services = useMemo(() => {
        if (!data) return []

        const direction = data.find(({services}) => services.some((s) => s.id === params.id))

        return direction?.services?.filter(s => s.id !== params.id) || []
    }, [data, params?.id])

    return (
        <Paper
            variant="outlined"
            sx={{
                padding: '28px 32px',
                maxHeight: 'calc(100vh - 170px)',
                overflow: 'scroll'
            }}
        >
            <Maybe
                when={!!data?.length}
                fallback={<ListLoader xs={12} count={3}/>}
            >
                <TransitionGroup>
                    <Grid container spacing={3}>
                        {services?.map(({id, name, shortDescription, group}, i) => (
                            <Grow
                                in
                                exit={false}
                                appear
                                timeout={{
                                    enter: getLimitedEnterTimeout(300 + i * 100),
                                }}
                            >
                                <Grid
                                    item xs={12}
                                    key={id}
                                >
                                    <EntityListItem
                                        onClick={() => navigate(`/solutions/${id}`)}
                                        title={name}
                                        description={shortDescription}
                                        tags={[{name: group.name, color: group.colorTag}]}
                                        bgColor={group.colorBackground}
                                    />
                                </Grid>
                            </Grow>
                        ))}
                    </Grid>
                </TransitionGroup>
            </Maybe>
        </Paper>
    )
}
