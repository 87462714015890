import {RouteObject} from "react-router-dom";
import {BackBlock, Home, Profile} from "../ui";
import {BaseLayout, RbacGuardLayout} from "src/shared/ui";
import {PermissionsEnum} from "src/shared/services/RbacServices";

export const routes: RouteObject[] = [
    {
        path: '/user/account',
        element: (
            <RbacGuardLayout permission={PermissionsEnum.CAN_ACCESS}/>
        ),
        children: [
            {
                path: '',
                element: (
                    <BaseLayout>
                        <Home/>
                    </BaseLayout>
                )
            },
            {
                path: 'profile',
                element: (
                    <BaseLayout
                        slotTop={<BackBlock/>}
                        backgroundColor="white"
                    >
                        <Profile/>
                    </BaseLayout>
                )
            },
        ]
    },
]
