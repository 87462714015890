import {IServiceListItem, ListResponse} from "src/shared/services/ApiService"
import {generateQuery} from "src/shared/services/ApiService/helpers";

export type UseServicesQueryVariables = {
    tariffId?: string,
    page: number,
    perPage: number
}

export type UseServicesQueryResponse = ListResponse<IServiceListItem>

export const [useServicesQuery, getUseServicesQueryKey] = generateQuery<undefined,
    UseServicesQueryVariables,
    UseServicesQueryResponse>(
    params => `services`,
    'services'
)
