import React from 'react'
import {Alert, Box, Typography} from "@mui/material";
import {ITariff} from "src/shared/services/ApiService";
import DOMPurify from "dompurify";


type Props = {
    data: ITariff
}

export default function TariffCardBody({
    data: {
        description
    }
                                       }: Props) {
    return (
        <Box>
            <Box mt={4.5}>
                <Typography
                    variant="h5"
                    fontWeight={700}
                    sx={{
                        marginBottom: '12px'
                    }}
                >
                    Где и зачем используется данный Тариф
                </Typography>

                <Typography
                    fontWeight={500}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(description),
                    }}
                />
            </Box>
        </Box>
    )
}
