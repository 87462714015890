import {EntityListItem, Maybe} from "src/shared/ui";
import {range} from 'lodash'
import {Box, Grid, Paper, Skeleton, Typography} from "@mui/material";
import React from "react";

type Props = {
    count: number
    xs?: number,
    showHeader?: boolean
    hideTag?: boolean
}

export function ListLoader({
                               count,
                               xs = 4,
                               showHeader = false,
                               hideTag = false
                           }: Props) {
    return (
        <Grid container spacing={3}>
            <Maybe when={showHeader}>
                <Grid item xs={12}>
                    <Box>
                        <Paper
                            elevation={0}
                            sx={{
                                backgroundColor: '#EFEFFD',
                                padding: '20px'
                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    fontSize: '28px'
                                }}
                                fontWeight="bold"
                            >
                                <Skeleton width="60%"/>
                            </Typography>

                            <Typography
                                variant="h3"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 'medium'
                                }}
                            >
                                <Skeleton width="50%"/>
                            </Typography>
                        </Paper>

                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 'medium',
                                marginTop: '20px',
                                marginBottom: '10px'
                            }}
                        >
                            <Skeleton width="90%"/>
                            <Skeleton width="70%"/>
                        </Typography>
                    </Box>
                </Grid>
            </Maybe>

            {range(count).map((i) => (
                <Grid
                    item
                    xs={xs}
                    key={i}
                >
                    <EntityListItem
                        title={<Skeleton width="60%"/>}
                        description={(
                            <>
                                <Skeleton width="90%"/>
                                <Skeleton width="70%"/>
                            </>
                        )}
                        caption={<Skeleton width="30%"/>}
                        tags={hideTag ? [] : [{name: <Skeleton width={50}/>, color: '#A6A6A6'}]}
                        bgColor="#F1F1F1"
                    />
                </Grid>
            ))}
        </Grid>
    )
}
