import {HintTitle} from "src/features/Services/ui/Services/ServiceFilters/HintTitle";
import React from "react";
import {Box, Checkbox, FormControlLabel, FormGroup, Skeleton, Stack} from "@mui/material";
import {Maybe, ShowMore} from "src/shared/ui";
import {useServiceGroupsQuery, useServiceParamsQuery} from "src/shared/services/ApiService";
import {FormikHandlers} from "formik";
import {TransitionGroup} from 'react-transition-group'


type Props = {
    value: string[],
    onChange: FormikHandlers['handleChange']
}

export function ServiceGroups({
                                  value,
                                  onChange
                              }: Props) {
    const {
        data: {data} = {}, isLoading
    } = useServiceGroupsQuery({
        params: undefined,
        queryParams: {}
    })

    const makeHandleChange = (name: string) => (_: any, checked: boolean) => {
        onChange({
            target: {
                name: 'groups',
                value: checked ? [...value, name] : value.filter((v) => v !== name),
            }
        })
    }

    return (
        <Box>
            <HintTitle
                title="Класс решения"
            />

            <Maybe
                when={!isLoading}
                fallback={(
                    <Stack>
                        <FormControlLabel
                            sx={{
                                '& .MuiTypography-root': {
                                    fontSize: '14px'
                                }
                            }}
                            control={<Checkbox disabled/>}
                            label={<Skeleton width={200}/>}
                            value="1"
                        />
                        <FormControlLabel
                            sx={{
                                '& .MuiTypography-root': {
                                    fontSize: '14px',
                                }
                            }}
                            control={<Checkbox disabled/>}
                            label={<Skeleton width={130}/>}
                            value="2"
                        />

                        <FormControlLabel
                            sx={{
                                '& .MuiTypography-root': {
                                    fontSize: '14px'
                                }
                            }}
                            control={<Checkbox disabled/>}
                            label={<Skeleton width={170}/>}
                            value="3"
                        />
                    </Stack>
                )}
            >
                <FormGroup>
                    <ShowMore
                        items={data?.map(({tag, name}) => (
                            <FormControlLabel
                                sx={{
                                    '& .MuiTypography-root': {
                                        fontSize: '14px',
                                        fontWeight: 500
                                    }
                                }}
                                control={<Checkbox
                                    onChange={makeHandleChange(tag)}
                                    checked={value.includes(tag)}
                                />}
                                label={name}
                                value={tag}
                            />
                        )) || []}
                        max={6}
                    />
                </FormGroup>
            </Maybe>

        </Box>
    )
}
