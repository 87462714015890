import React from 'react'
import {Outlet} from 'react-router-dom'
import {Box} from "@mui/material";

export function Layout(){
    return (
        <Box>
            <Outlet/>
        </Box>
    )
}
