import {
    Box,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    OutlinedInputProps,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {useFormik} from "formik";
import * as Yup from 'yup'
import {AuthTextField} from "src/features/Authentication/ui/SignIn/styled";
import {useAuthContext} from "src/shared/providers";
import {useNavigate} from "react-router-dom";
import {useUserLoginMutation} from "src/shared/services/ApiService";
import {FontFamily} from "src/shared/utils/font";


type FormValues = {
    email: string
    password: string
}

const initialValues: FormValues = {
    email: '',
    password: '',
}

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Введите логин'),
    password: Yup.string()
        .required('Введите пароль')
        .min(5, 'Минимальное количество символов: 5'),
})


export function SignIn() {
    const {mutateAsync: loginMutation} = useUserLoginMutation({
        onSuccess: (data) => {
            onSetToken(data.token)
            navigate('/')
        }
    })
    const {onSetToken,} = useAuthContext()
    const navigate = useNavigate()

    const {
        values,
        isSubmitting,
        handleChange,
        handleSubmit,
        errors,
    } = useFormik<FormValues>({
        initialValues,
        onSubmit: async (v: FormValues) => {
            try {
                await loginMutation({
                    body: {
                        email: v.email,
                        password: v.password
                    }
                })
                // await loginMutation(v as UseUserLoginMutationVariables)
            } catch {
            }
        },
        //validationSchema,
    })


    return (
        <Box
            sx={{
                backgroundColor: '#EFEFFD',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Container
                maxWidth="lg"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        height: '475px',
                        borderRadius: '16px'
                    }}>
                    <Grid container sx={{
                        height: '100%',
                        borderRadius: '16px',
                        border: '1px solid #DEDEDE',
                        boxShadow: '12px 24px 50px -10px rgba(0, 0, 0, 0.16)'

                    }}>
                        <Grid item xs={6}>
                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                backgroundColor: '#EFEFFD',
                                borderTopLeftRadius: '16px',
                                borderBottomLeftRadius: '16px',
                            }}>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '40px',
                                        left: '40px'
                                    }}
                                >
                                    <img
                                        width="150px"
                                        src="/logo.svg"
                                    />
                                </Box>
                                <Box>
                                    <img src="/illustrations/login.svg"/>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box p={5}>
                                <Typography
                                    fontFamily={FontFamily.ibmMono}
                                    variant="h5"
                                    fontWeight="bold"
                                    textAlign="center"
                                >
                                    Вход
                                </Typography>

                                <Box
                                    mt={4}
                                    component="form"
                                    onSubmit={(e: any) => {
                                        e.preventDefault()
                                        handleSubmit()
                                    }}
                                >
                                    <AuthTextField
                                        label="Логин"
                                        autoComplete="email"
                                        name="email"
                                        onChange={handleChange}
                                        fullWidth
                                        variant="filled"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                borderBottomLeftRadius: 0,
                                                borderBottomRightRadius: 0,
                                            },
                                        }}
                                    />

                                    <AuthTextField
                                        label="Пароль"
                                        autoComplete="password"
                                        name="password"
                                        onChange={handleChange}
                                        fullWidth
                                        variant="filled"
                                        type="password"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0,
                                            },
                                        }}
                                    />


                                    {/*<FormControlLabel control={<Checkbox defaultChecked/>} label="Запомнить меня" sx={{*/}
                                    {/*    marginTop: '8px'*/}
                                    {/*}}/>*/}


                                    <Box pt={2}>
                                        <LoadingButton
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            loading={isSubmitting}
                                        >
                                            Войти
                                        </LoadingButton>
                                    </Box>
                                </Box>

                                <Box mt={3} textAlign="center">
                                    <Typography variant="caption" color="textSecondary">
                                        © КНОБ Технолоджис”, 2022
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    )
}
