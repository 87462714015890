import React, {useState, useEffect} from 'react'
import { useIsFetching } from "@tanstack/react-query"

import LinearProgress from '@mui/material/LinearProgress';
import Box from "@mui/material/Box";

const GlobalLoadingIndicator = () => {
    const [progress, setProgress] = useState(0)
    const isFetching = useIsFetching()

    useEffect(() => {
        // @ts-ignore
        let interval = null

        if(isFetching){
            setProgress(0)
            interval = setInterval(
                () => setProgress(progress => Math.min(progress+10, 70)),
                100
            )
        }

        return () => {
            // @ts-ignore
            if(interval){
                // @ts-ignore
                clearInterval(interval)

                setTimeout(()=>{
                    setProgress(100)
                }, 100)

                setTimeout(()=>{
                    setProgress(101)
                }, 500)
            }


        };
    }, [!!isFetching]);

    if (progress === 101) {
        return null
    }

    return (
        <Box sx={{
            position: 'absolute',
            width: '100%',
            zIndex: 3,
            top: 0,
            left: 0,
        }}>
            <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                    backgroundColor: 'transparent'
                }}
            />
        </Box>
    )
}

export default GlobalLoadingIndicator
