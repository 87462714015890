import React, {Suspense, useEffect} from 'react'
import {RouteObject, useLocation} from 'react-router-dom'
import {useRoutes} from 'react-router-dom'


type PropsType = {
    routes: RouteObject[]
    children?: React.ReactNode
}

const ScrollToTop: React.FC = () => {
    const {pathname} = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return null
}

function RouterProvider({children, routes}: PropsType) {
    const routing = useRoutes(routes)

    return (
        <Suspense fallback={"loading..."}>
            <ScrollToTop/>
            {routing}
            {children}
        </Suspense>
    )
}

export {RouterProvider}
