import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function HeartIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="18" height="17" viewBox="0 0 18 17" fill="none"
            {...props}
        >
            <mask id="mask0_792_1355" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="18"
                  height="17">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.666992 0.499939H17.7274V16.7508H0.666992V0.499939Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask0_792_1355)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2.18696 8.10254C3.35529 11.7375 7.97112 14.6767 9.19779 15.4042C10.4286 14.6692 15.0778 11.6975 16.2086 8.10587C16.9511 5.7842 16.262 2.84337 13.5236 1.96087C12.197 1.53504 10.6495 1.7942 9.58112 2.62087C9.35779 2.79254 9.04779 2.79587 8.82279 2.62587C7.69112 1.77504 6.21279 1.52587 4.86529 1.96087C2.13112 2.84254 1.44446 5.78337 2.18696 8.10254ZM9.19862 16.7509C9.09529 16.7509 8.99279 16.7259 8.89946 16.675C8.63862 16.5325 2.49446 13.1459 0.996956 8.4842C0.996123 8.4842 0.996123 8.48337 0.996123 8.48337C0.0561227 5.54837 1.10279 1.86004 4.48196 0.770871C6.06862 0.257537 7.79779 0.483371 9.19612 1.36587C10.5511 0.509204 12.3511 0.272537 13.9061 0.770871C17.2886 1.8617 18.3386 5.5492 17.3995 8.48337C15.9503 13.0917 9.76112 16.5292 9.49862 16.6734C9.40529 16.725 9.30196 16.7509 9.19862 16.7509Z"
                      fill="#2C2C2C"/>
            </g>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.1282 6.85412C13.8057 6.85412 13.5324 6.60662 13.5057 6.27996C13.4507 5.59496 12.9924 5.01662 12.3399 4.80579C12.0107 4.69912 11.8307 4.34662 11.9366 4.01912C12.0441 3.69079 12.3932 3.51246 12.7232 3.61579C13.8591 3.98329 14.6549 4.98912 14.7524 6.17829C14.7799 6.52245 14.5241 6.82412 14.1799 6.85162C14.1624 6.85329 14.1457 6.85412 14.1282 6.85412Z"
                  fill="#2C2C2C"/>
        </SvgIcon>
    );
}
