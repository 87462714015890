import React, {useEffect} from 'react'
import {Box, Grid, Typography} from "@mui/material";
import {ServiceFilters} from "src/features/Services/ui/Services/ServiceFilters";
import ServiceList from "src/features/Services/ui/Services/ServiceList";
import {IServiceListItem, useDirectionsQuery, useServicesInfinityQuery} from "src/shared/services/ApiService";
import {reduceInfinityResultData} from "src/shared/utils";
import {Maybe, InfinityLoader, ListLoader} from "src/shared/ui";
import {useInfiniteScroll} from "src/shared/utils";
import {useFormikWithQueryString} from "src/shared/utils/hooks/useFormikWithQueryString";
import {IDirection} from "src/shared/services/ApiService/interfaces/IDirection";

export type ServicesFormValues = {
    directions: string[]
    groups: string[],
}

export function Services() {
    const {
        submittedValues,
        handleChange,
        values,
        handleSubmit
    } = useFormikWithQueryString<ServicesFormValues>({
        initialValues: {
            directions: [],
            groups: []
        },
        onSubmit: () => {
        }
    })

    const {
        data: {data} = {},
        isLoading,
    } = useDirectionsQuery({
        params: undefined,
        queryParams: {
            directions: submittedValues?.directions,
            groups: submittedValues?.groups
        }
    }, {
        staleTime: 30 * 1000,
    })

    const handleChangeWrapper = (e: any) => {
        handleSubmit()
        return handleChange(e)
    }

    return (
        <Grid container columnSpacing={7.5}>
            <Grid item xs={3}>
                <ServiceFilters
                    values={values}
                    onChange={handleChangeWrapper}
                />
            </Grid>

            <Grid item xs={9}>
                <Maybe when={isLoading}>
                    <ListLoader count={5} showHeader/>
                </Maybe>

                <Maybe when={!isLoading && data?.length === 0}>
                    <Box mt={10} mb={2} textAlign="center">
                        <Typography
                            variant="h5"
                            fontWeight="600"
                        >
                            Список решений по заданному фильтру отсутствует!
                        </Typography>
                    </Box>
                </Maybe>

                <Maybe
                    when={!!data?.length}
                >
                    <ServiceList
                        items={data as IDirection[]}
                    />
                </Maybe>
            </Grid>
        </Grid>
    )
}
