import {RouteObject} from "react-router-dom";
import {Order, Orders} from "../ui";
import {BaseLayout, RbacGuardLayout} from "src/shared/ui";
import {PermissionsEnum} from "src/shared/services/RbacServices";

export const routes: RouteObject[] = [
    {
        path: '/user/orders',
        element: (
            <BaseLayout>
                <RbacGuardLayout permission={PermissionsEnum.CAN_ACCESS}/>
            </BaseLayout>
        ),
        children: [
            {
                path: '',
                element: <Orders/>
            },
            {
                path: ':id',
                element: <Order/>
            },
        ]
    },
]
