import {ReactNode, useState, Fragment} from "react";
import {
    ExpandedState,
    useReactTable,
    getCoreRowModel,
    getExpandedRowModel,
    ColumnDef,
    flexRender,
    Row,
} from '@tanstack/react-table'
import {
    Box,
    Card, Collapse, Fade, Grow,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {styled} from "@mui/material/styles";


type Props<T> = {
    hideHead?: boolean,
    data: T[]
    columns: ColumnDef<T>[],
    getRowCanExpand: () => boolean,
    renderSubComponent?: (a: { row: Row<T> }) => ReactNode
}

export const TableRowStyled = styled(TableRow)(({theme}) => ({
}));

export const TableHeaderCellStyled = styled(TableCell)(() => ({
    fontSize: '14px',
    fontWeight: '700',
    borderBottom: 'none',
    ':not(:first-child)': {
        padding: '16px 8px',
    }
}));

export const TableCellStyled = styled(TableCell)(({theme}) => ({
    borderBottom: 'none',
    padding: 0,
    // padding: '16px 16px',
    // border: `1px solid  #DEDEDE`,
    // borderStyle: 'solid none',
    // '&:first-child': {
    //     padding: '16px 16px',
    //     borderColor: '#DEDEDE',
    //     borderWidth: '1px',
    //     borderLeftStyle: 'solid',
    //     borderTopLeftRadius: '12px',
    //     borderBottomLeftRadius: '12px'
    // },
    // '&:last-child': {
    //     padding: '16px 16px',
    //     borderColor: '#DEDEDE',
    //     borderWidth: '1px',
    //     borderRightStyle: 'solid',
    //     borderTopRightRadius: '12px',
    //     borderBottomRightRadius: '12px'
    // }
}));

// @ts-ignore
export const TableCellContentStyled = styled(Box)(({theme, index, len, expanded}) => ({
    margin: '6px 0',
    padding: '16px 8px',
    border: `1px solid  #DEDEDE`,
    borderStyle: 'solid none',
    height: '58px',
    backgroundColor: 'white',
    ...(index === 0 ? ({
        padding: '16px 8px',
        paddingLeft: '16px',
        borderColor: '#DEDEDE',
        borderWidth: '1px',
        borderLeftStyle: 'solid',
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px'
    }) : {}),
    ...(index === (len - 1) ? ({
        padding: '16px 8px',
        paddingRight: '16px',
        borderColor: '#DEDEDE',
        borderWidth: '1px',
        borderRightStyle: 'solid',
        borderTopRightRadius: '12px',
        borderBottomRightRadius: '12px'
    }) : {}),
    ...(expanded ? ({
        marginBottom: '-10px'
    }) : ({}))
}));

export function KnobExpandableTable<T>({
                                           data,
                                           columns,
                                           getRowCanExpand,
                                           renderSubComponent,
                                           hideHead = false
                                       }: Props<T>) {
    const [expanded, setExpanded] = useState<ExpandedState>({})

    const table = useReactTable<T>({
        data,
        columns,
        getRowCanExpand,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
    })

    return (
        <TableContainer sx={{
            width: "100%",
            overflowX: "auto"
        }}>
            <div className="h-2"/>
            <Table sx={{
                borderCollapse: 'separate',
                //borderSpacing: '0 12px',
                //width: table.getTotalSize()
            }}>
                <TableHead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <TableRow key={headerGroup.id} sx={{
                            visibility: hideHead ? 'hidden' : 'visible'
                        }}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <TableHeaderCellStyled
                                        {...{
                                            key: header.id,
                                            colSpan: header.colSpan,
                                            style: {
                                                width: header.getSize(),
                                            },
                                        }}
                                    >
                                        {(header.isPlaceholder) ? null : (
                                            <div>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                            </div>
                                        )}
                                    </TableHeaderCellStyled>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHead>

                <TableBody>
                    {table.getRowModel().rows.map(row => {
                        const len = row.getVisibleCells().length

                        return (
                            <Fragment key={row.id}>
                                <TableRowStyled>
                                    {/* first row is a normal row */}
                                    {row.getVisibleCells().map((cell, index) => {
                                        return (
                                            <TableCellStyled
                                                {...{
                                                    key: cell.id,
                                                    style: {
                                                        width: cell.column.getSize(),
                                                    },
                                                }}
                                            >
                                                <TableCellContentStyled
                                                    // @ts-ignore
                                                    len={len}
                                                    // @ts-ignore
                                                    index={index}
                                                    // @ts-ignore
                                                    expanded={row.getIsExpanded()}
                                                    sx={{
                                                        // @ts-ignore
                                                        ...(cell.column.columnDef.cellSx || {})
                                                    }}
                                                >
                                                    <Box
                                                        {...{
                                                            key: cell.id,
                                                            style: {
                                                                width: cell.column.getSize(),
                                                            },
                                                        }}
                                                        sx={{
                                                            // @ts-ignore
                                                            ...(cell.column.columnDef.sx || {})
                                                        }}
                                                    >
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </Box>
                                                </TableCellContentStyled>
                                            </TableCellStyled>
                                        )
                                    })}
                                </TableRowStyled>
                                {row.getIsExpanded() && (
                                    <TableRowStyled>
                                        <TableCellStyled
                                            colSpan={row.getVisibleCells().length}>
                                            <Fade in timeout={500}>
                                                <Box
                                                    sx={{
                                                        border: `1px solid  #DEDEDE`,
                                                        borderTop: 'none',
                                                        borderRadius: '12px',
                                                        borderTopLeftRadius: 0,
                                                        borderTopRightRadius: 0,
                                                        marginBottom: '6px'
                                                    }}
                                                >
                                                    {renderSubComponent?.({row})}
                                                </Box>
                                            </Fade>
                                        </TableCellStyled>
                                    </TableRowStyled>
                                )}
                            </Fragment>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
