import * as React from 'react';
import {styled} from '@mui/material/styles';
import {Box, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {useState} from "react";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    '& .MuiToggleButtonGroup-grouped': {
        minWidth: '133px',
        height: '40px',
        margin: '5px 8px',
        border: 0,
        textTransform: 'none',
        padding: '12px 28px',
        color: '#2C2C2C',
        fontWeight: '700',
        '&.Mui-disabled': {
            border: 0,
        },
        '&:hover': {
            backgroundColor: '#e6e9ee',
        },
        '&.Mui-selected': {
            backgroundColor: '#0048D3',
            boxShadow: '0px 8px 16px -5px rgba(3, 63, 180, 0.4)',
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#003eb7',
            },
        },
        '&:not(:first-of-type)': {
            borderRadius: '50px',
        },
        '&:first-of-type': {
            borderRadius: '50px',
        },
    },
}));

const linkStyle = {
    textDecoration: 'none',
}

export default function ToggleNav() {
    const location = useLocation()
    const value = React.useMemo(() => {
        if (location.pathname.startsWith('/user/projects')) {
            return 'projects'
        }

        if (location.pathname.startsWith('/user/portfolio')) {
            return 'portfolio'
        }

        if (location.pathname.startsWith('/user/orders')) {
            return 'orders'
        }

        return 'solutions'
    }, [location.pathname])

    return (
        <Box sx={{
            border: '1px solid #DEDEDE',
            borderRadius: '50px'
        }}>
            <StyledToggleButtonGroup
                value={value}
            >
                <ToggleButton
                    component={Link}
                    value="solutions"
                    to="/solutions"
                    style={linkStyle}
                >
                    Решения
                </ToggleButton>

                <ToggleButton
                    component={Link}
                    value="orders"
                    to="/user/orders"
                    style={linkStyle}
                >
                    Заказы
                </ToggleButton>

                <ToggleButton
                    component={Link}
                    value="portfolio"
                    to="/user/portfolio"
                    style={linkStyle}
                >
                    Портфель
                </ToggleButton>

                <ToggleButton
                    component={Link}
                    value="projects"
                    to="/user/projects"
                    style={linkStyle}
                >
                    Пакеты лицензий
                </ToggleButton>
            </StyledToggleButtonGroup>
        </Box>
    )
}
