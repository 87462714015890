import {generateMutation} from "src/shared/services/ApiService/helpers";


export type UseProjectsCreateMutationVariables = {
    name: string
    description: string,
    shortDescription: string,
    url?: string
}

export type UseProjectsCreateMutationResponse = {}


export const useProjectsCreateMutation = generateMutation<
    undefined,
    UseProjectsCreateMutationVariables,
    UseProjectsCreateMutationResponse
    >(
    'post',
    params => 'projects'
)
