import React from "react";

export default function usePopoverAnchor(title: string) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? title : undefined;

    return {
        anchorEl,
        id,
        handleOpen,
        handleClose,
        open,
    }
}
