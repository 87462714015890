import {generateQuery} from "src/shared/services/ApiService/helpers";
import {EntityResponse, IUserMe, ListResponse} from "src/shared/services/ApiService";
import {IServiceGroup} from "src/shared/services/ApiService/interfaces/IServiceGroup";

export type UseUsersMeQueryVariables = {}

export type UseUsersMeQueryResponse = EntityResponse<IUserMe>

export const [useUsersMeQuery, getUseUsersMeQueryKey] = generateQuery<undefined,
    UseUsersMeQueryVariables,
    UseUsersMeQueryResponse>(
    params => `users/me`,
    'UsersMe'
)
