import React, {useRef, useState, useEffect} from 'react'

import debounce from 'lodash/debounce'
import {Box, BoxProps, Fade, IconButton, IconButtonProps} from "@mui/material";

import NavigateNext from "@mui/icons-material/NavigateNext";
import NavigateBefore from "@mui/icons-material/NavigateBefore";

import {SCROLL_STEP} from './constants'
import {styled} from "@mui/material/styles";

type Props = BoxProps & {
    children: React.ReactNode
    onChangeScrollPosition?: (position: number) => void
    scrollStep?: number
    rightButtonOffset?: number
    leftButtonOffset?: number
    smallNavigateBtn?: boolean,
    initialScrollEnd?: boolean,
}

interface NavigateButtonProps extends IconButtonProps {
    leftOffset?: number,
    rightOffset?: number
    isLeft?: boolean
    isRight?: boolean
}

const NavigateButton = styled((props: NavigateButtonProps) => {
    const {leftOffset, rightOffset, isLeft, isRight, ...other} = props;
    return <IconButton {...other} />;
})(({theme, leftOffset, rightOffset, isLeft, isRight}) => ({
    position: 'absolute',
    top: '50%',
    //width: 40,
    //height: 40,
    backgroundColor: `${theme.palette.background.paper} !important`,
    borderRadius: '50%',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    border: `1px solid ${theme.palette.divider}`,
    transform: 'translateY(-50%)',
    transition: 'all .2s ease',
    zIndex: 1,
    ...(isLeft ? {
        left: leftOffset,
        '&:hover': {
            transform: 'translate(2px, -50%)',
        },
    } : {}),
    ...(isRight ? {
        right: rightOffset,
        '&:hover': {
            transform: 'translate(-2px, -50%)',
        },
    } : {}),
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
    }
}));

export const HorizontalArrowsScroller: React.FC<Props> = ({
                                                              children,
                                                              onChangeScrollPosition,
                                                              scrollStep = SCROLL_STEP,
                                                              leftButtonOffset = 5,
                                                              rightButtonOffset = 5,
                                                              smallNavigateBtn,
                                                              initialScrollEnd = true,
                                                              ...boxProps
                                                          }) => {
    const endScrolled = useRef<boolean>(!initialScrollEnd)
    const trackRef = useRef<HTMLElement>(null)
    const [isChildrenInTransition, setIsChildrenInTransition] = useState(false)
    const [{scrollLeft, scrollWidth, offsetWidth}, setScrollParams] =
        useState({
            scrollLeft: 0,
            scrollWidth: 0,
            offsetWidth: 0,
        })

    const isScrollLeftVisible = !!scrollLeft
    const isScrollRightVisible =
        offsetWidth + Math.ceil(scrollLeft) < scrollWidth - 1

    const handleClickLeft = () => {
        onChangeScrollPosition?.(scrollLeft - scrollStep)
        trackRef?.current?.scrollTo(scrollLeft - scrollStep, 0)
    }
    const handleClickRight = () => {
        onChangeScrollPosition?.(scrollLeft + scrollStep)
        trackRef?.current?.scrollTo(scrollLeft + scrollStep, 0)
    }


    const updateScrollParams = () => {
        if (!trackRef.current) return

        const {scrollWidth, offsetWidth, scrollLeft} = trackRef.current

        if (!endScrolled.current) {
            trackRef?.current?.scrollTo(scrollWidth - offsetWidth, 0)
            endScrolled.current = true
            updateScrollParams()

            return;
        }

        setScrollParams({
            scrollWidth,
            offsetWidth,
            scrollLeft,
        })
    }

    useEffect(updateScrollParams, [children, isChildrenInTransition])

    useEffect(() => {
        const onScroll = debounce(updateScrollParams, 100)

        trackRef?.current?.addEventListener('scroll', onScroll)
        return () => {
            //eslint-disable-next-line
            trackRef?.current?.removeEventListener('scroll', onScroll)
        }
    }, [])

    useEffect(() => {
        //нужен для обновления scrollParams после завершения транзишенов детей (например, после изменений их размеров)
        const onTransitionStart = debounce(
            () => setIsChildrenInTransition(true),
            300
        )
        const onTransitionEnd = debounce(
            () => setIsChildrenInTransition(false),
            100
        )

        trackRef.current?.addEventListener('transitionstart', onTransitionStart)
        trackRef.current?.addEventListener('transitionend', onTransitionEnd)
        return () => {
            trackRef.current?.removeEventListener(
                'transitionstart',
                onTransitionStart
            )
            trackRef.current?.removeEventListener(
                'transitionend',
                onTransitionEnd
            )
        }
    }, [])

    useEffect(() => {
        onChangeScrollPosition?.(scrollLeft)
    }, [scrollLeft, onChangeScrollPosition])

    return (
        <Box sx={{
            position: 'relative',
            width: '100%',
        }}>
            <Fade in={isScrollLeftVisible}>
                <Box>
                    <NavigateButton
                        size={smallNavigateBtn ? "small" : undefined}
                        isLeft
                        leftOffset={leftButtonOffset}
                        onClick={e => {
                            e.stopPropagation()
                            handleClickLeft()
                        }}>
                        <NavigateBefore fontSize={smallNavigateBtn ? "small" : undefined}/>
                    </NavigateButton>
                </Box>
            </Fade>
            <Fade in={isScrollRightVisible}>
                <Box>
                    <NavigateButton
                        size={smallNavigateBtn ? "small" : undefined}
                        isRight
                        rightOffset={rightButtonOffset}
                        onClick={e => {
                            e.stopPropagation()
                            handleClickRight()
                        }}>
                        <NavigateNext fontSize={smallNavigateBtn ? "small" : undefined}/>
                    </NavigateButton>
                </Box>
            </Fade>
            <Box
                {...boxProps}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                ref={trackRef}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflowX: 'scroll',
                    scrollBehavior: 'smooth',
                    transition: 'all .2s ease',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                }}>
                {children}
            </Box>
        </Box>
    )
}
