import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    Fade,
    Grid,
    Paper,
    Skeleton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useUsersMeQuery, useUsersMeUpdateMutation} from "src/shared/services/ApiService";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {useFormik} from "formik";
import LoadingButton from '@mui/lab/LoadingButton';
import {useEffect, useState} from "react";
import {Maybe} from "src/shared/ui";
import {useNotification} from "src/shared/providers";
import {NotificationStatus} from "src/shared/providers/NotificationProvider/useNotification";


type FormValues = {
    email: string,
    phone: string,
    fio: string
}

export function Profile() {
    const [isEdit, setIsEdit] = useState(false)
    const {showNotification} = useNotification()
    const {mutateAsync: updateInfo} = useUsersMeUpdateMutation({
        onSuccess: () => {
            showNotification(NotificationStatus.success, {
                message: 'Профиль обновлен!'
            })
        },
        onError: () => {
            showNotification(NotificationStatus.error, {
                message: 'Произошла ошибка!'
            })
        }
    })

    const {
        data: {data} = {}, isLoading,
    } = useUsersMeQuery({
        queryParams: {},
        params: undefined,
    })

    const {
        values,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
    } = useFormik<FormValues>({
        initialValues: {
            email: '',
            phone: '',
            fio: ''
        },
        onSubmit:async (v) => {
            await updateInfo({
                body: v
            })

            setIsEdit(false)
        }
    })

    useEffect(() => {
        setValues({
            email: data?.email || '',
            phone: data?.phone || '',
            fio: data?.fio || '',
        })
    }, [data]);


    return (
        <Box>
            <Typography
                sx={{
                    fontSize: '28px',
                    fontWeight: 700,
                    margin: '20px 0'
                }}
            >
                Профиль
            </Typography>

            <Box>
                <Grid container spacing={9}>
                    <Grid item xs={3}>
                        <Paper elevation={0} sx={{
                            padding: '28px 24px',
                            border: '1px solid #DEDEDE'
                        }}>
                            <Box display="flex" alignItems="center" flexDirection="column">
                                <Avatar
                                    sx={{
                                        width: 124, height: 124,
                                        fontSize: '60px',
                                        fontWeight: 600
                                    }}
                                >
                                    {isLoading ? (<Skeleton variant="circular" width={124}
                                                            height={124}/>) : data?.fio?.[0]?.toUpperCase()}
                                </Avatar>

                                <Button
                                    disabled={true}
                                    sx={{
                                        textDecoration: 'underline'
                                    }}
                                >
                                    Обновить фото
                                </Button>
                            </Box>

                            <Divider sx={{
                                margin: '24px 0'
                            }}/>

                            <Box>
                                <Typography
                                    sx={{
                                        fontWeight: '600',
                                        fontSize: '14px'
                                    }}
                                >
                                    Cопровождающий менеджер
                                </Typography>

                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        color: '#747474'
                                    }}
                                >
                                    {isLoading ? <Skeleton/> : (data?.escortManager || 'Не указан')}
                                </Typography>
                            </Box>

                            <Box mt={3}>
                                <Typography
                                    sx={{
                                        fontWeight: '600',
                                        fontSize: '14px'
                                    }}
                                >
                                    Cопровождающий оператор
                                </Typography>

                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        color: '#747474'
                                    }}
                                >
                                    {isLoading ? <Skeleton/> : (data?.escortOperator || 'Не указан')}
                                </Typography>
                            </Box>

                            <Divider sx={{
                                margin: '24px 0'
                            }}/>

                            <Box>
                                <Typography sx={{
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    color: '#747474'
                                }}>
                                    Дата регистрации
                                </Typography>

                                <Typography sx={{
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    color: '#A6A6A6'
                                }}
                                >
                                    {isLoading ?
                                        <Skeleton/> :
                                        (data?.addedAt && format(new Date(data?.addedAt), 'dd MMMM, yyyy года', {
                                            locale: ru
                                        }) || 'Не указано')
                                    }
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={9}>
                        <Maybe
                            when={!isLoading}
                            fallback={(
                                <Box mt={9} textAlign="center">
                                    <CircularProgress/>
                                </Box>
                            )}
                        >
                            <Fade in timeout={500}>
                                <Stack spacing={2.5}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                marginBottom: '12px'
                                            }}
                                        >
                                            Фио
                                        </Typography>

                                        <TextField
                                            size="small"
                                            name="fio"
                                            value={values.fio}
                                            onChange={handleChange}
                                            fullWidth
                                            disabled={!isEdit}
                                        />
                                    </Box>

                                    <Box>
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                marginBottom: '12px'
                                            }}
                                        >
                                            Телефон
                                        </Typography>

                                        <TextField
                                            size="small"
                                            name="phone"
                                            value={values.phone}
                                            onChange={handleChange}
                                            fullWidth
                                            disabled={!isEdit}
                                        />
                                    </Box>

                                    <Box>
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                marginBottom: '12px'
                                            }}
                                        >
                                            Email
                                        </Typography>

                                        <TextField
                                            size="small"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            fullWidth
                                            disabled={!isEdit}
                                        />
                                    </Box>

                                    <Box>
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                marginBottom: '12px'
                                            }}
                                        >
                                            Компания
                                        </Typography>

                                        <TextField
                                            size="small"
                                            disabled={true}
                                            value={data?.currentCompanyName}
                                            fullWidth
                                        />
                                    </Box>
                                </Stack>
                            </Fade>

                            <Box mt={3}>
                                <Maybe when={!isEdit}>
                                    <LoadingButton
                                        size="large"
                                        variant="outlined"
                                        onClick={() => setIsEdit(true)}
                                    >
                                        Редактировать профиль
                                    </LoadingButton>
                                </Maybe>


                                <Maybe when={isEdit}>
                                    <Stack direction="row" spacing={1.5}>
                                        <LoadingButton
                                            size="large"
                                            variant="contained"
                                            loading={isSubmitting}
                                            onClick={() => handleSubmit()}
                                        >
                                            Сохранить
                                        </LoadingButton>

                                        <LoadingButton
                                            size="large"
                                            variant="outlined"
                                            disabled={isSubmitting}
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Отменить
                                        </LoadingButton>
                                    </Stack>
                                </Maybe>
                            </Box>
                        </Maybe>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
