import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import axiosInstance from "src/shared/services/ApiService/fetch";
import identity from 'lodash/identity'
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";

export type AuthProviderContextType = {
    token: string | null,
    onSetToken: (token: string) => void,
    refresh: () => void,
    logout: () => void
}

// @ts-ignore
export const Context = React.createContext<AuthProviderContextType>(null)


export const useAuthContext = (): AuthProviderContextType => useContext(Context)


const TOKEN_KEY = 'knob_token'

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
                                                                   children
                                                               }) => {
    const [token, setToken] = useState<null | string>(() => localStorage.getItem(TOKEN_KEY))
    const navigate = useNavigate()

    const refresh = useCallback(() => {
    }, [])


    useEffect(() => {
        axiosInstance.interceptors.response.use(
            identity,
            async (error: AxiosError<any>) => {
                if (
                    error.response?.status === 401 &&
                    !error.request?.responseURL?.includes('/api/v1/user/login')
                ) {
                    setToken('')
                }

                return Promise.reject(error)
            }
        )
    }, []);

    useEffect(() => {
        if (token) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
            localStorage.setItem(TOKEN_KEY, token)
            return
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        delete axiosInstance.defaults.headers.common['Authorization']
        localStorage.removeItem(TOKEN_KEY)

        const authPath = '/auth/sign-in'

        navigate(authPath, {
            state: {
                back: window.location.pathname.includes(authPath) ? '/' : window.location.pathname
            }
        })
    }, [token])

    const value = useMemo(() => ({
        token,
        onSetToken: (token: string) => setToken(token),
        refresh,
        logout: () => setToken(null),
    }), [
        token,
        refresh,
        setToken,
    ])

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}

export {AuthProvider}
