import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function MoreIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="28" height="27" viewBox="0 0 28 27" fill="none"
            {...props}
            style={{
                fill: 'none'
            }}
        >
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.1361 2.16669C7.88673 2.16669 2.80273 7.25069 2.80273 13.5C2.80273 19.7494 7.88673 24.8334 14.1361 24.8334C20.3854 24.8334 25.4694 19.7494 25.4694 13.5C25.4694 7.25069 20.3854 2.16669 14.1361 2.16669ZM14.1361 26.8334C6.78407 26.8334 0.802734 20.852 0.802734 13.5C0.802734 6.14802 6.78407 0.166687 14.1361 0.166687C21.4881 0.166687 27.4694 6.14802 27.4694 13.5C27.4694 20.852 21.4881 26.8334 14.1361 26.8334Z"
                  fill="#0048D3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.4005 14.8516C18.6632 14.8516 18.0605 14.2556 18.0605 13.5183C18.0605 12.7809 18.6512 12.1849 19.3872 12.1849H19.4005C20.1379 12.1849 20.7339 12.7809 20.7339 13.5183C20.7339 14.2556 20.1379 14.8516 19.4005 14.8516Z"
                  fill="#0048D3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.0545 14.8516C13.3172 14.8516 12.7158 14.2556 12.7158 13.5183C12.7158 12.7809 13.3052 12.1849 14.0425 12.1849H14.0545C14.7918 12.1849 15.3878 12.7809 15.3878 13.5183C15.3878 14.2556 14.7918 14.8516 14.0545 14.8516Z"
                  fill="#0048D3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.70914 14.8516C7.97181 14.8516 7.36914 14.2556 7.36914 13.5183C7.36914 12.7809 7.95981 12.1849 8.69714 12.1849H8.70914C9.44647 12.1849 10.0425 12.7809 10.0425 13.5183C10.0425 14.2556 9.44647 14.8516 8.70914 14.8516Z"
                  fill="#0048D3"/>
        </SvgIcon>
    );
}
