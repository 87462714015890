import {Avatar, Box, IconButton, Tooltip, Menu, MenuItem, Divider, ListItemIcon, Paper} from '@mui/material';
import * as React from 'react';
import {useAuthContext} from "src/shared/providers";
import {Link} from "react-router-dom";
import {LogoutIcon, NotificationIcon, ProfileIcon} from "src/shared/ui";
import {MenuIcon} from "src/shared/ui/Icons/MenuIcon";
import {DocumentIcon} from "src/shared/ui/Icons/DocumentIcon";
import {WorkIcon} from "src/shared/ui/Icons/WorkIcon";
import {FolderIcon} from "src/shared/ui/Icons/FolderIcon";

const menuIconBoxStyle = {
    backgroundColor: '#0048D3',
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    marginRight: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

export default function ProfileMenu() {
    const {logout,} = useAuthContext()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                <Tooltip title="Профиль">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        sx={{
                            padding: 0,
                            height: '50px'
                        }}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                border: '1px solid #DEDEDE',
                                borderRadius: '50px',
                                padding: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '16px'
                            }}
                        >
                            <MenuIcon
                                fontSize="small"
                                sx={{
                                    marginLeft: '8px'
                                }}/>
                            <Avatar
                                sx={{
                                    width: 36,
                                    height: 36,
                                    backgroundColor: '#E5F7FF',
                                    color: '#2C2C2C'
                                }}
                            >K</Avatar>
                        </Paper>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        boxShadow: '4px 7px 30px -5px rgba(83, 72, 52, 0.1)',
                        border: '1px solid #F1EEE9',
                        mt: 1.5,
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                {/*<Link to="/user/portfolio" style={{textDecoration: 'none', color: '#000000'}}>*/}
                {/*    <MenuItem*/}
                {/*        sx={{*/}
                {/*            fontWeight: '500'*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <Box sx={menuIconBoxStyle}>*/}
                {/*            <ListItemIcon*/}
                {/*                sx={{*/}
                {/*                    justifyContent: 'center'*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                <WorkIcon fontSize="small" htmlColor="#FFFFFF"/>*/}
                {/*            </ListItemIcon>*/}
                {/*        </Box>*/}

                {/*        Мой портфель*/}
                {/*    </MenuItem>*/}
                {/*</Link>*/}

                {/*<Link to="/user/projects" style={{textDecoration: 'none', color: '#000000'}}>*/}
                {/*    <MenuItem*/}
                {/*        sx={{*/}
                {/*            fontWeight: '500'*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <Box sx={menuIconBoxStyle}>*/}
                {/*            <ListItemIcon*/}
                {/*                sx={{*/}
                {/*                    justifyContent: 'center'*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                <FolderIcon fontSize="small" htmlColor="#FFFFFF"/>*/}
                {/*            </ListItemIcon>*/}
                {/*        </Box>*/}
                {/*        Мои пакеты лицензий*/}
                {/*    </MenuItem>*/}
                {/*</Link>*/}

                {/*<Divider sx={{*/}
                {/*    margin: '16px 0'*/}
                {/*}}/>*/}

                <Link
                    to="/user/account"
                    style={{
                        color: 'inherit',
                        textDecoration: 'none'
                    }}
                >
                    <MenuItem
                        sx={{
                            fontWeight: '600',
                            height: '46px'
                        }}
                    >
                        <ListItemIcon>
                            <ProfileIcon fontSize="small"/>
                        </ListItemIcon>
                        Аккаунт
                    </MenuItem>
                </Link>
                {/*<MenuItem*/}
                {/*    sx={{*/}
                {/*        fontWeight: '600',*/}
                {/*        height: '46px'*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <ListItemIcon>*/}
                {/*        <NotificationIcon fontSize="small"/>*/}
                {/*    </ListItemIcon>*/}
                {/*    Уведомления*/}
                {/*</MenuItem>*/}
                <MenuItem
                    sx={{
                        fontWeight: '600',
                        height: '46px'
                    }}
                    onClick={() => logout()}
                >
                    <ListItemIcon>
                        <LogoutIcon fontSize="small"/>
                    </ListItemIcon>
                    Выйти из аккаунта
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}
