import React from 'react'
import {Grid} from "@mui/material";
import {TariffsFilters} from "./TariffsFilters";
import TariffsList from "./TariffsList";
import {ITariffListItem, useTariffsInfinityQuery} from "src/shared/services/ApiService";
import {reduceInfinityResultData, useInfiniteScroll} from "src/shared/utils";
import {InfinityLoader, ListLoader, Maybe} from "src/shared/ui";

export function Tariffs() {
    const {
        data,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
        isError,
    } = useTariffsInfinityQuery({
        params: undefined,
        queryParams: {}
    }, {
        staleTime: 30 * 1000
    })

    const tariffs: ITariffListItem[] = reduceInfinityResultData<ITariffListItem>(data)

    const [loaderRef] = useInfiniteScroll({
        loading: isFetchingNextPage,
        hasNextPage: !!hasNextPage,
        onLoadMore: fetchNextPage,
        disabled: isError,
        rootMargin: '0px 0px 100px 0px',
    });


    return (
        <Grid container columnSpacing={7.5}>
            {/*<Grid item xs={3}>*/}
            {/*    <TariffsFilters/>*/}
            {/*</Grid>*/}

            <Grid item xs={12}>
                <Maybe
                    when={!!tariffs.length}
                    fallback={<ListLoader count={8} xs={3}/>}
                >
                    <TariffsList
                        items={tariffs}
                    />

                    <InfinityLoader
                        isFetchingNextPage={isFetchingNextPage}
                        hasNextPage={hasNextPage}
                        ref={loaderRef}
                    />
                </Maybe>
            </Grid>
        </Grid>
    )
}
