import React from 'react'
import {Paper, Typography} from "@mui/material";
import {ITariff} from "src/shared/services/ApiService";
import {CARD_STYLES_BY_CODE} from "src/features/Tariffs/ui/Tariffs/TariffsList/contants";

type Props = {
    data: ITariff
}

export default function TariffCardHeader({
                                             data: {
                                                 name,
                                                 shortDescription,
                                                 code
                                             }
                                         }: Props) {
    return (
        <Paper
            elevation={0}
            sx={{
                backgroundColor: CARD_STYLES_BY_CODE[code as 'T0']?.bgColor || '#FFF',
                padding: '20px'
            }}
        >
            {/*<Typography variant="caption" color="textSecondary">*/}
            {/*    Мультитенантная модель*/}
            {/*</Typography>*/}

            <Typography variant="h5" fontSize={28} fontWeight={700} sx={{
                margin: '8px 0 12px 0'
            }}>
                {name}
            </Typography>

            <Typography fontWeight={500}>
                {shortDescription}
            </Typography>
        </Paper>
    )
}
