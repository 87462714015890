export const SERVICE_PARAMS_LABELS = {
    ipQuantity: 'Количество IP-адресов в диапазоне',
    domainQuantity: 'Количество доменов',
    activeIpQuantity: 'Количество активных IP-адресов',
    hostsQuantity: 'Количество хостов',
    webQuantity: 'Количество веб-приложений',
    serviceQuantity: 'Количество сервисов',
    accountQuantity: 'Количество учетных записей',
    serviceHashQuantity: 'Количество сервисов, анализируемых методом перебора хэшей (остальные тестируются методом OSINT)',
    skanQuantity: 'Количество сканирований',
    projectQuantity: 'Количество проектов',
    scanFlowQuantity: 'Количество потоков сканирования',
    userInterfaceQuantity: 'Количество пользователей интерфейса',
    projectDevelopmentQuantity: 'Количество проектов разработки',
    onlineCourseUser: 'Количество пользователей онлайн-курсов',
    onlineCtfUser: 'Количество пользователей CTF-платформы',
    requestQuantity: 'Количество запросов в месяц',
    professionalHours: 'Количество часов профессиональных сервисов'
}
