import React from 'react'
import {Box, Fade, Grid, Skeleton, Typography, Grow} from "@mui/material";
import {EntityListItem, ListLoader, Maybe} from "src/shared/ui";
import {CARD_STYLES_BY_CATEGORY, MOCK_TARIFFS} from "src/features/Tariffs/ui/Tariffs/TariffsList/mock";
import {slice} from 'lodash'
import {IService, useServicesQuery, useTariffsQuery} from "src/shared/services/ApiService";
import {useNavigate} from "react-router-dom";
import {TransitionGroup} from 'react-transition-group'
import {getLimitedEnterTimeout} from "src/shared/utils";


type Props = {
    id: IService["id"]
}


export default function TariffCardServicesIncludedIn({
                                                         id
                                                     }: Props) {
    const navigate = useNavigate()

    const {data: {data} = {}, isLoading} = useServicesQuery({
        queryParams: {
            tariffId: id,
            page: 1,
            perPage: 100
        },
        params: undefined
    })

    return (
        <>
            <Maybe when={isLoading}>
                <Box>
                    <Typography
                        variant="h5"
                        fontWeight={700}
                        sx={{
                            marginBottom: '16px'
                        }}
                    >
                        <Skeleton width={300}/>
                    </Typography>

                    <ListLoader count={2} xs={6}/>
                </Box>
            </Maybe>
            <Maybe when={!!data?.length}>
                <Fade in={true}>
                    <Box>
                        <Typography
                            variant="h5"
                            fontWeight={700}
                            sx={{
                                marginBottom: '16px'
                            }}
                        >
                            В тариф включены услуги:
                        </Typography>
                        <TransitionGroup>
                            <Grid container spacing={3}>
                                {data?.map(({id, name, shortDescription, group}, i) => (
                                    <Grow
                                        in
                                        exit={false}
                                        appear
                                        timeout={{
                                            enter: getLimitedEnterTimeout(300 + i * 100),
                                        }}
                                    >
                                        <Grid
                                            item xs={6}
                                            key={id}
                                        >
                                            <EntityListItem
                                                onClick={() => navigate(`/services/${id}`)}
                                                title={name}
                                                description={shortDescription}
                                                caption={group.name}
                                                tags={[{name: group.tag, color: group.colorTag}]}
                                                bgColor={group.colorBackground}
                                            />
                                        </Grid>
                                    </Grow>
                                ))}
                            </Grid>
                        </TransitionGroup>
                    </Box>
                </Fade>
            </Maybe>
        </>
    )
}
