import {
    QueryFunctionContext, QueryKey,
    useInfiniteQuery, UseInfiniteQueryOptions, UseInfiniteQueryResult,
    useMutation, UseMutationOptions,
    useQuery, UseQueryOptions,
} from '@tanstack/react-query'


import {Axios, AxiosError, AxiosRequestConfig} from 'axios'

import axiosInstance from './fetch'
import {
    GenerateInfiniteQueryType,
} from './types'
import {UseQueryResult} from "@tanstack/react-query/src/types";

export const generateQuery = <TParams,
    TQueryParams,
    QResult>(
    getRoute: (params: TParams) => string,
    queryKey: string,
    axiosConfig: AxiosRequestConfig = {}
): [
    (
        p: { params: TParams, queryParams: TQueryParams },
        config?: UseQueryOptions<QResult, AxiosError>
    ) => UseQueryResult<QResult, AxiosError>,
    (v: { params: TParams, queryParams: TQueryParams }) => QueryKey
] => {
    type QVariables = { params: TParams, queryParams: TQueryParams }

    const getQueryKey = (variables: QVariables) => [queryKey, variables.params, variables.queryParams]

    const getRequest = async (variables: QVariables) => {
        const {data} = await axiosInstance.get<QResult>(
            `/api/v1/${getRoute(variables.params)}`,
            {
                ...axiosConfig,
                params: variables.queryParams
            }
        )
        return data
    }

    const useGeneratedQuery = (
        params: QVariables,
        config?: UseQueryOptions<QResult, AxiosError>
    ) =>
        useQuery({
            queryKey: getQueryKey(params),
            queryFn: () => getRequest(params),
            ...(config || {}),
        })

    return [useGeneratedQuery, getQueryKey]
}


export const generateMutation = <TParams,
    TBody,
    MResult>(
    method: keyof Pick<Axios, 'post' | 'put' | 'delete' | 'patch' | 'get'>,
    getRoute: (params?: TParams) => string
) => {
    type MVariables = {
        params?: TParams,
        body?: TBody
        file?: File
    }

    const mutation = async ({body, file, params}: MVariables): Promise<MResult> => {
        let formData
        if (file) {
            formData = new FormData()
            formData.append('file', file)
        }

        const {data} = await axiosInstance[method]<MResult>(
            `/api/v1/${getRoute(params)}`,
            file ? formData : body,
            file && {headers: {'Content-Type': 'multipart/form-data'}}
        )
        return data
    }

    return (config?: UseMutationOptions<MResult, AxiosError, MVariables>) =>
        useMutation<MResult, AxiosError, MVariables>(mutation, config)
}

const LIMIT = 18

export const generateInfiniteQuery = <TParams,
    TQueryParams,
    QResult>(
    getRoute: (params: TParams) => string,
    queryKey: string
): [
    (
        v: { params: TParams, queryParams: TQueryParams },
        config?: UseInfiniteQueryOptions
    ) => UseInfiniteQueryResult<QResult, AxiosError>,
    (v: { params: TParams, queryParams: TQueryParams }) => QueryKey
] => {
    type QVariables = { params: TParams, queryParams: TQueryParams }

    const getQueryKey = (variables: QVariables) => [
        queryKey,
        variables.params,
        variables.queryParams
    ]

    const getRequestWithPageParam = async (
        variables: QVariables,
        {
            pageParam = 1,
        }: QueryFunctionContext<[string, TParams, TQueryParams]>
    ) => {
        const {data} = await axiosInstance.get<QResult>(
            `/api/v1/${getRoute(variables.params)}`,
            {
                params: {
                    ...(variables.queryParams || {}),
                    perPage: LIMIT,
                    page: pageParam,
                },
            }
        )

        return data
    }

    const useGeneratedInfiniteQuery = (
        variables: QVariables,
        config: UseInfiniteQueryOptions = {}
    ) =>
        useInfiniteQuery({
            queryKey: getQueryKey(variables),
            // @ts-ignore
            queryFn: context => getRequestWithPageParam(variables, context),
            ...({
                ...(config || {}),
                getNextPageParam: (lastPage: any) =>
                    lastPage.pager.page < lastPage.pager.pages ? (lastPage.pager.page + 1) : false
            })
        })

    return [
        useGeneratedInfiniteQuery as (
            v: { params: TParams, queryParams: TQueryParams },
            config?: UseInfiniteQueryOptions
        ) => UseInfiniteQueryResult<QResult, AxiosError>,
        getQueryKey
    ]
}
