import React from 'react'
import {Box, Divider} from "@mui/material";
import ServiceDirections from "src/features/Services/ui/Services/ServiceFilters/ServiceDirections";
import {ServiceGroups} from "src/features/Services/ui/Services/ServiceFilters/ServiceGroups";
import {ServicesFormValues} from "src/features/Services/ui";
import {FormikHandlers} from "formik";

type Props = {
    onChange: FormikHandlers['handleChange'],
    values: ServicesFormValues
}

export function ServiceFilters({
                                   values,
                                   onChange
                               }: Props) {
    return (
        <Box>
            <ServiceDirections
                value={values.directions}
                onChange={onChange}
            />

            <Divider sx={{
                margin: '32px 0'
            }}/>

            <ServiceGroups
                value={values.groups}
                onChange={onChange}
            />
        </Box>
    )
}
