import {
    Box, Button,
    IconButton,
} from "@mui/material";
import {LoadingButton, PlusIcon, SuccessModal} from "src/shared/ui";
import React from "react";
import {
    IServicePortfolioUnallocated, IServicePurchased,
    useOrganizationProjectsQuery, useProjectsPurchasedServicesJoinMutation,
} from "src/shared/services/ApiService";
import {useQueryClient} from "@tanstack/react-query";
import {ModalWrapper, useModal} from "src/shared/providers";
import {useQueryParams} from "src/shared/utils/hooks/useQueryParams";


type Props = {
    service: IServicePurchased
}

type FormValues = {
    project?: string
}

export function ServiceJoin({
                                service
                            }: Props) {
    const {mutateAsync: mutateJoin} = useProjectsPurchasedServicesJoinMutation()
    const queryClient = useQueryClient()
    const [qParams] = useQueryParams()

    const {
        openModal,
        closeModal
    } = useModal()

    const handleOpen = () => {
        const key = 'join-modal'

        openModal({
            key,
            component: (
                <ModalWrapper
                    dialogTitle="Вы хотите привязать услугу к пакету лицензий?"
                    onClose={closeModal.bind(null, key)}
                >
                    <Box sx={{
                        display: 'flex',
                        gap: '32px',
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            onClick={() => {
                                closeModal(key)
                            }}
                        >
                            Отмена
                        </Button>

                        <LoadingButton
                            variant="contained"
                            onClick={async () => {
                                if (typeof qParams.id !== 'string') return

                                const data = await mutateJoin({
                                    params: {
                                        id: qParams.id as string,
                                        serviceId: service.id,
                                    }
                                })

                                await queryClient.invalidateQueries({
                                    queryKey: ['ServicesPurchased']
                                })

                                closeModal(key)
                            }}
                        >
                            Привязать
                        </LoadingButton>
                    </Box>
                </ModalWrapper>
            )
        })
    }

    return (
        <Box>
            <IconButton
                onClick={handleOpen}
                sx={{
                    padding: 0
                }}
            >
                <PlusIcon/>
            </IconButton>
        </Box>
    )
}
