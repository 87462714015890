import {Navigate, RouteObject} from "react-router-dom";
import {SignIn, Layout} from "src/features/Authentication/ui";

export const routes: RouteObject[] = [
    {
        path: '/auth',
        element: <Layout/>,
        children: [
            {
                path: '',
                element: <Navigate to="sign-in"/>
            },
            {
                path: 'sign-in',
                element: <SignIn/>
            },
        ]

    },

]
