import React from 'react'
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ArrowLeftIcon} from "src/shared/ui";

export function BackAction() {
    const navigate = useNavigate()

    return (
        <Button
            onClick={()=>navigate(-1)}
            startIcon={<ArrowLeftIcon sx={{
                //fontSize: '32px !important'
            }}/>}
            sx={{
                textTransform: 'none',
                color: '#2C2C2C',
                fontSize: '20px',
                fontWeight: 600,
                '& .MuiButton-startIcon': {
                    marginRight: '20px'
                }
            }}
        >
            Назад
        </Button>
    )
}
