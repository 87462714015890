import {RouteObject} from "react-router-dom";
import {Portfolio} from "../ui";
import {BaseLayout, HomeLayout, RbacGuardLayout} from "src/shared/ui";
import {PermissionsEnum} from "src/shared/services/RbacServices";
import {Box, Typography} from "@mui/material";
import React from "react";

export const routes: RouteObject[] = [
    {
        path: '/user/portfolio',
        element: (
            <BaseLayout>
                <RbacGuardLayout permission={PermissionsEnum.CAN_ACCESS}/>
            </BaseLayout>
        ),
        children: [
            {
                path: '',
                element: <Portfolio/>
            },
            // {
            //     path: '',
            //     element: (
            //         <Box mt={5}>
            //             <Typography variant="h4">
            //                 Данный раздел находится в разработке
            //             </Typography>
            //         </Box>
            //     )
            // }
        ]
    },
]
