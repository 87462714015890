import React from 'react'
import {Box, IconButton, Typography} from "@mui/material";
import InfoIcon from '@mui/icons-material/InfoOutlined';

type Props = {
    title: string,
    hint?: string
}

export function HintTitle({
                              title,
                              hint,
                          }: Props) {
    return (
        <Box
            mb={2}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <Typography
                variant="subtitle1"
                fontWeight="700"
                sx={{
                    fontSize: '18px'
                }}
            >
                {title}
            </Typography>

            <IconButton aria-label="info">
                <InfoIcon htmlColor="#2C2C2C"/>
            </IconButton>
        </Box>
    )
}
