import {EntityResponse, IService, ITariffListItem, ListResponse} from "src/shared/services/ApiService"
import {generateQuery} from "src/shared/services/ApiService/helpers";

export type UseServiceQueryVariables = {}

export type UseServiceQueryResponse = EntityResponse<IService>

export const [useServiceQuery, getUseServiceQueryKey] = generateQuery<{
    serviceId: string
},
    UseServiceQueryVariables,
    UseServiceQueryResponse>(
    params => `services/${params.serviceId}`,
    'service'
)
