import {IServicePortfolioUnallocated, SimpleListResponse} from "src/shared/services/ApiService";
import {generateQuery} from "src/shared/services/ApiService/helpers";

export type UseServicePortfoliosUnallocatedQueryVariables = {}

export type UseServicePortfoliosUnallocatedQueryResponse = SimpleListResponse<IServicePortfolioUnallocated>

export const [useServicePortfoliosUnallocatedQuery, getUseServicePortfoliosUnallocatedQueryKey] = generateQuery<undefined,
    UseServicePortfoliosUnallocatedQueryVariables,
    UseServicePortfoliosUnallocatedQueryResponse>(
    params => `service-portfolios/unallocated`,
    'ServicePortfoliosUnallocated'
)
