import React from 'react'
import {Button} from "@mui/material";
import {HeartIcon} from "src/shared/ui";

export function FavoriteAction() {
    return (
        <Button
            startIcon={<HeartIcon/>}
            sx={{
                textTransform: 'none',
                color: '#2C2C2C',
            }}
        >
            <span style={{textDecoration: 'underline'}}>Добавить в избранное</span>
        </Button>
    )
}
