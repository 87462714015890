import React from 'react'
import {Box, Fade, Grid, Skeleton, Typography} from "@mui/material";
import {EntityListItem, ListLoader, Maybe} from "src/shared/ui";
import {IService, useTariffsQuery} from "src/shared/services/ApiService";
import {useNavigate} from "react-router-dom";
import {CARD_STYLES_BY_CODE} from "src/features/Tariffs/ui/Tariffs/TariffsList/contants";
import {range} from "lodash";


type Props = {}


export default function ServiceCardTariffsIncludedInFallback({}: Props) {


    return (
        <Box>
            <Typography
                variant="h5"
                fontWeight={700}
                sx={{
                    marginBottom: '16px'
                }}
            >
                <Skeleton
                    sx={{
                        margin: 'auto'
                    }}
                    width={300}
                />
            </Typography>

            <ListLoader count={2} xs={6} hideTag/>
        </Box>
    )
}
