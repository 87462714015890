import {generateMutation} from "src/shared/services/ApiService/helpers";


export type UseProjectsPurchasedServicesJoinMutationVariables = {}

export type UseProjectsPurchasedServicesJoinMutationResponse = {}


export const useProjectsPurchasedServicesJoinMutation = generateMutation<{
    id: string | number,
    serviceId: string | number
},
    UseProjectsPurchasedServicesJoinMutationVariables,
    UseProjectsPurchasedServicesJoinMutationResponse>(
    'post',
    params => `projects/${params?.id}/purchased-services/${params?.serviceId}`
)
