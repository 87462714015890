import React from 'react'
import {Box, Grid, Typography} from "@mui/material";
import AnotherServices from "src/features/Services/ui/Service/AnotherServices";
import ServiceCard from "./ServiceCard/ServiceCard";
import {BackAction, FavoriteAction, ShareAction} from "src/shared/ui";
import {Navigate, useParams} from "react-router-dom";

export default function Service() {
    const {id} = useParams()

    if (!id) {
        return <Navigate to="/404" replace/>
    }

    return (
        <Grid container spacing={7.5}>
            <Grid item xs={8.5}>
                <Box
                    mb={0.5}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <BackAction/>

                    {/*<Box display="flex" gap="24px">*/}
                    {/*    <ShareAction/>*/}
                    {/*    <FavoriteAction/>*/}
                    {/*</Box>*/}
                </Box>

                <ServiceCard
                    id={id}
                />
            </Grid>

            <Grid item xs={3.5}>
                <Typography
                    variant="h5"
                    fontWeight={700}
                    sx={{
                        marginBottom: '16px'
                    }}
                >
                    Решения направления
                </Typography>

                <AnotherServices/>
            </Grid>
        </Grid>
    )
}
