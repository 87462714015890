import React, {createContext, useContext, Suspense, useState} from 'react'

export type ModalOptions = {
    key: string
    component: JSX.Element
}

export type ProviderContext = {
    openModal: ({key, component}: ModalOptions) => void
    closeModal: (key: string) => void
}

export const useModal = (): ProviderContext => useContext(DialogContext)

const DialogContext = createContext<ProviderContext>({
    openModal: () => {
        throw new Error('Вы забыли подключить ModalProvider')
    },
    closeModal: () => {
        throw new Error('Вы забыли подключить ModalProvider')
    },
})

type Props = {
    children: React.ReactNode
}

const ModalProvider: React.FC<Props> = ({
                                            children
                                        }) => {
    const [modals, setModals] = useState<ModalOptions[]>([]);

    const openModal = (options: ModalOptions) => {
        setModals((modals) =>
            modals.some(({key}) => options.key === key)
                ? modals
                : [
                    ...modals,
                    options,
                ]
        )
    }

    const closeModal = (keyString: string) => {
        setModals((modals) => modals.filter(({key}) => key !== keyString))
    }

    const contextValues = {
        openModal,
        closeModal,
    }


    return (
        <DialogContext.Provider value={contextValues}>
            {children}
            {modals.map(({key, component}) => (
                <React.Fragment key={key}>
                    <Suspense
                        fallback="Modal loading...">
                        {component}
                    </Suspense>
                </React.Fragment>
            ))}
        </DialogContext.Provider>

    )
}

export default ModalProvider
