import * as React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

export function PlusIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            width="26" height="27" viewBox="0 0 26 27" fill="none"
            {...props}
        >
            <path
                d="M13 0.305908C5.83203 0.305908 0 6.13794 0 13.3059C0 20.4739 5.83203 26.3059 13 26.3059C20.168 26.3059 26 20.4739 26 13.3059C26 6.13794 20.168 0.305908 13 0.305908ZM13 2.30591C19.0859 2.30591 24 7.21997 24 13.3059C24 19.3918 19.0859 24.3059 13 24.3059C6.91406 24.3059 2 19.3918 2 13.3059C2 7.21997 6.91406 2.30591 13 2.30591ZM12 7.30591V12.3059H7V14.3059H12V19.3059H14V14.3059H19V12.3059H14V7.30591H12Z"
                fill="#2C2C2C"/>
        </SvgIcon>
    );
}
