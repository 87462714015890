import React from 'react'
import {Button} from "@mui/material";
import {ShareIcon} from "src/shared/ui";

export function ShareAction() {
    return (
        <Button
            startIcon={<ShareIcon/>}
            sx={{
                textTransform: 'none',
                color: '#2C2C2C',
            }}
        >
            <span style={{textDecoration: 'underline'}}>Поделиться</span>
        </Button>
    )
}
