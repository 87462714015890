import React, {useCallback, useMemo, useEffect} from 'react'
import {QueryCache, MutationCache, QueryClient, QueryClientProvider} from "@tanstack/react-query"
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'

import GlobalLoadingIndicator from './GlobalLoadingIndicator'
import {useNotification} from "src/shared/providers/NotificationProvider";
import {debounce} from "lodash";
import {Box, Typography} from "@mui/material";
import {useAuthContext} from "src/shared/providers";
import {axiosInstance} from "src/shared/services/ApiService";

type Props = {
    children: React.ReactNode
}

const ReactQueryDevtoolsProduction = React.lazy(() =>
    import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(d => ({
        default: d.ReactQueryDevtools
    }))
)

const IGNORED_ERROR_TITLES = ['Invalid JWT Token', 'Expired JWT Token']

const ApiProvider: React.FC<Props> = ({children}) => {
    const {token,} = useAuthContext()
    const {showErrorNotification} = useNotification()
    const [showDevtools, setShowDevtools] = React.useState(false)

    const debouncedShowError = useCallback(
        debounce(showErrorNotification, 300),
        [showErrorNotification],
    );


    useEffect(() => {
        // @ts-ignore
        window.toggleDevtools = () => setShowDevtools(old => !old)
    }, [])

    useEffect(() => {
        if (token) {
            axiosInstance.defaults.headers.common['Authorization'] = token
            return
        }

        delete axiosInstance.defaults.headers.common['Authorization']
        return () => {
            delete axiosInstance.defaults.headers.common['Authorization']
        }
    }, [token]);


    const queryClient = useMemo(() => {
        const processError = (errorResponse: unknown) => {
            // @ts-ignore
            if (errorResponse?.response?.code === 401) return

            // @ts-ignore
            const messages = Object.keys(errorResponse?.response?.data?.errors || {})?.map((key) => `${key}: ${errorResponse?.response?.data?.errors[key]}`)

            debouncedShowError({
                message: (
                    <Box>
                        <Typography variant="subtitle2">
                            {
                                // @ts-ignore
                                errorResponse?.response?.errorTittle
                            }
                        </Typography>
                        <Typography variant="subtitle2">
                            {
                                // @ts-ignore
                                messages.map((message) => (
                                    <Box>
                                        {message}
                                    </Box>
                                ))
                            }
                        </Typography>
                    </Box>
                ),
            })

        }

        return new QueryClient({
            defaultOptions: {
                queries: {
                    retry: 0,
                    refetchOnWindowFocus: false,
                },
            },
            queryCache: new QueryCache({
                onError: (error) => {
                    processError(error)
                }
            }),
            mutationCache: new MutationCache({
                onError: (error) => {
                    processError(error)
                }
            }),
        })
    }, [debouncedShowError]);


    return (
        <QueryClientProvider client={queryClient}>
            <GlobalLoadingIndicator/>
            {children}
            <ReactQueryDevtools initialIsOpen={false}/>
            {showDevtools && (
                <React.Suspense fallback={null}>
                    <ReactQueryDevtoolsProduction/>
                </React.Suspense>
            )}
        </QueryClientProvider>
    )
}

export default ApiProvider
